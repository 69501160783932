import React, { useState,useEffect } from 'react'
import Asset from "../../asset/homeasset.png"

import useWindowDimensions from "../Layout/useWindowDimension"
import HomeBannerImage from "../../asset/homeBanner.png"
import "./Home.css"
const HomeBanner = ({active,setActive,banner}) => {
  const activeButton = "py-3 border-b-[2px] cursor-pointer border-[#2967B1] text-[#2967B1] "
  const inActiveButton = "py-3 cursor-pointer   text-black "
   useEffect(() => {
   
   }, [])
   const {width,height} = useWindowDimensions()
  return (
    <>
    <div className='' >
     
       <img src={banner.image} alt="" />
       
        
    </div>
   
       </>
  )
}

export default HomeBanner