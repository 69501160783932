import React,{useState,useEffect} from 'react'
import Navbar from '../Components/Layout/Navbar'
import HomeBanner from '../Components/Home/HomeBanner'
import HomeCarousel from '../Components/Home/HomeCarousel'
import Services from '../Components/Home/Services'
import RepairSteps from '../Components/Home/RepairSteps'
import Faqs from '../Components/Home/Faqs'
import Brands from '../Components/Home/Brands'
import LeftRight from '../Components/Home/LeftRight'
import DownloadApp from '../Components/Home/DownloadApp'
import Testimonial from '../Components/Home/Testimonial'
import Footer from '../Components/Layout/Footer'

import { useSelector, useDispatch } from 'react-redux'
import { loadUser } from '../actions/userAction'
import axios from "axios"
import PinCodeModal from '../Components/Layout/PinCodeModal'
import { ref, onValue, get, push, set, query} from "firebase/database";
import {database, db, requestForToken, storage} from "../firebase.config"
import RepairProducts from '../Components/Repair/RepairProducts'
import Features from '../Components/Home/Features'
import Stories from '../Components/Home/Stories'
import BelowCTA from '../Components/Home/BelowCTA'
import AddTestimonials from '../Components/Home/AddTestimonials'
import { addDoc, collection, getDocs, where } from 'firebase/firestore'
import Mi from "../asset/brandslogo/mi.png"

import Oppo from "../asset/brandslogo/oppo.png"
import Google from "../asset/brandslogo/Google.png"
import Vivo from "../asset/brandslogo/vivo.png"

import Poco from "../asset/brandslogo/poco.png"
import Apple from "../asset/brandslogo/apple.png"
import { getDownloadURL, listAll, ref as storageRef } from 'firebase/storage'
import { saveRepairDevice } from '../actions/repairActions'
import { useNavigate } from 'react-router-dom'
import Loader from '../Components/Layout/Loader'
import { Spinner } from 'flowbite-react'


const deviceData = [
  { id: 1, name: 'Mobiles', value:"mobileRepair",device:"mobile_brands" },
  { id: 2, name: 'Tablets', value:"laptopRepair",device:"tablet_brands" },
  { id: 3, name: 'Laptops', value:"mainLaptopRepair",device:"laptop_brands" },

]
const Home = () => {
  const dispatch = useDispatch()
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState({})
  const [device, setDevice] = useState({})
  const [model, setModel] = useState({})
  const [models, setModels] = useState([])
  const [brandImages, setBrandImages] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [pinCode, setPinCode] = useState(null)
  const [pinCodeModal, setPinCodeModal] = useState(false)
  const [repairProducts, setRepairProducts] = useState([])
  const [shieldProducts, setShieldProducts] = useState([])
  const [faqs, setFaqs] = useState([])
  const [scrollPosition, setScrollPosition] = useState(0)
  const navigate = useNavigate()
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
};
  const [active, setActive] = useState("repair")
  const [mobileRepairList, setMobileRepairList] = useState([])
   const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  const [testimonialDetail, setTestimonialDetail] = useState({
    name:userProfile?.FirstName ? userProfile?.FirstName : '',
    position:'',
    company:'',
    testimonial:''
  })
  const [testimonialList, setTestimonialList] = useState([])
  const handleModal = () =>{
    setPinCodeModal(true)
  }
  const handleOpenModal = () =>{
    setOpenModal(!openModal)
  }
  const closeModal = () =>{
    setPinCodeModal(false)
  }
  
  const handleAddTestimonial = () =>{
     addDoc(collection(db, "testimonials"),
        {
         name:testimonialDetail.name,
    position:testimonialDetail.position,
    company:testimonialDetail.company,
    testimonial:testimonialDetail.testimonial,
    status:'pending'
      })
    .then(()=>{
     
     setTestimonialDetail({
       name:userProfile?.FirstName ? userProfile?.FirstName : '',
    position:'',
    company:'',
    testimonial:''
     })
     handleOpenModal()
      
  })
}
const brandList1 = [Apple,Mi,Google,Poco,Oppo,Vivo]
  const fetchTestimonials =  async () =>{
    const q = query(collection(db, "testimonials"), where("status", "==", 'approved'));
   const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
     setTestimonialList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
     
    
     
     
  
    });
  }
  const fetchFaqs = () =>{
     const repairCountRef = ref(database, 'faqs/' );
  if(repairCountRef){
   onValue(repairCountRef, (snapshot) => {
  const data = snapshot.val();
  if(data){
  const newProducts = Object.keys(data).map(key=>({
    id:key,
    ...data[key]
  }));
   setFaqs(newProducts)
}
 
},);
  }
  }
  const mobileBrandList =  () =>{
    if(Object.keys(device).length !== 0 && device?.value?.length !== 0){
       const repairCountRef = ref(database, 'repair/' + device?.value );

     onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          name:data[key].brand,
         
          ...data[key]
        }));
        setBrands(newProducts)
       })
    }
    else{
       return ;
    }
        
      }
      const getModels = () =>{
        if(Object.keys(brand).length !== 0){
          console.log("hii")
          let modelList = []
          brand?.models?.map((item,index)=>{
            const modelItem = {
              id:index+1,
              name: item,
            }
            modelList.push(modelItem) 
          })
          setModels(modelList)
        } 
        else{
          return;
        }
      }

      const [stories, setStories] = useState([])
  const fetchAllStories = async () =>{
      const repairCountRef =  await ref(database, 'successStories/');

     onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setStories(newProducts)
       })
  }
  const getBrandImage = () =>{
    const listRef = storageRef(storage, 'Brands/homeBrands/');
  
    listAll(listRef)
  .then((res) => {
    res.items.forEach((item)=>{
        getDownloadURL(item).then((url)=>{
           setBrandImages((prev)=>[...prev,url])
        })
    })
  }).catch((error) => {
   console.log(error)
  });
  }
  console.log(brandImages)

  const handleAddRepair = () =>{
    if(Object.keys(device).length === 0 || Object.keys(brand).length === 0 || Object.keys(model).length === 0){
      alert("Enter Valid Input")
    }
    else{
       dispatch(
      saveRepairDevice({
       
        deviceData: {
          deviceType: device?.device,
          brandName: brand?.name,
          manufacturerDetails: brand,
          modelName: model.name,
          deviceColor: "",
          devicePurchaseDate: "",
          imeiNo: "",
          devicePrice: "",
          purchaseBill: "",
        },
        // packData : {
        //   warrantyType : "",
        //   packName : plan?.packName,
        //   serviceCost : "",
        //   PackCost : plan?.planCost,
        //   packValidity : "",
        //   PackId : plan?.id
        // },
        // pickUpData : {
        //   geoPoint: {
        //     latitude: address.latitude,
        //     longitude: address.longitude
        //   },
        //   addLine1 : address.location,
        //   addLine2 : address.addressTwo,
        //   addLine3 :address.landmark,
        //   bookingTimeDate : day + "/" + monthYear + " - " + time + ":00"
        // },
      })
    ).then(()=>(
      navigate("/repairModels")
    ))
    }
    
  }
  
  useEffect(  ()=>{
    fetchAllStories()
    fetchFaqs()
  mobileBrandList()
    getModels()
    fetchTestimonials()
 if(isAuthenticated) {
    dispatch(loadUser(users));
  }
  
  setTimeout(handleModal, 3000)
    getBrandImage()
  
    sessionStorage.setItem("activePage", "Home");
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  },[device,brand])


  return (
    <>
 {!loading &&  <div className=' ' >
        <Navbar />
        <AddTestimonials testimonialDetail={testimonialDetail} setTestimonialDetail={setTestimonialDetail} handleAddTestimonial={handleAddTestimonial} openModal={openModal} handleOpenModal={handleOpenModal} /> 
        <div className='mt-[80px]' >
   
        <HomeCarousel handleAddRepair={handleAddRepair} models={models} model={model} setModel={setModel} mobileBrandList={mobileBrandList} brands={brands} brand={brand} setBrand={setBrand} deviceData={deviceData} device={device} setDevice={setDevice} active={active} setActive={setActive} />
        
  
        <RepairSteps pinCodeModal={pinCodeModal} setPinCodeModal={setPinCodeModal} />
         <Brands brandImages={brandImages}  />
         <Features scrollPosition={scrollPosition} />
         <Testimonial testimonialList={testimonialList} handleOpenModal={handleOpenModal} isAuthenticated={isAuthenticated} />
         <Stories stories={stories} />
           <Faqs faqs={faqs} />
      
       
       
       
        <DownloadApp />
        <BelowCTA />
       
        </div>
        <Footer bg="#0D1F37" />
    </div> }

     
    </>
  )
}

export default Home