import React from 'react'
import MobileRepair from "../../asset/mobileDevice.png"
import TabRepair from "../../asset/tablet.png"
import LaptopRepair from "../../asset/laptop.png"
const SelectDevice = ({device,setDevice,stage,setStage,mobileBrandList,tabBrandList,laptopBrandList}) => {
  return (
     <div>
        <div className='lg:px-16 px-8 py-12  lg:py-24' >
            <h3 className='lg:text-[2rem] text-[1.5rem] font-[600]' >Select a Device</h3>
            <div className='flex items-center mt-8 lg:mt-12 justify-start gap-4 lg:gap-12' >
                <div className='cursor-pointer' onClick={()=>{
                    setDevice("Mobiles")
                    setStage("select service")
                    mobileBrandList()
                }} >
                    <img className='w-[200px]  rounded-lg lg:px-6 lg:py-6 py-3 px-3 bg-gray-200 ' src={MobileRepair} alt="mobile repair"/>
                    <p className='text-center text-[1rem] lg:text-[1.5rem] mt-3 font-[600]' >Mobile</p>
                </div>
               <div className='cursor-pointer' onClick={()=>{
                    setDevice("Tablets")
                    setStage("select service")
                   tabBrandList()
                }}>
                    <img className='w-[200px]  rounded-lg lg:px-6 lg:py-6 py-3 px-3 bg-gray-200 ' src={TabRepair} alt="mobile repair"/>
                    <p className='text-center text-[1rem] lg:text-[1.5rem] mt-3 font-[600]'>Tablets</p>
                </div>
                <div  className='cursor-pointer' onClick={()=>{
                    setDevice("Laptops")
                    setStage("select service")
                   laptopBrandList()
                }}>
                    <img className='w-[200px]  rounded-lg lg:px-6 lg:py-6 py-3 px-3 bg-gray-200' src={LaptopRepair} alt="mobile repair"/>
                    <p className='text-center text-[1rem] lg:text-[1.5rem] mt-3 font-[600]'>Laptop</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SelectDevice