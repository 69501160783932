import React from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import TermsSection from '../Components/Others/TermsSection'
import BelowCTA from '../Components/Home/BelowCTA'

const Terms = () => {
  return (
    <div>
        <Navbar />
        <div className='lg:mt-[80px] md:mt-[130px] mt-[80px] font-[Inter] ' >
        <TermsSection />
        <BelowCTA />
            <Footer bg="#0D1F37" />
        </div>
    </div>
  )
}

export default Terms