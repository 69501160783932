
import { PhoneIcon,EnvelopeIcon } from '@heroicons/react/24/solid'
import WhatshappIcon from "../../asset/whatsapp.png"
import React from 'react'
import ReactWhatsapp from 'react-whatsapp';
import GetInTouch from "../../asset/getInTouch.svg"
const ContactSection = ({handleContactForm,contactInfo,setContactInfo}) => {
  return (
    <div className='bg-[#000000] md:grid grid-cols-2 items-start  justify-center gap-6 grid-flow-col px-6 py-6 lg:py-28 md:px-16 xl:px-48 '  >
        <div>
           <img className='xl:ml-[-50px] md:ml-[-20px] w-[300px]' src={GetInTouch} alt="" />
            <div className='my-8 md:w-[300px] xl:w-[400px]' >
                <input value={contactInfo.name}
                onChange={(e)=>setContactInfo({...contactInfo,name:e.target.value})}
                placeholder='Name' className='border-gray-700 my-2 font-[InterRegular] placeholder:font-[InterRegular] rounded-md bg-transparent text-white border-[1px] px-2 py-3 w-full' type="text" />
                <input value={contactInfo.email}
                onChange={(e)=>setContactInfo({...contactInfo,email:e.target.value})} placeholder='Email' className='border-gray-700 my-2 font-[InterRegular] placeholder:font-[InterRegular] rounded-md bg-transparent text-white border-[1px] px-2 py-3 w-full' type="email" />
                <input value={contactInfo.contact}
                onChange={(e)=>setContactInfo({...contactInfo,contact:e.target.value})} placeholder='Phone Number' className='border-gray-700 my-2 font-[InterRegular] placeholder:font-[InterRegular] rounded-md text-white bg-transparent border-[1px] px-2 py-3 w-full' type="number" />
                <textarea value={contactInfo.message}
                onChange={(e)=>setContactInfo({...contactInfo,message:e.target.value})} placeholder='Message' className='border-gray-700 h-[120px] my-2 font-[InterRegular] placeholder:font-[InterRegular] text-white rounded-md bg-transparent border-[1px] px-2 py-3 w-full'  ></textarea>
                <div>
                    <button onClick={handleContactForm} className='w-full bg-[#2967B0] font-[InterRegular]  text-white py-3  rounded-lg font-[500] ' >Submit</button>
                </div>
            </div>
        </div>
        <div className='bg-[#a1a1a149] py-6 px-4 md:py-12 md:h-[600px] md:px-12 xl:px-8 rounded-lg' >
            <div>
                <h4 className='text-white text-[2rem] font-[InterBold]  font-[600]' >Other Ways </h4>
                <p className='text-[#5F5F5F] font-[InterMedium]' >to reach out</p>
            </div>
            <div className='my-16' >
            <a href="tel:+91 8339833985">
                <div className='flex my-10  items-center justify-start gap-6' >
                    <PhoneIcon className='w-[30px] text-[#A4A4A4]' />
                    <h4 className='text-[#A4A4A4] font-[InterMedium]' >Get on call with us</h4>
                </div>
                </a>
                <a href="mailTo:care@zippyindia.in">
                  <div className='flex my-10  items-center justify-start gap-6' >
                    <EnvelopeIcon className='w-[30px] text-[#A4A4A4]' />
                    <h4 className='text-[#A4A4A4] font-[InterMedium]' >Send us an email</h4>
                </div>
                </a>
                <ReactWhatsapp number="8282822979" message="Hii" >
                  <div className='flex my-0  items-center justify-start gap-6' >
                    <img className='w-[30px]' src={WhatshappIcon} alt="" />
                    <h4 className='text-[#A4A4A4] font-[InterMedium]' >Connect on WhatsApp</h4>
                </div>
                </ReactWhatsapp>
            </div>
        </div>
    </div>
  )
}

export default ContactSection