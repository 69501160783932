import React,{useState,useEffect} from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import SelectDeviceBanner from '../Components/Shield/SelectDeviceBanner'
import SelectDevice from '../Components/Shield/SelectDevice'
import HowItWorks from '../Components/Shield/HowItWorks'
import FAQ from '../Components/Shield/FAQ'
import DownloadApp from '../Components/Home/DownloadApp'
import BelowCTA from '../Components/Home/BelowCTA'
import ServiceBanner from '../Components/Shield/ServiceBanner'
import SelectPlan from '../Components/Shield/SelectPlan'
import { ref, onValue, get} from "firebase/database";
import {database, db} from "../firebase.config"
import ManufacturerBanner from '../Components/Shield/ManufacturerBanner'
import SelectManufacturer from '../Components/Shield/SelectManufacturer'
import ModelBanner from '../Components/Shield/ModelBanner'
import SelectModel from '../Components/Shield/SelectModel'
import ServiceDetailsModal from '../Components/Shield/ServiceDetailsModal'
import DeviceDetailsModal from '../Components/Shield/DeviceDetailsModal'
import { and, collection, getDocs, query, where } from 'firebase/firestore'
import PackBanner from '../Components/Shield/PackBanner'
import SelectPack from '../Components/Shield/SelectPack'
import { useSelector,useDispatch } from 'react-redux'
import AddressModal from '../Components/Shield/AddressModal'
import TimeModal from '../Components/Shield/TimeModal'
import { saveShieldDevice } from '../actions/shieldActions'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Shield = () => {
   const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
    const [stage, setStage] = useState("select device")
    const [device, setDevice] = useState("")
    const [service, setService] = useState("")
    const [laptopRepairList, setLaptopRepairList] = useState([])
    const [tabRepairList, setTabRepairList] = useState([])
    const [mobileRepairList, setMobileRepairList] = useState([])
    const [manufacturer, setManufacturer] = useState({})
    const [model, setModel] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [detailsModal, setDetailsModal] = useState(false)
    const [deviceModal, setDeviceModal] = useState(false)
    const [purchaseDate, setPurchaseDate] = useState(new Date())
    const [imeiOne, setImeiOne] = useState("")
    const [imeiTwo, setImeiTwo] = useState("")
    const [devicePrice, setDevicePrice] = useState(null)
    const [pinCode, setPinCode] = useState("")
    const [servicePacks, setServicePacks] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [addressModel, setAddressModel] = useState(false)
    const [timeModal, setTimeModal] = useState(false)
    const [alternateNumber, setAlternateNumber] = useState("")
    const [day, setDay] = useState("")
    const [plan, setPlan] = useState("")
    const [shieldOrderList, setShieldOrderList] = useState([])
    const [purchaseBill, setPurchaseBill] = useState("")
    const [filteredModelList, setFilteredModelList] = useState(manufacturer?.models)
    const [locationData, setLocationData] = useState({})
    const [addresses, setAddresses] = useState([])
    const [monthYear, setMonthYear] = useState("")
    const [isLogin, setIsLogin] = useState(false)
    const navigate = useNavigate()
  const [time, setTime] = useState("")
  const dispatch = useDispatch();
     const [address, setAddress] = useState({
    name:userProfile?.FirstName,
    location:"",
    addressTwo:'',
    landmark:"",
    latitude:0,
    longitude:0,
    shortAddress:'',
    longAddress:'',
    alternameNumber:userProfile?.ContactNo ? userProfile?.ContactNo : ''
  })
    const handleDetailsModal = () =>{
      setDetailsModal(!detailsModal)
    }
  
    const handleDeviceModal = () =>{
      setDeviceModal(!deviceModal)
    }
    const handleTimeOpenModal = () =>{
        setTimeModal(!timeModal)
       
      }
     const tabBrandList = () =>{
         const repairCountRef = ref(database, 'repair/laptopRepair');

       onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setTabRepairList(newProducts)
       })
      }
        const laptopBrandList = () =>{
         const repairCountRef = ref(database, 'repair/mainLaptopRepair');

       onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setLaptopRepairList(newProducts)
       })
      }
       const mobileBrandList = () =>{
         const repairCountRef = ref(database, 'repair/mobileRepair');

       onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setMobileRepairList(newProducts)
       })
      }
      console.log(manufacturer,model,mobileRepairList)
       const handleAddressOpenModal = () =>{
        setAddressModel(!addressModel)
       
      }
      const handleSaveAddress = () =>{
        setAddressModel(!addressModel)
        setTimeModal(!timeModal)
      }
      const handleServicePacks = async () =>{

          
            const querySnapshot = await getDocs(collection(db,"shieldProduct"));
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setServicePacks((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
        
      }
       const fetchShieldOrder =  async () =>{
    
    
    console.log("newOrder")
   const querySnapshot = await getDocs(collection(db, "shieldOrders"));
    querySnapshot.forEach((doc) => {
     setShieldOrderList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
     
     
  
    });
    }
      console.log(devicePrice)
       const checkDay = (currentDate) =>{
      switch (currentDate.getDay()) {
   case 0:
    return "Sunday"
  case 1:
   return "Monday"
  case 2:
  return "Tuesday"
  case 3:
    return "Wednesday"
  case 4:
     return "Thrusday"
  case 5:
     return "Friday"
  case 6:
    return "Saturday"
    default:
    return ""
       
}
    }
     const handleSetDate = () =>{
          setTimeModal(!timeModal)
         if(isAuthenticated){
           handleShieldOrder()
              navigate("/shieldCart")
         }
         else{
          setIsLogin(true)
         }
      }
      const handleFilterPacks = () =>{
        let filterList = []
        servicePacks?.map((pack)=>{
          console.log(Number(pack?.startingPrice) <= Number(devicePrice),)
          if(pack?.PackGroup === service && pack?.brand?.includes(manufacturer?.brand) && pack?.gadgets?.includes(device) && Number(pack?.startingPrice) <= Number(devicePrice)  && Number(pack?.endingPrice) >= Number(devicePrice) ){
            console.log(JSON.stringify(pack))
            filterList.push(pack)
             
        }})
       setFilteredList(filterList)
      }
      const filterBySearch = (event) => {
  // Access input value
  const query = event.target.value;
  // Create copy of item list
  var updatedList = [...manufacturer?.models];
  // Include all elements which includes the search query
  updatedList = updatedList.filter((item) => {
    return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
});
  // Trigger render with updated values
  setFilteredModelList(updatedList);
};
        const handleShieldOrder = () =>{
        dispatch(saveShieldDevice({
    orderType : "Shield Pack",
    
    orderStatus : "Pending",
    problemList : [],
    problemDesc : "",
    recordedProblems : "",
    deviceData : {
      deviceType : device,
      brandName : manufacturer?.brand,
      modelName : model,
      deviceColor  : "",
      devicePurchaseDate : purchaseDate,
      imeiNo : imeiOne,
      devicePrice : devicePrice,
      purchaseBill : purchaseBill
    },
    packData : {
      warrantyType : plan?.PackGroup,
      packName : plan?.PackName,
      serviceCost : "",
      PackCost : plan?.price,
      packValidity : plan?.service?.serviceDays,
      PackId : plan?.id
    },
    pickUpData : {
      geoPoint: {
        latitude: address.latitude,
        longitude: address.longitude
      },
      addLine1 : address.location,
      addLine2 : address.landmark,
      addLine3 : address.addressTwo,
      bookingTimeDate : day + "/" + monthYear + " - " + time
    },
    personalData : {
      fullName : userProfile?.name,
      emailId : userProfile?.email,
      contactNo : userProfile?.phone
    },
   
  }))
      }
      const handleLocationData =  (latitude,longitude) =>{
      if(address)
       axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`).then((response)=>{
        setLocationData(response?.data?.results)
        console.log(response?.data?.results)
        setAddress({...address,
          addressTwo:response?.data?.results[4]?.formatted_address,
          shortAddress:response?.data?.results[8]?.formatted_address,
        longAddress:response?.data?.results[3]?.formatted_address,
        latitude:latitude,
        longitude:longitude
        })
       }).catch(err=>{
        console.log(err.message)
       })
      
    }
     const fetchAddresses = () =>{

      const reviewCountRef = ref(database, 'addresses/' + users);

    onValue(reviewCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));

      setAddresses(newProducts)
      }
   


    },);
    }
      console.log(servicePacks,service)
     
    useEffect(() => {
       navigator.geolocation.getCurrentPosition(function(position) {
    setAddress({...address,
      latitude:position.coords.latitude,
      longitude:position.coords.longitude})
     handleLocationData(position.coords.latitude,position.coords.longitude)
    });
      handleServicePacks()
      if(users && isAuthenticated){
         fetchAddresses()
      }
       if(isLogin && isAuthenticated){
         handleShieldOrder()
      navigate("/shieldCart")
    }
     
    }, [isLogin,isAuthenticated])
  return (
    <div className=' font-[Inter]'>
        <Navbar isLogin={isLogin} />
        <div className='lg:mt-[80px] mt-[80px] md:mt-[130px] '> 
      <ServiceDetailsModal service={service} handleDetailsModal={handleDetailsModal} detailsModal={detailsModal} setDetailsModal={setDetailsModal} stage={stage} setStage={setStage} />
      <DeviceDetailsModal handleServicePacks={handleServicePacks} handleFilterPacks={handleFilterPacks} stage={stage} setStage={setStage} devicePrice={devicePrice} pinCode={pinCode} setPinCode={setPinCode} setDevicePrice={setDevicePrice} imeiOne={imeiOne} setImeiOne={setImeiOne} imeiTwo={imeiTwo} setImeiTwo={setImeiTwo} purchaseDate={purchaseDate} setPurchaseDate={setPurchaseDate} deviceModal={deviceModal} setDetailsModal={setDetailsModal} handleDeviceModal={handleDeviceModal} />
     { addressModel && <AddressModal addresses={addresses} handleSaveAddress={handleSaveAddress} alternateNumber={alternateNumber} setAlternameNumber={setAlternateNumber} handleAddressOpenModal={handleAddressOpenModal} address={address} locationData={locationData} setLocationData={setLocationData} setAddress={setAddress} addressModel={addressModel} setAddressModel={setAddressModel}  /> }
      { timeModal && <TimeModal monthYear={monthYear} setMonthYear={setMonthYear} addressModel={addressModel} setAddressModel={setAddressModel} handleRepairOrder={handleShieldOrder} handleSetDate={handleSetDate} day={day} time={time} setTime={setTime} setDay={setDay} checkDay={checkDay} address={address} timeModal={timeModal} handleTimeOpenModal={handleTimeOpenModal} setTimeModal={setTimeModal}  /> }
             { stage === "select device" && <> <SelectDeviceBanner />
                <SelectDevice laptopBrandList={laptopBrandList} tabBrandList={tabBrandList} mobileBrandList={mobileBrandList} device={device} setDevice={setDevice} stage={stage} setStage={setStage} /></>}
            { stage === "select service" && <> <ServiceBanner device={device} setDevice={setDevice} stage={stage} setStage={setStage} />
               <SelectPlan handleDetailsModal={handleDetailsModal}  service={service} setService={setService} stage={stage} setStage={setStage}  /> </>}
               {
                stage === "select manufacturer" && (
                  <>
                    <ManufacturerBanner service={service}  device={device} stage={stage} setStage={setStage}  />
                    <SelectManufacturer filteredModelList={filteredModelList} setFilteredModelList={setFilteredModelList} stage={stage} setStage={setStage} device={device} manufacturer={manufacturer} setManufacturer={setManufacturer} laptopRepairList={laptopRepairList} tabRepairList={tabRepairList} mobileRepairList={mobileRepairList} />
                  </>
                )
               }
               {
                stage === "select model" && (
                  <>
                   <ModelBanner  device={device} service={service} manufacturer={manufacturer} stage={stage} setStage={setStage} />
                   <SelectModel stage={stage} setStage={setStage} filterBySearch={filterBySearch} filteredModelList={filteredModelList}  handleDeviceModal={handleDeviceModal} model={model} setModel={setModel} manufacturer={manufacturer} openModal={openModal} setOpenModal={setOpenModal} />
                  </>
                )
               }
                {
                stage === "select pack" && (
                  <>
                   <PackBanner device={device} service={service} manufacturer={manufacturer} stage={stage} setStage={setStage} imeiOne={imeiOne} purchaseDate={purchaseDate} devicePrice={devicePrice} />
                   <SelectPack plan={plan} setPlan={setPlan} handleAddressOpenModal={handleAddressOpenModal} stage={stage} setStage={setStage} filteredList={filteredList} />
                  </>
                )
               }
                <HowItWorks />
                <FAQ />
                <DownloadApp />
                <BelowCTA />
        <Footer />
         </div>
    </div>
  )
}

export default Shield