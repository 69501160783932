import React from 'react'
import Download from "../../asset/download.png"
import AppleIcon from "../../asset/appleicon.png"
import PlaystoreIcon from "../../asset/playstoreicon.png"
import BackgroundImage from "../../asset/ctaBanner.svg"
import GooglePlayButton from "../../asset/googlePlayButton.svg"
import ApplePlayButton from "../../asset/appleButton.svg"
import CtaMobile from "../../asset/ctaMobiles.svg"
import {Zoom} from "react-reveal"
const DownloadApp = () => {
  return (
    <Zoom>
    <div style={{backgroundImage:`url(${BackgroundImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}} className='xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]   flex items-start  justify-between  ' >
    
    <div className='xl:w-[450px] lg:w-[450px] md:w-[400px] my-6 lg:mt-[45px] xl:mt-[74px] ' >
        <h4 className='xl:text-[48px] lg:text-[35px] md:text-[26px] font-[InterBold] lg:leading-[50px] xl:leading-[64px] text-[1.7rem] text-white' >Spread the word! <br /> about Zepair</h4>
        <p className='xl:text-[18px] lg:text-[16px] md:text-[13px] font-[InterMedium] text-[.9rem] mt-2 text-[#FFFFFF]' >Don’t wait for disaster to strike your devices. Choose Zepair today and secure a hassle-free future with your gadgets. Get started now!</p>
        <p className='lg:mt-[44px]  md:mt-[30px] mt-8 text-[#FFFFFF] font-[InterMedium] text-[16px] ' >Get the App: </p>
         <div className='mt-4 font-[InterMedium] flex  gap-4  flex-row items-start md:items-center justify-start  ' >
          <a target='_blank' rel='noreferrer' href="https://play.google.com/store/apps/details?id=com.zippy.consumer">    <img className='md:w-[100px] w-[120px] lg:w-[150px]  ' src={GooglePlayButton} alt="" /> </a>  
          <a target='_blank' rel='noreferrer' href="https://apps.apple.com/in/app/zippy-india/id6466287109">     <img className='md:w-[100px] w-[120px] lg:w-[150px]  ' src={ApplePlayButton} alt="" /> </a>  
                </div>
    </div>
    <div className='md:block hidden' >
      <img className='xl:w-[550px] lg:w-[450px] md:w-[400px]' src={CtaMobile} alt="" />
    </div>
      
      
    </div>
    </Zoom>
  )
}

export default DownloadApp