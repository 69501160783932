import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import {Button} from "@material-tailwind/react"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import Choose from "../../asset/shield/choose.png"
import Insurance from "../../asset/shield/macbookcarepro.png"
import FormFill from "../../asset/shield/formfill.png"
import Payment from "../../asset/shield/payment.png"
const HowItWorks = () => {
  return (
    <div className='lg:px-28 my-6 px-12 py-12 bg-gray-200' >
        <div>
                <h4 className='font-[600] text-[2rem]' >How It Works</h4>
                <div className='' >
              <Swiper
          spaceBetween={15}
          className="py-[10px] my-12"
          draggable={true}
          breakpoints={{
        576: {
          width: 576,
          slidesPerView: 2,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
        1300: {
          width: 1300,
          slidesPerView: 4,
        },
         1440:{
          width:1440,
          slidesPerView:4.5
        }
      }}
    
          
          loop={false}
       

        >
           
       <SwiperSlide >
        
            <div className=' xl:w-[260px] h-[250px] xl:h-[250px] w-[94%] mx-0  flex items-center justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
               <img src={Choose} className='w-[140px]' alt="" />
                <h4 className='font-[600]  mt-6 text-[16px] text-center ' >(i) Choose the device</h4>
                <p className='text-center text-[.8rem]' >Tell us which phone has to be repaired. Get the best pricing.</p>
            </div>
            </SwiperSlide>
          <SwiperSlide >
        
            <div className=' xl:w-[260px] h-[250px] xl:h-[250px] w-[94%] mx-0  flex items-center justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
               <img src={Insurance} className='w-[140px]' alt="" />
                <h4 className='font-[600]  mt-6 text-[16px] text-center ' >(ii) Choose desired insurance</h4>
                <p className='text-center text-[.8rem]' >Register your claim on our App or on our Website</p>
            </div>
            </SwiperSlide>
              <SwiperSlide >
        
            <div className=' xl:w-[260px] h-[250px] xl:h-[250px] w-[94%] mx-0  flex items-center justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
               <img src={FormFill} className='w-[140px]' alt="" />
                <h4 className='font-[600]  mt-6 text-[16px] text-center ' >(iii) Fill the form</h4>
                <p className='text-center text-[.8rem]' >Register your claim on our App or on our Website</p>
            </div>
            </SwiperSlide>
              <SwiperSlide >
        
            <div className=' xl:w-[260px] h-[250px] xl:h-[250px] w-[94%] mx-0  flex items-center justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
               <img src={Payment} className='w-[140px]' alt="" />
                <h4 className='font-[600]  mt-6 text-[16px] text-center ' >(iv) Pay</h4>
                <p className='text-center text-[.8rem]' >Register your claim on our App or on our Website</p>
            </div>
            </SwiperSlide>
            
     
        </Swiper>
                </div>
        </div>
    </div>
  )
}

export default HowItWorks