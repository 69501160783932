import React from 'react'
import SelectBanner from "../../asset/shield/laptopxtended.png"
import MobileRepair from "../../asset/mobileDevice.png"
import TabRepair from "../../asset/tablet.png"
import LaptopRepair from "../../asset/laptop.png"
import ExtendedWarrenty from "../../asset/feature23.png"
import Accidental from "../../asset/feature2.png"
import { ArrowLeftCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
const PackBanner = ({service,device,manufacturer,purchaseDate,imeiOne,devicePrice,stage,setStage}) => {
  return (
    <div className='xl:px-24 relative flex items-center justify-between bg-gradient-to-r from-[#e0572d76] to-[#FFFFFF]  px-4 py-4 xl:py-12'>
         <div>
            <h4 className='text-[1.5rem] lg:text-[2rem] font-[700]' >Ultimate Device <br /> Protection</h4>
            <div onClick={()=>setStage("select model")} className='flex w-[80px] lg:w-[100px] absolute md:top-[25px] top-[10px]  md:right-[25px]  text-white font-[600]  cursor-pointer rounded-lg py-2 lg:py-3 px-2 lg:px-4 bg-black items-center justify-start gap-3 ' >
      <ArrowLeftIcon className='lg:w-[20px] w-[10px] ' />
      <p className='text-[.8rem] lg:text-[1rem]' >Back</p>
    </div>
            <div className='flex items-start md:items-center mt-6  md:mt-12 flex-wrap lg:flex-nowrap justify-start gap-4' >

            
            <div className='md:w-[180px] mt-4 w-[150px]' >
                <h4 className='my-2 md:text-[1rem] text-[.8rem] text-black mb-4' >Select Device</h4>
                <div className='rounded-lg lg:h-[170px] h-[100px] md:h-[110px]  flex items-center justify-center bg-white flex-col md:px-6 px-2 py-2 md:py-3 border-[1px] border-[#59A4FF]'>
                  {  device === "Mobiles" &&  <img className='md:w-[60px] w-[40px] ' src={MobileRepair} alt="" />}
                  {  device === "Tablets" &&  <img className='md:w-[60px] w-[40px] ' src={TabRepair} alt="" />}
                  {  device === "Laptops" &&  <img className='md:w-[60px] w-[40px] ' src={LaptopRepair} alt="" />}
                     {  device === "Mobiles" &&  <p className=' text-[.7rem] md:text-[1rem] mt-2 '   > Mobile</p>}
                      {  device === "Tablets" &&  <p className=' text-[.7rem] md:text-[1rem] mt-2'   > Tablet</p>}
                       {  device === "Laptops" &&  <p className='text-[.7rem] md:text-[1rem] mt-2  '  > Laptop</p>}
                </div>
            </div>
              <div className='mt-4 md:w-[180px] w-[150px]' >
                <h4 className='my-2 md:text-[1rem] text-[.8rem] text-black mb-4' >Select Service</h4>
                <div className='rounded-lg lg:h-[170px] h-[100px] md:h-[110px]  flex items-center justify-center bg-white flex-col md:px-6 px-2 py-2 md:py-3 border-[1px] border-[#59A4FF]'>
                  {  service === "Zippy X-Tended" &&  <img className='lg:w-[80px] w-[30px] ' src={ExtendedWarrenty} alt="" />}
                  {  service === "Zippy Care" &&  <img className='lg:w-[80px] w-[30px]' src={Accidental} alt="" />}
               
                     {  service === "Zippy X-Tended" &&  <p className='text-[.7rem] md:text-[1rem] mt-2 '   > Extended Warrenty</p>}
                      {  service === "Zippy Care" &&  <p className='text-[.7rem] md:text-[1rem] mt-2'   > Accidental & Liquid Damage</p>}
                
                </div>
            </div>
             <div className='mt-4 md:w-[180px] w-[150px]' >
                <h5 className='my-2 md:text-[1rem] text-[.8rem] text-black mb-4  ' >Selected Model</h5>
                <div className='rounded-lg lg:h-[170px] h-[100px] md:h-[110px]  flex items-center justify-center bg-white flex-col md:px-6 px-2 py-2 md:py-3 border-[1px] border-[#59A4FF]'>
                     <img className='lg:w-[100px] w-[50px]   ' src={manufacturer?.image} alt="" />
                     <p  className='text-[.7rem] md:text-[1rem] mt-2' >{manufacturer?.brand}</p>
                </div>
               
            </div>
             <div className='mt-4 lg:w-[300px] md:w-[250px] w-[150px]' >
                <h5 className='my-2 md:text-[1rem] text-[.8rem] text-gray-600 mb-4  ' >Issues</h5>
                <div className='rounded-lg lg:h-[170px]  md:h-[140px]  flex items-start  justify-center bg-white flex-col md:px-6 px-2 py-2 md:py-3 border-[1px] border-[#59A4FF]'>
                     <p className='text-[.6rem] md:text-[.8rem] lg:text-[.95rem]' >Date of Purchase: <strong> {purchaseDate.getDate() + "/"+purchaseDate.getMonth()+"/"+purchaseDate.getFullYear()}</strong></p>
                     <p className='text-[.6rem] md:text-[.8rem] lg:text-[.95rem]' >IMEI Number: <strong> {imeiOne}</strong></p>
                     <p className='text-[.6rem] md:text-[.8rem] lg:text-[.95rem]' >Device Price: <strong> {devicePrice}</strong></p>
                </div>
               
            </div>
            </div>
        </div>
        <div className='hidden lg:flex items-center justify-center gap-3' >
        <div>
            <img className='w-[200px]' src={SelectBanner} alt="" />
            </div>
            <div>
              <h4 className='font-[600] text-[1.3rem]' >Buy Insurance</h4>
              <p className='text-[.8rem]' >(Device purchased within 6 months)</p>
            </div>
        </div>
        
    </div>
  )
}

export default PackBanner