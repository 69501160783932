import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
  
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import GuildelinesIcon from "../../asset/guildelinesIcon.png"
import GoogleMapReact from 'google-map-react'
import "./Repair.css"
import { Map } from '@googlemaps/react-wrapper'
import { MapPinIcon } from "@heroicons/react/24/solid";
import LocationMarker from "../Layout/LocationMarker";
import { MapIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Autocomplete from "react-google-autocomplete";
import PlaceAutocomplete from "./PlaceAutocomplete";
import CrossIcon from "../../asset/crossIcon.svg"
const AddressModel = ({addressModel,setAddressModel,handleAddressOpenModal,address,setAddress,handleSaveAddress,alternateNumber,setAlternameNumber,locationData,addresses,setLocationData,handleLocationData,setGeometry,geometry,currentLocation,map,setMap,onLoad}) => {
 const defaultProps = {
    center: {
      lat: geometry.lat,
      lng: geometry.lng
    },
    zoom: 15
  };
  console.log(address)
  const [activeSection, setActiveSection] = useState("add address")
 const [locationError, setLocationError] = useState(false)
  const [locationChange, setLocationChange] = useState(false)
  const [landmarkError, setLandmarkError] = useState(false)
  const [landmarkChange, setLandmarkChange] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [contactChange, setContactChange] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [nameChange, setNameChange] = useState(false)

  const [markerPosition, setMarkerPosition] = useState({ lat: address?.latitude, lng: address?.longitude });
    const handleAddressSave = () =>{
    if(address?.name === ""){
        setNameError(true)
    }
    else if(address?.location.length === 0){
      setLocationError(true)
    }
   
    else if(address.alternameNumber.length === 0 || address.alternameNumber.length > 10 || address.alternameNumber.length < 10){
      setContactError(true)
    }
   
    else{
      handleSaveAddress()
    }

  }
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCeV4tAAnF0MUsAiJL1vMBN-4AOzpIrj0A', // Replace with your API key
  });

  const mapStyles = {
    height: '100%',
    width: '100%',
    borderRadius:'10px'
  };
  const mapStylesTwo = {
    height: '250px',
    width: '100%',
    borderRadius:'10px'
  };


  const onMarkerDragEnd = (e) => {
    setGeometry({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
   
    handleLocationData(e.latLng.lat(),e.latLng.lng())
  };

 

    console.log(geometry)
  useEffect(() => {
   
  }, [])
 
  return (
     <div>
      <Dialog
        size="xl"
        open={addressModel}
        handler={handleAddressOpenModal}
        className="bg-transparent   shadow-none"
      >
        <Card className="lg:mx-auto  relative    w-full ">
        <div onClick={()=>setAddressModel(!addressModel)} className="absolute cursor-pointer top-2 right-[10px] w-[40px] h-[40px]" >
           <img src={CrossIcon} alt="" />
        </div>
          <CardBody className="grid lg:gap-[35px] p-2  grid-cols-1  h-[550px] overflow-y-scroll  lg:overflow-hidden  lg:grid-cols-2 ">
          <div className="hidden lg:block " >
        
          {
            isLoaded &&
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={15}
     
  
      center={{lat:geometry.lat,lng:geometry.lng}}
      onLoad={onLoad}
    >
      <Marker
        position={{lat:geometry.lat,lng:geometry.lng}}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
    </GoogleMap>
  
          }
            
          </div>
            <div className="lg:hidden block " >
        
          {
            isLoaded &&
    <GoogleMap
      mapContainerStyle={mapStylesTwo}
      zoom={15}
     
  
      center={{lat:geometry.lat,lng:geometry.lng}}
      onLoad={onLoad}
    >
      <Marker
        position={{lat:geometry.lat,lng:geometry.lng}}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
    </GoogleMap>
  
          }
            
          </div>
          { activeSection === "add address" ?  (<div className=" xl:w-[450px] mt-3 md:mt-6" >
              <h4 className="text-[16px] text-[#686868] font-[InterMedium] uppercase" >Select Location</h4>
              <div className="border-b-[10px] pb-4 border-[#F4F4F4]" >
              <div className="my-3 flex items-center justify-start gap-3 " >
              <div>
                <MapPinIcon className="w-[20px] text-blue-500" />
              </div>
                <div>
                  <p className=" text-[#0D1F37] font-[InterBold] lg:text-[18px] " >{address.longAddress.slice(0,25)}...</p>
                 
                </div>
                <div>
                  <button onClick={()=>setActiveSection("change address")} className="bg-gray-100 rounded-lg text-[.7rem] px-6 py-2 font-[500] text-blue-500" >Change</button>
                </div>
              </div>
               {/* <p className="font-[400] text-gray-500 text-[.8rem] lg:text-[14px] ">{address.longAddress.slice(45,125)}</p> */}
               </div>
              <div className="my-6 flex items-start justify-center flex-col gap-2 lg:gap-4 " >
              <div className="xl:w-[450px] w-full" >
              <Input error={nameError ? true : false} value={address.name} onChange={(e)=>{
                setAddress({...address,name:e.target.value})
                setNameChange(true)
              }}  label="Name" />
              <p className="text-red-600 mt-2 text-[.8rem]" >{nameError && "Enter a Valid Name"}</p>
              </div>
              <div className="xl:w-[450px] w-full" >
              <Input error={locationError ? true : false} value={address.location} onChange={(e)=>{
                setAddress({...address,location:e.target.value})
                setLocationChange(true)
              }}  label="Apartment / Building / Area" />
               <p className="text-red-600 mt-2 text-[.8rem]" >{locationError && "Enter a Valid Location"}</p>
               </div>
               <div className="xl:w-[450px] w-full">
              <Input  value={address.landmark} onChange={(e)=>{
                setAddress({...address,landmark:e.target.value})
                setLandmarkChange(true)
              }}  label="Landmark(optional)" />
           
              </div>
              <div className="xl:w-[450px] w-full">
                <Input error={contactError ? true : false} type="number" value={address.alternameNumber} onChange={(e)=>{
                setAddress({...address,alternameNumber:e.target.value})
                setContactChange(true)
              }}  label="Alternate Number" />
                 <p className="text-red-600 mt-2 text-[.8rem]" >{contactError && "Enter a Valid Contact Number"}</p>
                 </div>
              </div>
              <div className="flex items-start justify-start gap-4 md:gap-6" >
            
              <button onClick={handleAddressSave} className="bg-[#2967B0] font-[InterMedium] px-4 w-[150px] text-[12px]  md:w-[242px] lg:px-8 font-[500]  py-3 rounded-lg text-white" >Save and Proceed</button>
              </div>
            </div>):(
                <div className="xl:w-[400px] mt-3 md:mt-6">
                <div className="py-4 border-gray-200 border-b-[5px]" >
                  <h4 className="font-[700] text-[#0D1F37] fon-[InterBold] text-[1.8rem]" >Help us locate you</h4>
                  <p className="my-1 text-gray-600" >This will ensure accurate choice of services</p>
                  <div className="my-2" >
                    
                    <PlaceAutocomplete geometry={geometry} setGeometry={setGeometry} handleLocationData={handleLocationData} address={address} setAddress={setAddress} setLocationData={setLocationData} locationData={locationData}  />
                  </div>
                  <p onClick={()=>{
                    currentLocation()
                    setActiveSection("add address")}} className="text-blue-600  cursor-pointer flex items-center justify-start gap-4 my-4 font-[500]" ><MapIcon className=" w-[30px]"  /> Use current location using GPS</p>
                  </div>
                  <div className="my-4" >
                    <h4 className="text-gray-500 font-[500]" >Saved Address</h4>
                    <div>
                      {
                      addresses &&  addresses?.map((item)=>(
                        <div 
                        onClick={()=>{
                          
                         
                          setAddress({
                            ...address,
                            name:item.name,
                             location:item.location,
                            addressTwo:item.addressTwo,
                              landmark:item.landmark,
                              latitude:item.latitude,
                              longitude:item.longitude,
                              alternameNumber:Number(item.alternameNumber)
                          })
                          handleLocationData(item.latitude,item.longitude)
                          setActiveSection("add address")
                        }
                      
                        }
                          className="border-b-[1px] cursor-pointer py-3 border-gray-200" >
                          <h4 className="text-black font-[600] text-[1.1rem]" >{item.name}</h4>
                          <p>{item.location},{item.landmark}</p>
                          <p>{item.alternameNumber}</p>
                        </div>
                      ))
                      }
                    </div>
                  </div>
                  <div className="flex items-center justify-start lg:justify-end gap-4" >
                   <button onClick={()=>setActiveSection("add address")} className="bg-gray-400  w-[120px] font-[InterMedium] text-[.8rem]  md:w-[250px] px-4 lg:px-8 font-[500]  py-3 rounded-lg text-white" >Cancel</button>
                    <button onClick={()=>{
                setActiveSection("add address")
                
                }} className="bg-[#2967B0] px-4 w-[full] text-[12px] font-[InterMedium]  md:w-[250px] lg:px-8 font-[500]  py-3 rounded-lg text-white" >Save and Proceed</button>
                  </div>
                </div>
            )}
            
              
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default AddressModel