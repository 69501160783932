import React, { useState } from "react";
import Dropdown from "./Dropdown";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const MultiSelect = ({options,issue,setIssue,addTag,removeTag,toogleDropdown,dropdown}) => {
  
    // state showing if dropdown is open or closed
    
    // managing dropdown items (list of dropdown items)

    // contains multiselect items
    const [selectedItems, setSelected] = useState([]);

    // toggle dropdown open/close  
   
   
  return (
   <div className="autcomplete-wrapper">
        <div className="autcomplete">
        <div className="w-full flex flex-col items-center mx-auto">
    <div className="w-full">
        <div className="flex flex-col items-start justify-start relative">
            <div className="w-[300px] md:w-[400px] ">
                <div className="my-2 p-1 flex border border-[#DCDCDC] bg-white rounded ">
                    <div className="flex flex-auto flex-wrap">
                        {
                            issue.map((tag, index) => {
                                return (
                                    <div key={index} className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-[#59A4FF] bg-[#F6FBFF] border border-[#59A4FF] ">
                                            <div className="text-xs font-normal leading-none max-w-full flex-initial">{ tag.name }</div>
                                            <div className="flex flex-auto font-[InterMedium] flex-row-reverse">
                                                <div onClick={() => removeTag(tag)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                                                    className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>)
                            })
                        }
                        <div className="">
                            <input disabled={true} onClick={toogleDropdown} placeholder={issue.length === 0 && "Select an Issue"} className="bg-transparent py-2 px-2 appearance-none placeholder:font-[InterRegular] placeholder:text-[#959595] outline-none md:h-full md:w-full text-gray-800"/>
                        </div>
                    </div>
                    <div className="text-gray-300 w-8 py-1 pl-2 pr-1  flex items-center border-gray-200" onClick={toogleDropdown}>
                    <ChevronDownIcon className="text-[#000000]" />
                        {/* <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up w-4 h-4">
                                <polyline points="18 15 12 9 6 15"></polyline>
                            </svg>
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
         { dropdown  ? <Dropdown list={options} addItem={addTag}></Dropdown>: null }
    </div>
</div>

    </div>
        </div>
  );
};

export default MultiSelect;