import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
  
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import GuildelinesIcon from "../../asset/guildelinesIcon.png"
import GoogleMapReact from 'google-map-react'
import LocationMarker from "../Layout/LocationMarker";

import Map from "../../asset/map.png"
import { MapIcon, MapPinIcon } from "@heroicons/react/20/solid";
import PlacesAutocomplete from "../Repair/PlaceAutocomplete";
const AddressModal = ({addressModel,setAddressModel,handleAddressOpenModal,address,setAddress,handleSaveAddress,alternateNumber,setAlternameNumber,locationData,setLocationData,addresses}) => {
  const [locationError, setLocationError] = useState(false)
  const [locationChange, setLocationChange] = useState(false)
  const [landmarkError, setLandmarkError] = useState(false)
  const [landmarkChange, setLandmarkChange] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [contactChange, setContactChange] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [nameChange, setNameChange] = useState(false)

  const handleAddressSave = () =>{
    if(address?.name === ""){
        setNameError(true)
    }
    else if(address?.location.length === 0){
      setLocationError(true)
    }
   
    else if(address.alternameNumber.length === 0 || address.alternameNumber.length > 10 || address.alternameNumber.length < 10){
      setContactError(true)
    }
    else{
      handleSaveAddress()
    }

  }
   const defaultProps = {
    center: {
      lat: address.latitude,
      lng: address.longitude
    },
    zoom: 15
  };
  const [activeSection, setActiveSection] = useState("add address")

  return (
     <div>
      <Dialog
        size="xl"
        open={addressModel}
        handler={handleAddressOpenModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto   w-full ">
          <CardBody className="lg:grid gap-3 grid-cols-2 grid-flow-col">
          <div>
             <GoogleMapReact
          className=""
        bootstrapURLKeys={{ key: "AIzaSyCeV4tAAnF0MUsAiJL1vMBN-4AOzpIrj0A" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
      <LocationMarker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          text="That is Our Location"
        />
      </GoogleMapReact>
          
          </div>
          { activeSection === "add address" ? ( <div className="lg:mx-12 mx-0 mt-6" >
              <h4 className="text-[1.2rem] font-[500] uppercase" >Select Location</h4>
              <div className="my-3 flex items-center justify-start gap-4 " >
              <div>
                <MapPinIcon className="w-[20px] text-blue-500" />
              </div>
                <div>
                  <p className="font-[600] text-black lg:text-[1.3rem] " >{address.longAddress.slice(0,25)}...</p>
                  <p className="font-[400] text-gray-500 text-[.8rem] lg:text-[1rem] ">{address.shortAddress}</p>
                </div>
                <div>
                  <button onClick={()=>setActiveSection("change address")} className="bg-gray-100 rounded-lg text-[.7rem] px-6 py-2 font-[500] text-blue-500" >Change</button>
                </div>
              </div>
              <div className="my-6 flex items-center justify-center flex-col gap-2 lg:gap-4 " >
              <div className="lg:w-[450px] w-full" >
              <Input error={nameError ? true : false} value={address.name} onChange={(e)=>{
                setAddress({...address,name:e.target.value})
                setNameChange(true)
              }}  label="Name" />
              <p className="text-red-600 mt-2 text-[.8rem]" >{nameError && "Enter a Valid Name"}</p>
              </div>
              <div className="lg:w-[450px] w-full" >
              <Input error={locationError ? true : false} value={address.location} onChange={(e)=>{
                setAddress({...address,location:e.target.value})
                setLocationChange(true)
              }}  label="Apartment / Building / Area" />
               <p className="text-red-600 mt-2 text-[.8rem]" >{locationError && "Enter a Valid Location"}</p>
               </div>
               <div className="lg:w-[450px] w-full">
              <Input  value={address.landmark} onChange={(e)=>{
                setAddress({...address,landmark:e.target.value})
                setLandmarkChange(true)
              }}  label="Landmark(optional)" />
           
              </div>
              <div className="lg:w-[450px] w-full">
                <Input type="number" error={contactError ? true : false} value={address.alternameNumber} onChange={(e)=>{
                setAddress({...address,alternameNumber:e.target.value})
                setContactChange(true)
              }}  label="Alternate Number" />
                 <p className="text-red-600 mt-2 text-[.8rem]" >{contactError && "Enter a Valid Contact Number"}</p>
                 </div>
              </div>
              <div className="flex items-center justify-center gap-2 lg:gap-6" >
              <button onClick={()=>setAddressModel(false)} className="bg-gray-400 w-[150px] text-[.8rem]  md:w-[250px] px-4 lg:px-8 font-[500]  py-3 rounded-lg text-white" >Cancel</button>
                  <button onClick={handleAddressSave} className="bg-[#2967B0] px-4 w-[150px] text-[.8rem]  md:w-[250px] lg:px-8 font-[500]  py-3 rounded-lg text-white" >Save and Proceed</button>
              </div>
            </div>) : (
                <div className="lg:mx-12 md:mx-0 mx-0 mt-3 md:mt-6">
                <div className="py-4 border-gray-200 border-b-[5px]" >
                  <h4 className="font-[700] text-black text-[1.8rem]" >Help us locate you</h4>
                  <p className="my-1 text-gray-600" >This will ensure accurate choice of services</p>
                  <div className="my-2" >
                    
                    <PlacesAutocomplete address={address} setAddress={setAddress} setLocationData={setLocationData} locationData={locationData}  />
                  </div>
                  <p onClick={()=>setActiveSection("add address")} className="text-blue-600 cursor-pointer flex items-center justify-start gap-4 my-4 font-[500]" ><MapIcon className=" w-[30px]"  /> Use current location using GPS</p>
                  </div>
                  <div className="my-4" >
                    <h4 className="text-gray-500 font-[500]" >Saved Address</h4>
                    <div>
                      {
                      addresses &&  addresses?.map((item)=>(
                        <div 
                        onClick={()=>{
                          setAddress({
                            ...address,
                            name:item.name,
                             location:item.briefAddress,
                            addressTwo:item.addressTwo,
                              landmark:item.landmark,
                          })
                          setActiveSection("add address")
                        }
                      
                        }
                          className="border-b-[1px] cursor-pointer py-3 border-gray-200" >
                          <h4 className="text-black font-[600] text-[1.1rem]" >{item.name}</h4>
                          <p>{item.briefAddress},{item.landmark}</p>
                        </div>
                      ))
                      }
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-4" >
                   <button onClick={()=>setActiveSection("add address")} className="bg-gray-400 w-[150px] text-[.8rem]  md:w-[250px] px-4 lg:px-8 font-[500]  py-3 rounded-lg text-white" >Cancel</button>
                    <button onClick={()=>{
                setActiveSection("add address")
                
                }} className="bg-[#2967B0] px-4 w-[150px] text-[.8rem]  md:w-[250px] lg:px-8 font-[500]  py-3 rounded-lg text-white" >Save and Proceed</button>
                  </div>
                </div>
            )}
            
              
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default AddressModal