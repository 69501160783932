import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";


import LoginLeft from "../Components/Login/LoginLeft";
import MobileLogin from "../Components/Login/MobileLogin";
import CrossIcon from "../asset/crossIcon.svg"
import EmailLogin from "../Components/Login/EmailLogin";
import SignUp from "../Components/Login/SignUp";
import PhoneAuth from "../Components/Login/PhoneAuth";
import {useDispatch,useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import { loginUsingPhone, signUpUsingGoogle } from "../actions/userAction";
import PhoneEmail from "../Components/Login/PhoneEmail";
const Login = ({open,handleOpen}) => {
   const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  const [active, setActive] = useState("email")
  const [activeLogin, setActiveLogin] = useState("login")
  const [phoneEmail, setPhoneEmail] = useState(false)
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
    const [email, setEmail] = useState("")
    const [fullName, setFullName] = useState("")
    const handlePhoneEmail= () =>{
    setPhoneEmail(!phoneEmail)
  
  }
  
  const navigate=useNavigate()
   const dispatch = useDispatch();
  const handleGoogleLogin = async () =>{
    
    dispatch(signUpUsingGoogle())
    handleOpen()
  }
   const handleLoginRegister = () =>{
    if(fullName.length === 0){
      alert("Please enter your name")
    }
    else if(email.length === 0) {
      alert("Please enter your email")
    
    }
    else{
      dispatch(loginUsingPhone(phone,otp,fullName,email))
    }
    
    if(!error){
        handlePhoneEmail()
    }
  }

  return (
    <>
    <PhoneEmail phoneEmail={phoneEmail} phone={phone} setPhone={setPhone} otp={otp} setOtp={setOtp} fullName={fullName} setFullName={setFullName} email={email} setEmail={setEmail}  handleLoginRegister={handleLoginRegister}  setPhoneEmail={setPhoneEmail} handlePhoneEmail={handlePhoneEmail} />
       <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
      
        <Card className="mx-auto relative lg:grid grid-cols-2 grid-flow-col w-full ">
         <div onClick={()=>handleOpen()} className="absolute cursor-pointer top-2 right-[10px] w-[40px] h-[40px] " >
         <img src={CrossIcon} alt="" />
        </div>
         <LoginLeft />
       { activeLogin === "login" ?  (<div>
        {active === "phone" ? 
         (<div>
          <PhoneAuth phone={phone} setPhone={setPhone} fullName={fullName} setFullName={setFullName} email={email} setEmail={setEmail} otp={otp} setOtp={setOtp} phoneEmail={phoneEmail} setPhoneEmail={setPhoneEmail} handlePhoneEmail={handlePhoneEmail}  handleGoogleLogin={handleGoogleLogin} handleOpen={handleOpen} active={active} setActive={setActive} activeLogin={activeLogin} setActiveLogin={setActiveLogin} />
         </div>)
   : (
    <div>
        <EmailLogin handleGoogleLogin={handleGoogleLogin} activeLogin={activeLogin} setActiveLogin={setActiveLogin} active={active} setActive={setActive} handleOpen={handleOpen} />
    </div>
   ) }
         </div>) : (
          <div>
            <SignUp handleGoogleLogin={handleGoogleLogin} handleOpen={handleOpen} activeLogin={activeLogin} setActiveLogin={setActiveLogin} />
          </div>
         ) }
        </Card>
      </Dialog>
      <div id="recaptcha-container"></div>
    </>
  );
};

export default Login;
