import React from 'react'
import Story1 from "../../asset/story1.png"
import Story2 from "../../asset/story2.png"
import Story3 from "../../asset/story3.png"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import QuoteMark from "../../asset/QuoteMark.png"
const Stories = ({stories}) => {
  const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.02
  }
};
  return (
    <div className='xl:px-[165px] relative lg:px-[60px] px-[40px] md:px-[30px] py-8 xl:py-[60px]' >
        <h4 className='lg:text-[48px] text-[#18191F]  text-[35px] font-[InterBlack] ' > Our success stories </h4>
        <p className='font-[InterRegular] text-[#18191F]' >See what people are saying about our unique and effective formulas.</p>
        <img  className='absolute w-[300px]  top-[5%] right-[7%]' src={QuoteMark} alt="" />
        <div className='mt-8' >
             <Carousel
  swipeable={true}
  draggable={true}
  showDots={false}
  responsive={responsive}
  ssr={true}
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
 
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
        {
          stories?.map((story)=>(
          
            <a target="_blank" rel='norefferer' href={story.url}>
            <div className='bg-white xl:w-[330px]  w-[92%]  flex items-start justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
             <img src={story.image} alt="" />
             <h4 className='text-[20px] mt-2 font-[InterMedium]' >{story.name}</h4>
             <p className='text-[14px] font-[InterRegular]' >{story.description.slice(0,80)}...</p>
            </div></a>
        
         
          ))
        }
            
        
            
        </Carousel>
        </div>
    </div>
  )
}

export default Stories