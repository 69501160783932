import React from 'react'
import ShieldIcon from "../../asset/shieldIcon.png"
import ShieldBannerIcon from "../../asset/shieldBannerIcon.png"
import Cashless from "../../asset/feature26.png"
import Damage from "../../asset/feature24.png"
import ServiceCenter from "../../asset/feature5.png"
import Claims from "../../asset/feature3.png"
import Pickup from "../../asset/feature2.png"
import Process from "../../asset/feature1.png"
import More from "../../asset/more.png"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
const ShieldBanner = () => {
  return (
    <div className='bg-[#CBEAF4] py-12 px-8 lg:px-36 ' >
        <div className='flex  items-center justify-between' >
            <div>
            <img className='w-[60px]' src={ShieldIcon} alt="" />
                <h4 className='text-black font-[500] md:text-[2rem] text-[1.6rem] lg:text-[2.3rem]' >
                 <span className='font-[700] text-[#DE562C]' >All-Round Protection</span>  <br />
                  for Your Beloved Devices </h4>
                  <p className='mt-2 text-[.8rem] lg:text-[1rem]' >Insurance with a Zip of Reliability and a Shield of Accessibility!</p>
                 
            </div>
            <div className='hidden md:block'  >
                <img src={ShieldBannerIcon} alt="" />
            </div>
        </div>
        <div className='my-12' >
             <Swiper
          spaceBetween={4}
          className=""
          draggable={true}
          breakpoints={{
         300: {
          width: 300,
          slidesPerView: 1.5,
        },
        370: {
          width: 370,
          slidesPerView: 2,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
       
         1440:{
          width:1440,
          slidesPerView:7
        }
      }}
          autoplay={{
            delay: 3000,
            disableOnInteraction:true,
          }}
          loop={true}
          modules={[Autoplay]}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide>
            <div className='lg:w-[130px] w-[120px] text-center flex items-center justify-center flex-col' >
            <img className='lg:w-[80px] w-[60px] ' src={Cashless} alt="shield banner " />
            <h4 className='mt-2 text-[.8rem] font-[500]' >Cashless Repair</h4>
            </div>
            </SwiperSlide>
             <SwiperSlide>
            <div className='lg:w-[130px] w-[120px] text-center flex items-center justify-center flex-col' >
            <img className='lg:w-[80px] w-[60px]' src={Damage} alt="shield banner " />
            <h4 className='mt-2 text-[.8rem] font-[500]' >Accidental Damage Coverage</h4>
            </div>
            </SwiperSlide>
               <SwiperSlide>
            <div className='lg:w-[130px] w-[120px] text-center flex items-center justify-center flex-col' >
            <img className='lg:w-[80px] w-[60px]' src={ServiceCenter} alt="shield banner " />
            <h4 className='mt-2 text-[.8rem] font-[500]' >Company Authorized Service Center</h4>
            </div>
            </SwiperSlide>
             <SwiperSlide>
            <div className='lg:w-[130px] w-[120px] text-center flex items-center justify-center flex-col' >
            <img className='lg:w-[80px] w-[60px]' src={Claims} alt="shield banner " />
            <h4 className='mt-2 text-[.8rem] font-[500]' >Unlimited Claims</h4>
            </div>
            </SwiperSlide>
              <SwiperSlide>
            <div className='lg:w-[130px] w-[120px] text-center flex items-center justify-center flex-col' >
            <img className='lg:w-[80px] w-[60px]' src={Pickup} alt="shield banner " />
            <h4 className='mt-2 text-[.8rem] font-[500]' >Hassle free pickup and drop</h4>
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='lg:w-[130px] w-[120px] text-center flex items-center justify-center flex-col' >
            <img className='lg:w-[80px] w-[60px]' src={Process} alt="shield banner " />
            <h4 className='mt-2 text-[.8rem] font-[500]' >100 % Digital Process</h4>
            </div>
            </SwiperSlide>
              <SwiperSlide>
            <div className='lg:w-[130px] w-[120px] text-center flex items-center justify-center flex-col' >
            <img className='lg:w-[80px] w-[60px]' src={More} alt="shield banner" />
            <h4 className='mt-2 text-[.8rem] font-[500]' >and Many More</h4>
            </div>
            </SwiperSlide>
        </Swiper>
        </div>
    </div>
  )
}

export default ShieldBanner