import React from 'react'
import MobileDevice from "../../asset/mobileDevice.png"
import PhoneOrder from "../../asset/phoneOrder.png"
import TabletOrder from "../../asset/tabletOrder.png"
import LaptopOrder from "../../asset/laptopOrder.png"
import {Link} from "react-router-dom"
const OrderList = ({activeTab,repairOrderList,shieldOrderList}) => {
    console.log(repairOrderList)
  return (
    <div className='lg:my-6 lg:mx-4' >
       { activeTab === "repair" ? (
        <>
        {repairOrderList.map((order)=>(
            <div>
            <div className='bg-white rounded-lg my-3 border-gray-300 border-[1px] px-6 py-4' >
                <div className='flex items-center border-b-[1px] border-gray-300 py-3 justify-between' >
                    <h4 className='text-[14px] text-[#0D1F37] font-[InterMedium]' > <span className='text-[#686868]' >Order No:</span>  #{order && order?.orderId} </h4>
                    <p className='text-[.8rem] text-[#2967B0] font-[InterMedium]'>Need Help?</p>
                    
                </div>
                <div className='flex border-b-[1px] border-gray-400 border-dashed  items-start py-4 justify-between' >
                    <div className='flex gap-6 items-start justify-start' >
                    <div>
                    {
                 order?.deviceData?.deviceType === "mobile_brands" &&    <img className='border-gray-300 w-[60px] lg:w-full border-[1px] px-4 py-4 rounded-lg' src={PhoneOrder} alt="" />
                    }
                     {
                 order?.deviceData?.deviceType === "tablet_brands" &&    <img className='border-gray-300  w-[60px] lg:w-full border-[1px] px-4 py-4 rounded-lg' src={TabletOrder} alt="" />
                    }
                     {
                 order?.deviceData?.deviceType === "laptop_brands" &&    <img className='border-gray-300 w-[60px] lg:w-full border-[1px] px-4 py-4 rounded-lg' src={LaptopOrder} alt="" />
                    }
                        
                          </div>
                          <div>
                                <h4 className='text-[#0D1F37] lg:my-2 text-[14px] lg:text-[16px] font-[InterBold]' >{order?.packData?.packName}</h4>
                                <p className='text-[#686868] text-[.7rem] font-[InterRegular] lg:text-[15px] capitalize ' >{order?.deviceData?.brandName}, {order?.deviceData?.modelName}</p>
                                <p className='text-gray-500 text-[0.9rem]' >{order?.deviceData?.deviceColor}</p>
                          </div>
                    </div>
                    <div>
                        <p className='font-[InterBold] text-[18px]  lg:text-[22px]' >₹{order?.packData?.PackCost}</p>
                    </div>
                </div>
                <div className='flex items-start justify-between py-3' >
                    <div>
                    <p className='text-[#686868] text-[14px] font-[InterMedium] my-2' >Status : <span className={order?.orderStatus==="Cancelled" ?  "text-[#ae3636]" : "text-green-400"}  >Order {order?.orderStatus}</span></p>
                       
                        <p className='text-[#686868] text-[14px] font-[InterMedium] my-2'>Booking Schedule: <span className='text-[#0D1F37] font-[InterMedium]' >{order?.pickUpData?.bookingTimeDate}</span></p>
                    </div>
                    <div>
                     <Link to={`/orderDetails/repairOrders/${order.orderId}`} > <button className='border-[#2967B0] text-[12px] lg:text-[14px] border-[1px] text-[#2967B0] px-3 lg:px-6 rounded-lg font-[InterMedium] py-2' >See Details</button></Link>  
                    </div>
                </div>
            </div>
              
              
        </div>
        )) }
        </>) : (
             <>
        {shieldOrderList.map((order)=>(
            <div>
            <div className='bg-white rounded-lg my-3 border-gray-300 border-[1px] px-6 py-4' >
                <div className='flex items-center border-b-[1px] border-gray-300 py-3 justify-between' >
                    <h4 className='text-[.9rem]' >Order No: #{order && order?.orderId} </h4>
                    <p className='text-[.8rem] text-[#2967B0] '>Need Help?</p>
                    
                </div>
                <div className='flex border-b-[1px] border-gray-400 border-dashed  items-start py-8 justify-between' >
                    <div className='flex gap-6 items-start justify-start' >
                    <div>
                    {
                 order?.deviceData?.deviceType === "Mobiles" &&    <img className='border-gray-300 w-[60px] lg:w-full border-[1px] px-4 py-4 rounded-lg' src={PhoneOrder} alt="" />
                    }
                     {
                 order?.deviceData?.deviceType === "Tablets" &&    <img className='border-gray-300  w-[60px] lg:w-full border-[1px] px-4 py-4 rounded-lg' src={TabletOrder} alt="" />
                    }
                     {
                 order?.deviceData?.deviceType === "Laptops" &&    <img className='border-gray-300 w-[60px] lg:w-full border-[1px] px-4 py-4 rounded-lg' src={LaptopOrder} alt="" />
                    }
                        
                          </div>
                          <div>
                                <h4 className='text-black lg:my-2 text-[1.1rem] lg:text-[1.3rem] font-[600]' >{order?.packData?.packName}</h4>
                                <p className='text-gray-500 text-[.7rem] lg:text-[0.9rem] capitalize ' >{order?.deviceData?.brandName}, {order?.deviceData?.modelName}</p>
                                <p className='text-gray-500 text-[0.9rem]' >{order?.deviceData?.deviceColor}</p>
                          </div>
                    </div>
                    <div>
                        <p className='font-[600] text-[1.1rem] lg:text-[1.5rem]' >₹{order?.packData?.PackCost}</p>
                    </div>
                </div>
                <div className='flex items-start justify-between py-3' >
                    <div>
                        <p className='text-gray-500 text-[.9rem] my-2' >Status : <span className={order?.orderStatus==="Cancelled" ?  "text-[#ae3636]" : "text-green-400"}  >Order {order?.orderStatus}</span></p>
                        <p className='text-gray-500 text-[.9rem] my-2'>Booking Schedule: <span className='text-black' >{order?.pickUpData?.bookingTimeDate}</span></p>
                    </div>
                    <div>
                       <Link to={`/orderDetails/shieldOrders/${order.orderId}`} > <button className='border-[#2967B0] text-[.7rem] lg:text-[1rem] border-[1px] text-[#2967B0] px-3 lg:px-6 rounded-lg font-[500] py-2' >See Details</button></Link>
                    </div>
                </div>
            </div>
              
              
        </div>
        )) }
        </>
        )
        }
    </div>
  )
}

export default OrderList