import React from 'react'
import DiscountIcon from "../../asset/discount.png"
import CrossIcon from "../../asset/crossIcon.svg"
const PlaceCartRight = ({setPaymentMethod,deviceInfo,coupanCode,handleCoupanModal,setCoupanCode,totalServiceCost}) => {
  return (
    <div className='col-span-1 py-12 px-8' >
    <div className='pb-4 border-gray-100 border-b-[5px]' >
        <h4 className='flex items-center text-[20px] text-[#0D1F37] font-[InterBold] justify-start gap-4' ><img alt='icon' src={DiscountIcon} /> Apply Discount Coupon</h4>
        <div>
            <div className='border-[1px] my-4 rounded-lg border-gray-200 py-3 px-6 flex items-center justify-between' >
                <div>
                   <h4 onClick={handleCoupanModal} className='text-blue-400 font-[InterMedium] cursor-pointer ' >{coupanCode?.title ? coupanCode?.title : 'Select a Coupon Code' }</h4> 
                </div>
                <div className='w-[30px] h-[30px]   ' >
                    <img src={CrossIcon} onClick={()=>setCoupanCode({})} className=' cursor-pointer ' />
                </div>
            </div>
        </div>
    </div>
        <div className='py-4' >
                <h4 className='font-[InterBold] text-[20px]  text-[#0D1F37]' >Payment Summary</h4>
                <div className='mt-3 border-dashed border-gray-300 border-b-[1px]' >
                <div className='flex  items-center justify-between py-2' >
                    <h4 className='text-[#4D4D4D] font-[InterRegular]' >Item Total</h4>
                    <p className='text-[#4D4D4D] font-[InterRegular]'>₹{totalServiceCost}</p>
                </div>
                <div className='flex items-center justify-between py-2' >
                    <h4 className='text-[#4D4D4D] font-[InterRegular]'>Platform Fee</h4>
                    <p className='text-green-200 font-[InterRegular]'>-₹0</p>
                </div>
                <div className='flex items-center justify-between py-2' >
                    <h4 className='text-[#4D4D4D] font-[InterRegular]'>GST</h4>
                    <p className='text-green-200 font-[InterRegular]' >-₹0</p>
                </div>
               {coupanCode?.coupanValue && <div className='flex items-center justify-between py-2' >
                    <h4 className='text-[#4D4D4D] font-[InterRegular]'>Coupan Code</h4>
                    <p className='text-green-200 font-[InterRegular]' >-₹{coupanCode?.coupanValue}</p>
                </div>}
                </div>
                <div className='flex items-center justify-between py-4' >
                    <h4 className='text-[#4D4D4D] font-[InterRegular]' >To Pay</h4>
                    <p className='font-[InterBold] text-[#0D1F37] text-[18px] ' >Total  ₹{coupanCode?.coupanValue ? Number(totalServiceCost)-Number(coupanCode?.coupanValue) : totalServiceCost}</p>
                </div>
        </div>
    </div>
  )
}

export default PlaceCartRight