import React,{useState,useEffect} from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import StoriesSection from '../Components/Others/StoriesSection'
import { onValue, ref } from 'firebase/database'
import { database } from '../firebase.config'
import BrandList from '../Components/About/BrandList'
import SuccessVideo from "../asset/sucessVideo.mp4"
const SuccessStories = () => {
  const [stories, setStories] = useState([])
  const fetchAllStories = async () =>{
      const repairCountRef =  await ref(database, 'successStories/');

     onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setStories(newProducts)
       })
  }
  useEffect(() => {
    fetchAllStories()
  }, [])
  return (
    <div>
        <Navbar />
        <div className='mt-[80px]' >
        
        <BrandList />
        <video className='w-full'  loop="true" autoplay="autoplay" muted>
    <source src={SuccessVideo}  type="video/mp4" />
      </video>
          <StoriesSection stories={stories} />
            <Footer bg="#0D1F37" />
        </div>
    </div>
  )
}

export default SuccessStories