import React from 'react'
import Email from "../../asset/email.png"
import Google from "../../asset/google.png"
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import MobileNumberInput from './MobileNumberInput';
import { Link } from 'react-router-dom';

const MobileLogin = ({handleOpen,showOTP,setShowOTP,countryCallingCode,active,setActive,activeLogin,setActiveLogin,flags,name,countries,phone,setPhone,phoneError,setPhoneError,phoneErrorMessage,setPhoneErrorMessage,country,setCountry,onSignup,handleGoogleLogin,otpLoading}) => {

  return (
    <div className="col-span-1">
           <CardBody className="flex flex-col gap-4">
             <Typography className='font-[InterBold]' variant="h4" color="blue-gray">
             Welcome to <span className="text-[#2967B0] italic " >Zepair</span> 
            </Typography>
            <Typography
              className="mb-2 font-[InterMedium] text-[#0D1F37] "
              variant="paragraph"
              color="gray"
            >
              Login or  <span className="font-[600]" > Get Started</span>
            </Typography>
            <h3 className="-mb-2 font-[InterMedium]" >
              Enter your Phone Number
            </h3>
            <MobileNumberInput country={country} setCountry={setCountry} phoneError={phoneError} phoneErrorMessage={phoneErrorMessage} name={name} flags={flags} countryCallingCode={countryCallingCode} countries={countries}  phone={phone} setPhone={setPhone} />
          <p className='font-[InterMedium] text-red-600' >{phoneErrorMessage}</p>
            
             <button  onClick={onSignup}  className="bg-[#2967B0] font-[InterBold] text-white py-3 rounded-lg " >
            {!otpLoading ?   "Send OTP" : "Sending OTP..."}
            </button>
            <Typography variant="small" className="mt-4 font-[InterRegular] flex justify-center">
              Don&apos;t have an account?
              <Typography
                as="p"
             
                variant="small"
                color="blue-gray"
                className="ml-1 font-[InterBold] cursor-pointer "
                onClick={()=>setActiveLogin("signup")}
              >
                Sign up
              </Typography>
            </Typography>
            
          </CardBody>
          <CardFooter className="pt-0 border-t-[1px] border-gray-300 mx-6  ">
            <div className="flex mt-3 gap-4 items-center justify-start" >
            <div onClick={()=>setActive("email")} className="px-2 py-2 w-[50px] h-[50px] flex items-center justify-center cursor-pointer   border-[1px] border-gray-300 rounded-full">
            <img   src={Email} alt="" />
            </div>
            <div onClick={handleGoogleLogin}  className="px-2 py-2 w-[50px] h-[50px] flex items-center justify-center cursor-pointer   border-[1px] border-gray-300 rounded-full">
            <img  src={Google} alt="" />
            </div>
            </div>
            <p className="mt-3 text-[.8rem] font-[InterRegular] " >By continuing, you agree to Zepair’s <Link to="/termsandcondition" className='underline' > Terms of Use.</Link> Read our <Link className='underline' to="/privacy-policy" > Privacy Policy </Link></p>
          </CardFooter>
        </div>
  )
}

export default MobileLogin