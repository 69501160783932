import React,{useState} from 'react'
import OrderTab from './OrderTab'
import OrderList from './OrderList'

const MyOrders = ({repairOrderList,shieldOrderList}) => {
  const [activeTab, setActiveTab] = useState("repair")
  return (
    <div>
        <OrderTab activeTab={activeTab} setActiveTab={setActiveTab} />
        <OrderList activeTab={activeTab} setActiveTab={setActiveTab} repairOrderList={repairOrderList} shieldOrderList={shieldOrderList} />
    </div>
  )
}

export default MyOrders