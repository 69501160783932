import React from "react";
import FeatureOne from "../../asset/feature31.png"
import FeatureTwo from "../../asset/feature24.png"
import FeatureThree from "../../asset/feature3.png"
import Featurefour from "../../asset/feature2.png"
import Featurefive from "../../asset/feature1.png"
import FeatureSix from "../../asset/feature25.png"
import FeatureSeven from "../../asset/feature26.png"
import FeatureEight from "../../asset/more.png"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
const RepairBannerOne = () => {
  return (
    <div
      className=" xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:py-[30px] bg-gradient-to-r from-[#2968b060] to-[#f6eb155f]"
    >
    <h4 className="text-[#0D1F37] font-[InterBold] text-[1.5rem] lg:text-[35px]" >Our Skilled Technicians to Your Rescue</h4>
      
      <div className=" lg:gap-6 mt-12 " >
      <Swiper
          spaceBetween={8}
          className=""
          draggable={true}
          breakpoints={{
         300: {
          width: 300,
          slidesPerView: 1.5,
        },
        370: {
          width: 370,
          slidesPerView: 2,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
       
         1440:{
          width:1440,
          slidesPerView:7
        }
      }}
          autoplay={{
            delay: 3000,
            disableOnInteraction:true,
          }}
          loop={true}
          modules={[Autoplay]}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={FeatureOne} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >Doorstep Repair by Fully Equipped Zippy</p>
        </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={FeatureTwo} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >No Parerwork Required</p>
        </div>
          </SwiperSlide>
           <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={FeatureThree} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >Repair at Zippy Authorized Service Center</p>
        </div>
          </SwiperSlide>
           <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={Featurefour} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >All Round Support</p>
        </div>
          </SwiperSlide>
           <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={Featurefive} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >Hassle free pickup and drop</p>
        </div>
          </SwiperSlide>
           <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={FeatureSix} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >100 % Digital Process</p>
        </div>
          </SwiperSlide>
            <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={FeatureSeven} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >Cashless Repair</p>
        </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="lg:w-[180px] w-[150px] flex items-center justify-center flex-col " >
          <img src={FeatureEight} className="w-[70px]" alt="" />
          <p className="text-[14px] mt-2 text-center font-[InterMedium]" >And More</p>
        </div>
          </SwiperSlide>
        </Swiper>
        
        
      </div>
    </div>
  );
};

export default RepairBannerOne;
