import React from 'react'
import RepairIcon from "../../asset/repair.png"
import ShieldIcon from "../../asset/shield.png"
const OrderInfo = ({orderDetails,type}) => {
  return (
    <div className='lg:px-24 px-3 pb-8 pt-16' >
            <h4 className='font-[InterBold] text-[#0D1F37] text-[20px]' >Order Details</h4>
            <div className='border-[1px] w-full mt-6 mb-0 border-gray-300 rounded-lg py-6 px-4  lg:px-12' >
            <div className='border-b-[1px] border-gray-300 py-2' >
            {type==="repairOrders" ? <img className='w-[90px]' src={RepairIcon} alt={type} />
             : <img className='w-[90px]' src={ShieldIcon} alt={type} />}
             <div className='flex items-center mt-4 justify-between' >
             <h4 className='text-[16px] text-[#0D1F37] font-[InterMedium]' > <span className='text-[#686868]' >Order No:</span>  #{orderDetails?.orderId} </h4>
           
             
             </div>
             </div>
             <div className='py-4' >
                    <h4 className='py-2 text-[#0D1F37] font-[InterBold] text-[1.2rem]' >Address:</h4>
                    <div className='py-3' >
                        <p className=' lg:text-[14px] text-[#4A4A4A] font-[InterMedium] text-[12px]' >Name: <span className='font-[InterRegular]   ' > {orderDetails?.personalData?.fullName}</span></p>
                        <p className=' lg:text-[14px] text-[#4A4A4A] font-[InterMedium] text-[12px]'>Location: <span className='font-[InterRegular]  ' > {orderDetails?.pickUpData?.addLine1}, {orderDetails?.pickUpData?.addLine2}, {orderDetails?.pickUpData?.addLine3}</span></p>
                        <p className=' lg:text-[14px] text-[#4A4A4A] font-[InterMedium] text-[12px]'>Booking Date: <span className='font-[InterRegular] ' > {orderDetails?.pickUpData?.bookingTimeDate}</span></p>
                         <p className=' lg:text-[14px] text-[#4A4A4A] font-[InterMedium] text-[12px]'>Contact No: <span className='font-[InterRegular] ' > {orderDetails?.personalData?.contactNo}</span></p>
                    </div>
             </div>
            </div>
    </div>
  )
}

export default OrderInfo