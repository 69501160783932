import React from 'react'
import Service from "../../asset/ChooseService.png"
import Device from "../../asset/device.png"
import FillForm from "../../asset/fillform.png"
import Pay from "../../asset/pay.png"
import useWindowDimensions from "../Layout/useWindowDimension"
import RaiseClaim from "../../asset/homeasset2.png"
import Pickup from "../../asset/homeasset.png"
import FormImage from "../../asset/laptopfix.png"
import PayImage from "../../asset/scooty.png"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import StepsImage from "../../asset/steps.svg"
import StepsMobileImage from "../../asset/stepMobile.svg"
const StepsToRepair = () => {
   const {width,height} = useWindowDimensions()
  return (
    <div className='  z-[-1]  xl:mt-[20px]  md:mt-[30px] '>
{/*     
       {width > 850 ?  <div className='flex items-start flex-col lg:flex-row justify-center gap-8 py-12' >
         <div className=' w-[400px]  mx-4 border-[1px] flex items-center justify-center  flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={RaiseClaim} className='w-[150px] ' alt="" />
                <h4 className='text-center mt-6 font-[InterBlack] text-[15px]' >Notify us by raising the claim</h4>
                <p className='text-[#686868] mt-[12px] font-[InterRegular] text-[12px] text-center' >Register your claim on our App or on our Website</p>
                
            </div>
              <div className='w-[400px] mx-4 border-[1px] flex items-center justify-center flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={Pickup} className='w-[150px] ' alt="" />
                <h4 className='text-center mt-6 font-[InterBlack] text-[15px]' >We pickup the device</h4>
                <p className='text-[#686868] mt-[12px] font-[InterRegular] text-[12px] text-center' >Sit back and relax as we pick up the damaged device from your doorstep.e</p>
            </div>
              <div className='w-[400px]  mx-4 border-[1px] flex items-center justify-center flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={FormImage} className='w-[150px] ' alt="" />
                <h4 className='text-center mt-6 font-[InterBlack] text-[15px]' >Device undergoes repair</h4>
                <p className='text-[#686868] mt-[12px] font-[InterRegular] text-[12px] text-center' >You receive the repair estimate & pay a small claim processing fee (if any).</p>
            </div>
              <div className='w-[400px]  mx-4 border-[1px] flex items-center justify-center flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={PayImage} className='w-[150px] ' alt="" />
                <h4 className='text-center mt-6 font-[InterBlack] text-[15px]' >Device delivered back to you </h4>
                <p className='text-[#686868] mt-[12px] font-[InterRegular] text-[12px] text-center' >Within the promised timeline, get free delivery of your repaired device at your doorstep.</p>
            </div>
            
        </div> : 
        <div className='flex items-center justify-center' > 
           <Swiper
          spaceBetween={0}
          className="py-[20px] my-12"
          draggable={true}
          breakpoints={{
        450: {
          width: 450,
          slidesPerView: 1,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
       
         1440:{
          width:1440,
          slidesPerView:3.5
        }
      }}
          autoplay={{
            delay: 1500,
            disableOnInteraction:false,
          }}
          loop={false}
          modules={[Autoplay]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
        <SwiperSlide>
           <div className=' w-[94%] md:w-[350px] mx-3  border-[1px] flex items-center justify-center flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={RaiseClaim} className='w-[150px] ' alt="" />
                <h4 className='font-[600] text-center mt-6 text-[18px]' >Notify us by raising the claim</h4>
                <p className='text-gray-500 text-center' >Register your claim on our App or on our Website</p>
                
            </div>
        </SwiperSlide>
        <SwiperSlide>
             <div className=' w-[94%] md:w-[350px] mx-3  border-[1px] flex items-center justify-center flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={Pickup} className='w-[150px] ' alt="" />
                <h4 className='font-[600] text-center mt-6 text-[18px]' >We pickup the device</h4>
                <p className='text-gray-500 text-center' >Sit back and relax as we pick up the damaged device from your doorstep.e</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='w-[94%] md:w-[350px] mx-3  border-[1px] flex items-center justify-center flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={FormImage} className='w-[150px] ' alt="" />
                <h4 className='font-[600] text-center mt-6 text-[18px]' >Device undergoes repair</h4>
                <p className='text-gray-500 text-center' >You receive the repair estimate & pay a small claim processing fee (if any).</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
           <div className=' w-[94%] md:w-[350px] mx-3  border-[1px] flex items-center justify-center flex-col rounded-[16px] px-3 py-2 border-gray-200 ' >
                <img src={PayImage} className='w-[150px] ' alt="" />
                <h4 className='font-[600] text-center mt-6 text-[18px]' >Device delivered back to you </h4>
                <p className='text-gray-500 text-center' >Within the promised timeline, get free delivery of your repaired device at your doorstep.</p>
            </div>
        </SwiperSlide>
        </Swiper>
        </div>
         } */}
         <img className='w-full md:block hidden' src={StepsImage} alt="" />
         <img className='w-full block md:hidden' src={StepsMobileImage} alt="" />
    </div>
  )
}

export default StepsToRepair