import { Button } from '@material-tailwind/react';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ShieldSuccess = () => {
    const { shieldOrder } = useSelector(
    (state) => state.shieldOrders
  );
  return (
    <div className='flex items-center justify-center mt-[20%] ' >
        <div className='flex items-center justify-center flex-col' >
            <h4 className='font-[600] text-[2rem]' >Yay! Order Received</h4>
            <p className='font-[400] my-2 text-[1.2rem] text-center' >Your Order for <br/> {shieldOrder?.packData?.packName} is placed.</p>
            <div className='flex items-center justify-center mt-6 gap-6' >
       { shieldOrder?.orderId &&  <Link className='border-[#2967B0] border-[2px] py-3 px-8 rounded-lg text-[#2967B0] font-[500]' to={`/orderDetails/shieldOrders/${shieldOrder?.orderId}`} > Checkout Order</Link>}
       <Link className='bg-[#2967B0] border-[#2967B0] border-[2px] py-3 px-12 rounded-lg text-white font-[500]' to="/">Go to Home</Link>
       </div>
        </div>
    </div>
  )
}

export default ShieldSuccess