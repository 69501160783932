import React from 'react'
import Story1 from "../../asset/story1.png"
import Story2 from "../../asset/story2.png"
import Story3 from "../../asset/story3.png"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
const StoriesSection = ({stories}) => {
  return (
    <div className='2xl:px-[120px] font-[Inter] lg:px-12 px-6 py-12 lg:py-[60px]' >
        <h4 className='lg:text-[48px] text-[24px] text-[#18191F] mt-2 font-[InterBlack] ' > Our success stories </h4>
        <p className='text-[#18191F] text-[18px] mt-2 font-[InterRegular]' >See what people are saying about our unique and effective formulas.</p>
        <div className='lg:mt-8 mt-2 py-3 lg:py-12  flex items-start justify-start gap-4  lg:gap-12 flex-wrap ' >
        {
          stories?.map((story)=>(
         <a target="_blank" rel='norefferer' href={story.url}>
            <div className='bg-white xl:w-[380px]  w-[92%]  flex items-start justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
             <img className='w-full' src={story.image} alt="" />
             <h4 className='text-[#18191F] mt-2 text-[24px] font-[InterMedium]' >{story.name}</h4>
             <p className='text-[14px] font-[InterRegular] '>{story.description.slice(0,80)}</p>
            </div>
            </a>
          ))
        }
      {/* 
            <div className='bg-white xl:w-[330px] h-[280px] xl:h-[280px] w-[92%]  flex items-start justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
             <img src={Story2} alt="" />
             <h4 className='text-[1.7rem] mt-2 font-[700]' >Lorem ipsum</h4>
             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cupiditate.</p>
            </div>
            <div className='bg-white xl:w-[330px] h-[280px] xl:h-[280px] w-[92%]  flex items-start justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
             <img src={Story3} alt="" />
             <h4 className='text-[1.7rem] mt-2 font-[700]' >Lorem ipsum</h4>
             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cupiditate.</p>
            </div>
        
            <div className='bg-white xl:w-[330px] h-[280px] xl:h-[280px] w-[92%]  flex items-start justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
             <img src={Story1} alt="" />
             <h4 className='text-[1.7rem] mt-2 font-[700]' >Lorem ipsum</h4>
             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cupiditate.</p>
            </div>
           <div className='bg-white xl:w-[330px] h-[280px] xl:h-[280px] w-[92%]  flex items-start justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
             <img src={Story2} alt="" />
             <h4 className='text-[1.7rem] mt-2 font-[700]' >Lorem ipsum</h4>
             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cupiditate.</p>
            </div>
             <div className='bg-white xl:w-[330px] h-[280px] xl:h-[280px] w-[92%]  flex items-start justify-center flex-col rounded-[16px] py-4 px-3 xl:px-3 xl:py-2  ' >
             <img src={Story1} alt="" />
             <h4 className='text-[1.7rem] mt-2 font-[700]' >Lorem ipsum</h4>
             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, cupiditate.</p>
            </div> */}
     
        </div>
    </div>
  )
}

export default StoriesSection