import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux'
import { collection, query, where, getDoc,doc } from "firebase/firestore";
import { db } from '../firebase.config';
import Navbar from '../Components/Layout/Navbar'
import SingleRepairDetails from '../Components/Profile/SingleRepairDetails'
import { useParams } from 'react-router-dom';
import { ref, onValue, get,set,push,update} from "firebase/database";
import {database} from "../firebase.config"
import Footer from '../Components/Layout/Footer';
const SingleRepairOrder = () => {
    const {id} = useParams()
     const [repairOrderList, setRepairOrderList] = useState({})

   const { error, loading, isAuthenticated,user, } = useSelector(
    (state) => state.user
  );
   const { userProfile } = useSelector(
    (state) => state.userProfile
  );
    console.log(userProfile.uid)
  useEffect(() => {
    const fetchOrders = async () =>{
     const docRef = doc(db, "repairOrders", id);
const docSnap = await getDoc(docRef);
      
if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  setRepairOrderList(docSnap.data())
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}
    }
    fetchOrders()
  }, [id,user.uid]) 
  console.log(repairOrderList)
  
  return (
    <div>
        <Navbar />
        <SingleRepairDetails id={id} repairOrderList={repairOrderList} />
        <Footer  bg="#0D1F37" />
    </div>
  )
}

export default SingleRepairOrder