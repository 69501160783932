import { ArrowLeftIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Input } from '@material-tailwind/react'
import React,{useState} from 'react'
import NewModel from './NewModel'

const SelectModel = ({model,setModel,manufacturer,openModal,setOpenModal,stage,setStage,filteredModelList,setFilteredModelList,filterBySearch}) => {
  const [newModel, setNewModel] = useState(false)
  const handleNewModel = () =>{
    setNewModel(!newModel)
  }
  
  return (
    <>
    <NewModel stage={stage} openModal={openModal} setOpenModal={setOpenModal} setStage={setStage} newModel={newModel} model={model} setModel={setModel} handleNewModel={handleNewModel} setNewModel={setNewModel} />
    <div className='xl:px-[165px]  lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:py-[30px]'>

        <div className='flex items-start flex-col-reverse md:flex-row gap-6  justify-between' >
     <div className='flex items-center gap-4 flex-col  justify-start' >
     <div>
    <h4 className='font-[InterBold] text-[24px]'>Select a Model:  </h4>
    <p className='text-gray-600' > Can’t find ,  <span onClick={()=>setNewModel(!newModel)} className='text-blue-400 cursor-pointer' > Enter your Model </span></p>
    <div className='mt-2 relative '>
    <Input className='border[#C8C8C8] placeholder:font-[InterMedium] font-[InterMedium] md:w-[300px] lg:w-[543px] ' onChange={filterBySearch} label='Search Model'   />
    <MagnifyingGlassIcon className='absolute w-[20px] top-[8px] right-[8px] text-[#686868]' />
    </div>
    </div>
    
    </div>
    
    </div>
         <div className='flex items-center lg:h-[350px] lg:overflow-y-scroll flex-wrap gap-0 justify-start mt-3  lg:mt-12 ' >
         {
            filteredModelList && filteredModelList.map((model)=>(
                 <div onClick={()=>{
                    setModel(model)
                    setOpenModal(true)
                    }} className='xl:w-[320px] lg:w-[250px] md:w-[200px] text-start w-[95%] px-2 capitalize cursor-pointer rounded-lg py-3 font-[InterRegular] hover:bg-[#59a4ff11] ' >
                <h4 className='md:text-[1rem] text-[.8rem] text-[#4D4D4D] font-[600]' >{model}</h4>
            </div>
            ))
         }
           
          

         </div>
    </div>
    </>
  )
}

export default SelectModel