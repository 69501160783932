import React,{useState,useEffect} from 'react'
import Navbar from '../../Components/Layout/Navbar'
import Footer from '../../Components/Layout/Footer'
import CartLeft from '../../Components/Cart/CartLeft'
import CartRight from '../../Components/Cart/CartRight'
import CartModel from '../../Components/Cart/CartModel'
import { useDispatch, useSelector } from 'react-redux'
import { saveOrder, saveRepairDevice } from '../../actions/repairActions'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../firebase.config'
import { collection, query, where, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";

import PlaceCartRight from './PlaceCartRight'
import PlaceCartLeft from './PlaceCartLeft'
import CoupanModal from './CoupanModal'
import useRazorpay from 'react-razorpay'
import toast, { Toaster } from 'react-hot-toast';
const OrderCart = () => {
    const [openModal, setOpenModal] = useState(false)
    const navigate = useNavigate()
     const {  isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  const [Razorpay] = useRazorpay();
   const {id,type} = useParams()
  const {error, loading  } = useSelector(
    (state)=>state.repairOrders
  )
  const dispatch = useDispatch();
  const [repairOrderList, setRepairOrderList] = useState([])
  const [paymentMethod, setPaymentMethod] = useState("")
  const [deviceInfo, setDeviceInfo] = useState({})
  const [orderId, setOrderId] = useState("")
  const [coupanCode, setCoupanCode] = useState({})
  const [coupanList, setCoupanList] = useState([])
  const [coupanModal, setCoupanModal] = useState(false)
  const [totalServiceCost, setTotalServiceCost] = useState(0)
  const handleCODOrder = async () =>{
   
    if(deviceInfo){
        const orderRef = doc(db,type,id)
      await updateDoc(orderRef,{
        orderStatus:"Placed",
        estimateCostStatus:"Accepted",
        estimateAmoutPaid:coupanCode?.coupanValue ? Number(totalServiceCost)-Number(coupanCode?.coupanValue) : totalServiceCost,
        estimateCost:'Unpaid',
        estimatePaymentID:""
      }).then(()=>{
        navigate(`/orderDetails/${type}/${id}`)
      })
 
    }

  

  }
    const fetchRepairOrder =  async () =>{
    const docRef = doc(db, "repairOrders", id);
const docSnap = await getDoc(docRef);
      
if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  setDeviceInfo(docSnap.data())
  let totalPrice = 0
        docSnap.data()?.ServiceData?.map((service)=>(
          totalPrice = totalPrice+Number(service.servicePrice)
        ))
       setTotalServiceCost(totalPrice)
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}
    }
    const handlePayment = async (params) => {
  

  const options = {
    key: process.env.REACT_APP_RAZORPAY_API_KEY,
    amount: (coupanCode?.coupanValue ? Number(totalServiceCost)-Number(coupanCode?.coupanValue) : totalServiceCost)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "Zepair",
    description: "Test Transaction",
    

    
    handler: function (response) {
   
        if(deviceInfo){
        const orderRef = doc(db,type,id)
       updateDoc(orderRef,{
        orderStatus:"Placed",
        estimateCostStatus:"Accepted",
        estimateAmoutPaid:coupanCode?.coupanValue ? Number(totalServiceCost)-Number(coupanCode?.coupanValue) : totalServiceCost,
        estimateCost:'Paid',
        estimatePaymentID:response.razorpay_payment_id
      }).then(()=>{
        navigate(`/orderDetails/${type}/${id}`)
      })
 
    }
      
    },
    prefill: {
      name: deviceInfo?.personalData?.fullName,
      email: deviceInfo?.personalData?.emailId,
      contact: deviceInfo?.personalData?.contactNo,
    },
    notes: {
      address: "Unit No 30, VTM 1, Mehra Industrial Compound, Opp Telephone Exchange, Andheri Kurla Road, Saki Naka, Mumbai 400072",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzp1 = new Razorpay(options);

  rzp1.on("payment.failed", function (response) {
    toast.error("Payment Cancelled" ,{
      position:"top-center"
    })
  });

  rzp1.open();
};
    const fetchCoupans = async () =>{
  const querySnapshot = await getDocs(collection(db, "coupans"));
    querySnapshot.forEach((doc) => {
     setCoupanList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
    });
    }
    console.log()
    const handleOpenModal = () =>{
        setOpenModal(!openModal)
    }
    const handleCoupanModal = () =>{
      setCoupanModal(!coupanModal)
    }
    useEffect(() => {
      fetchRepairOrder()
      fetchCoupans()
      sessionStorage.setItem("activePage", "Cart");
    }, [])
  return (
    <div>
        <Navbar />
        <Toaster />
        <CoupanModal totalServiceCost={totalServiceCost} deviceInfo={deviceInfo} handleCoupanModal={handleCoupanModal} coupanCode={coupanCode} setCoupanCode={setCoupanCode} coupanModal={coupanModal} coupanList={coupanList} />
        <CartModel openModal={openModal} handleOpenModal={handleOpenModal}  />
        <div className='mt-[80px] font-[Inter]'>
                <div className='lg:grid grid-cols-3 gap-6 grid-flow-col xl:px-[160px] lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:py-[30px]' >
                    <PlaceCartLeft handlePayment={handlePayment}   totalServiceCost={totalServiceCost} paymentMethod={paymentMethod} deviceInfo={deviceInfo} setPaymentMethod={setPaymentMethod} handleOrder={handleCODOrder} handleOpenModal={handleOpenModal} />
                    <PlaceCartRight handleCoupanModal={handleCoupanModal} coupanCode={coupanCode} setCoupanCode={setCoupanCode} deviceInfo={deviceInfo} totalServiceCost={totalServiceCost}  />
                </div>
             <Footer bg="#0D1F37"/>
        </div>
   
    </div>
  )
}

export default OrderCart