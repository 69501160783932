import React from 'react'
import TabletOrder from "../../asset/tabletOrder.png"
import MobileOrder from "../../asset/phoneOrder.png"
import LaptopOrder from "../../asset/laptopOrder.png"
import WarningIcon from "../../asset/warningIcon.png"
import OrderSteps from './OrderSteps'
import { Link } from 'react-router-dom'
import ConfirmDialog from '../Profile/CancelOrderModal'
import { useState } from 'react'
const OrderStatus = ({orderDetails,type,handleCancelOrder,handleOpenModal,totalEstimatedValue,handleApproveOrder,approved,serviceData,orderId}) => {
  console.log(serviceData)
  const [confirmModal, setConfirmModal] = useState(false)
  const handleConfirmModal = () => {
    setConfirmModal(!confirmModal)
  }
  const handleOrderStatus = (currentStatus) =>{
     switch (currentStatus) {
      case 'Pending':
        return 'Order Placed';
      case 'Placed':
        return 'Order Confirmed';
      case 'Agent Assigned':
        return 'Agent Assigned';
      case 'Pickup':
        return 'Agent Out for Pickup';
      case 'Pickedup':
        return 'Order Picked Up';
      case 'Service':
        return 'Order In service';
      case 'Out for Delivery':
        return 'Out for delivery';
      case 'Completed':
        return 'Order Complete';
        case 'Cancelled':
        return 'Order Cancelled';
    // Add more cases for other statuses if needed
    // case 'anotherStatus':
    //   return 'Another Status Text';
      default:
        return 'Order Placed';
    }
  }
  console.log(orderDetails?.recordedProblems)
  return (
    <div className='lg:px-24 px-3 py-4' >
    <ConfirmDialog confirmModal={confirmModal} handleConfirmModal={handleConfirmModal} handleCancelOrder={handleCancelOrder} orderId={orderId} />
        <div className='border-[1px] my-0 border-gray-300 rounded-lg py-6 px-4  lg:px-4 ' >
        <div className='border-b-[1px] flex-col md:flex-row gap-6 flex items-center justify-between py-4 border-gray-400 border-dashed' >
            <div className='flex items-center gap-6   justify-start' >
               {type==="repairOrders" && <div>
                 {orderDetails?.deviceData?.deviceType === "mobile_brands" && <img className='py-2 px-3 rounded-lg border-gray-300 border-[1px]' src={MobileOrder} alt="" />}
            {orderDetails?.deviceData?.deviceType === "tablet_brands" && <img className='py-2 px-3 rounded-lg border-gray-300 border-[1px]' src={TabletOrder} alt="" />}
             {orderDetails?.deviceData?.deviceType === "laptop_brands" && <img className='py-2 px-3 rounded-lg border-gray-300 border-[1px]' src={LaptopOrder} alt="" />}
                </div>}
                 {type==="shieldOrders" && <div>
                 {orderDetails?.deviceData?.deviceType === "Mobiles" && <img className='py-2 px-3 rounded-lg border-gray-300 border-[1px]' src={MobileOrder} alt="" />}
            {orderDetails?.deviceData?.deviceType === "Tablets" && <img className='py-2 px-3 rounded-lg border-gray-300 border-[1px]' src={TabletOrder} alt="" />}
             {orderDetails?.deviceData?.deviceType === "Laptops" && <img className='py-2 px-3 rounded-lg border-gray-300 border-[1px]' src={LaptopOrder} alt="" />}
                </div>}
                <div>
            <h4 className='text-[#0D1F37] lg:my-2 text-[14px] lg:text-[16px] font-[InterBold]' >{orderDetails?.packData?.packName}</h4>
            <p className='text-[#686868] text-[.7rem] font-[InterRegular] lg:text-[15px] capitalize' >{orderDetails?.deviceData?.modelName}</p>
           {orderDetails?.paymentData?.paymentStatus === "UnPaid" && <p className='text-gray-600 text-[.9rem] lg:text-[1rem]' >Amount Paid: <span className='font-[600] text-black' >Rs.{orderDetails?.paymentData?.paidAmt + Number(orderDetails?.estimateAmoutPaid && orderDetails?.estimateAmoutPaid)}</span></p>}
          </div>
            </div>
            <div className='bg-[#faf4f0] rounded-lg md:w-[250px] w-full lg:w-[400px] px-4 py-8' >
           <h3 className='font-[InterBold] text-[18px]' >Problem List:</h3>
            {
              orderDetails?.problemList &&  (
                <div>
                  {orderDetails?.problemList?.map((problem,index)=>(
                    <div>
                      <h4 className='font-[InterRegular]' >{index+1 }. {problem}</h4>
                    </div>
                  ))}
          
             
                </div>
              ) 
            }
          {orderDetails?.problemDesc?.length !== 0 &&  <p> <span className='font-[InterBold]' > Problem Description: </span>{orderDetails?.problemDesc}</p>}
       {/* <div className="flex items-center gap-6 my-4 justify-start " >
             {orderDetails?.recordedProblems?.length !== 0 && <audio className="w-[350px]" src={orderDetails?.recordedProblems} controls />}
            </div> */}
           </div>
         {orderDetails?.orderStatus === "Pending" &&  <div className='bg-[#faf4f0] rounded-lg md:w-[250px] w-full lg:w-[500px] px-4 py-8' >
           <h3 className='font-[InterMedium] text-[18px]' >Booked Repair Service:</h3>
            {
              orderDetails?.ServiceData ? (
                <div>
                  {orderDetails?.ServiceData?.map((service,index)=>(
                    <div>
                      <h4 className='font-[InterRegular]' >{index+1 }. {service?.serviceItem}({service?.serviceType}) - Rs.{service?.servicePrice}</h4>
                    </div>
                  ))}
          {  totalEstimatedValue !==0 &&   <h4 className='my-3 font-[InterBold] text-[16px]' >Repair Estimated Cost: <span className=' text-[#59A4FF]' > Rs.{totalEstimatedValue}</span></h4>   }
               {orderDetails?.orderStatus !== "Placed" && <Link to={`/orderDetails/repairOrders/${orderDetails.orderId}/placeCart`}><button onClick={handleApproveOrder}   className='py-2 px-6 rounded-lg bg-[#2967B0] mt-3 text-white' >Approve to Place Order</button></Link> }
                </div>
              ) : (
                <h4 className='font-[InterMedium] text-gray-600' >Finding Estimated Cost for You...</h4>
              )
            }
           </div>}
        </div>
         <div className='flex py-8 items-center border-gray-200 border-b-[8px] justify-start gap-6' >
            <img src={WarningIcon} alt="" />
              <p className='text-[#2967B0] font-[InterMedium] text-[.7rem] lg:text-[1rem]' >  The final estimated parts price has to be paid after the service.</p>
            </div>
            <div className='pt-6' >
                <h4 className='font-[InterMedium]' >Status: <span className={orderDetails?.orderStatus === "Cancelled" ? "text-red-600" : "text-green-400"} >{handleOrderStatus(orderDetails?.orderStatus)}</span>  </h4>
             {orderDetails?.orderStatus !== "Cancelled" &&  <OrderSteps orderStatus={orderDetails?.orderStatus} />}
              {orderDetails?.orderStatus !== "Cancelled" &&  (orderDetails?.orderStatus === "Pending" || orderDetails?.orderStatus === "Confirmed") &&   <div className='flex items-center   mt-6 gap-6 justify-center  lg:justify-start' >
                    <button onClick={handleOpenModal} className='bg-[#2967B0] text-[.7rem] lg:text-[1rem] rounded-lg border-[#2967B0]  border-[2px]  py-3 px-3 lg:px-6 text-white font-[500]' >Reschedule</button>
                    <button onClick={handleConfirmModal} className='border-red-600 text-[.7rem] lg:text-[1rem] border-[2px] text-red-600 rounded-lg py-3 px-3 lg:px-6 font-[500]'>Cancel Order</button>
                </div>}
            </div>
        </div>
    </div>
  )
}

export default OrderStatus