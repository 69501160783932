import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'

const EmptyCart = () => {
  return (
    <div className='flex items-center justify-center h-[100vh] flex-col' >
        <ShoppingCartIcon className='w-[80px]' />
        <h4 className='font-[InterMedium] text-[1.5rem] mt-3' >The Cart is Empty</h4>
      <Link to="/" > <button className='px-6 py-2 bg-[#2967B0] text-white font-[InterMedium] rounded-lg mt-4' >Back to Home</button></Link> 
    </div>
  )
}

export default EmptyCart