import React,{useEffect, useState} from 'react'
import MobileLogin from './MobileLogin'

import {useDispatch,useSelector} from "react-redux"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { db,auth } from "../../firebase.config";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { clearErrors, loginUsingPhone } from '../../actions/userAction';
import { useCountries } from "use-react-countries";
import OTP from './OTP';
import EmailLogin from './EmailLogin';
import PhoneEmail from './PhoneEmail';
const PhoneAuth = ({activeLogin,setActiveLogin,active,setActive,handleOpen,handleGoogleLogin,otp,setOtp,phone,setPhone,phoneEmail,setPhoneEmail,handlePhoneEmail,fullName,setFullName,email,setEmail}) => {
      
      const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
    
    const [phoneError, setPhoneError] = useState(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
    const [mobileNumberPresent, setMobileNumberPresent] = useState(false)
    const [otpLoading, setOtpLoading] = useState(false)
    const [userUid, setUserUid] = useState('')
    const [showOTP, setShowOTP] = useState(false)
    
  const dispatch = useDispatch();
   const { countries } = useCountries();
  const [country, setCountry] = React.useState(221);
  const { name, flags, countryCallingCode } = countries[country];

   const onCaptchVerify = () => {
    if (!window.recaptchaVerify) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {

          },
        },
        auth
      );
    }
    else{
     
    }
  };
  const onSignup = async () => {
    onCaptchVerify();
    setOtpLoading(true)
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + countryCallingCode + phone;
    const mobileRef = collection(db, "user");
    const q = query(mobileRef, where("ContactNo", "==", phone));
    const getMobileInfo = await getDocs(q);
    var phoneRegex = 10;
    // console.log(getMobileInfo);
      if(phone === "" || phone.length<10 || phone.length > 10){
        setPhoneError(true)
        
        setPhoneErrorMessage("Enter a Valid Phone Number")
      }
     
    else {
      setPhoneError(false)
  
      setMobileNumberPresent(true);
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setShowOTP(true);
          setOtpLoading(false)
        })
        .catch((error) => {
          console.log(error);
        });
    } 
  };
  const resendOTP = () =>{
     onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
     const formatPh = "+" + countryCallingCode + phone;
    auth().signInWithPhoneNumber(formatPh, appVerifier, auth)
  }

  const handleMobileRegister = async () =>{
    
  
     
       
       const userMobileRef = collection(db, "user");
        const qMobile = query(userMobileRef, where("ContactNo", "==", phone));
        const getUserMobileInfo = await getDocs(qMobile)
        if(getUserMobileInfo.docs.length !== 0){
         
          dispatch(loginUsingPhone(phone,otp,fullName,email)).then(()=>{
            if(!error){
              setPhone('')
              setOtp('')
              handleOpen()
            }
          })
          
          
        }
        else{
          if(!error){
            setPhone('')
            setOtp('')
            handleOpen()
          }
       
           setPhoneEmail(true)
          
        }
   
  
   
  }
  useEffect(() => {
    
  
  }, [error])
  
 
      
  return (
    <div>
    
    { showOTP ? <OTP otp={otp} phone={phone} otpLoading={otpLoading} handleMobileRegister={handleMobileRegister} setOtp={setOtp} /> : <MobileLogin otpLoading={otpLoading} onSignup={onSignup} countryCallingCode={countryCallingCode} handleGoogleLogin={handleGoogleLogin} active={active} name={name}  country={country} setCountry={setCountry} countries={countries} flags={flags} phone={phone} setPhone={setPhone} phoneError={phoneError} setPhoneError={setPhoneError} phoneErrorMessage={phoneErrorMessage} setPhoneErrorMessage={setPhoneErrorMessage} setActive={setActive} activeLogin={activeLogin} setActiveLogin={setActiveLogin} showOTP={showOTP} setShowOTP={setShowOTP} /> }
    <div id="recaptcha-container"></div>
    </div>
  )
}

export default PhoneAuth