import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Repair from "../../asset/repair.png"
import CrossIcon from "../../asset/crossIcon.svg"
const CoupanModal = ({coupanModal,handleCoupanModal,coupanCode,setCoupanCode,coupanList,deviceInfo}) => {
  return (
      <div>
      <Dialog
        size="md"
        open={coupanModal}
        handler={handleCoupanModal}
        className="bg-transparent outline-none shadow-none"
      >
        <Card className="mx-auto grid outline-none  py-3   w-full ">
          <CardBody className="flex flex-col gap-4">
          <div className="flex items-center justify-between" >
            <h4 className="font-[600] text-[1.5rem] text-black " >Apply Coupon</h4>
            <div className='w-[30px] h-[30px] ' >
                    <img src={CrossIcon} onClick={handleCoupanModal} className='cursor-pointer ' />
                </div>
                </div>
             <div className='border-[1px] my-4 rounded-lg border-gray-200 py-3 px-6 flex items-center justify-between' >
                <div>
                   <h4 onClick={handleCoupanModal} className='text-blue-400 cursor-pointer ' >{coupanCode?.title ? coupanCode?.title : 'Select a Coupon Code' }</h4> 
                </div>
             {Object.keys(coupanCode).length !== 0 &&   <div className='w-[30px] h-[30px] b' >
                    <img src={CrossIcon} onClick={()=>setCoupanCode({})} className='text-[.8rem] cursor-pointer ' />
                </div>}
            </div>
            <div>
                {
                    coupanList?.map((coupan)=>(
                        <div onClick={()=>{
                          if(coupan.validAmount < deviceInfo?.packData?.PackCost){
                              setCoupanCode(coupan)
                              handleCoupanModal()
                          }
                          
                          }} className={`${coupan.validAmount > deviceInfo?.packData?.PackCost ?  "bg-gray-100   border-gray-100" : "cursor-pointer border-gray-300" }  border-[1px] my-3  rounded-lg`} >
                        <div className="flex px-4 py-3 gap-8 flex-col md:flex-row items-start justify-start" >
                            <div>
                                <img className="lg:w-[80px] w-full" src={coupan.image} alt="" />
                            </div>
                            <div>
                                <h4 className="font-[600] text-[1.3rem] text-black " >{coupan.title}</h4>
                                <p className="text-gary-400">{coupan.pack}</p>
                                <p className="my-3 text-[.8rem] text-center  border-gray-300 border-[1px] rounded-lg py-1 px-3 " >{coupan.coupanCode}</p>
                                {coupan?.validAmount >= deviceInfo?.packData?.PackCost && <p className="text-red-600 text-[.8rem] font-[InterMedium]" >Valid on Order Above: {coupan.validAmount}</p> }
                            </div>
                           
                            </div>
                             <div className="py-4 uppercase font-[500] border-gray-100 text-center border-t-[1px] bg-gray-200" >
                                <h4>Tap to Apply</h4>
                            </div>
                        </div>
                    ))
                }
            </div>
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default CoupanModal