import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Accordion, AccordionContent, AccordionPanel, AccordionTitle } from 'flowbite-react';
const Faqs = ({faqs}) => {
    console.log(faqs)
  return (
    <div  className='xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px] py-8 xl:pb-[60px] xl:pt-[0px]'> 
        <div>
            <h3  className='md:text-[2.5rem] text-[1.7rem] text-[#0D1F37] font-[InterBold]     ' >Get Your Questions Answered</h3>
        </div>
        <div className='bg-white px-0 py-8 rounded-[16px] my-2' >
         <Accordion className='border-none' >
             {
            faqs && faqs?.slice(0,5).map((faq,key)=>(
                  <Accordion.Panel className="hover:bg-transparent px-[0px]  " >
         
           
              <Accordion.Title className="flex w-full my-2 justify-between outline-none  hover:bg-transparent bg-white px-0 border-b-[1px] border-[#DCDCDC]  xl:py-4 py-2 text-left text-[14px] xl:text-[18px] focus:ring-transparent  focus:outline-none ">
                <span className='font-[InterMedium] text-[#0D1F37] '>{faq?.question}</span>
                
              </Accordion.Title>
              <Accordion.Content className=" pt-4 font-[InterRegular] pb-2 text-[16px] px-0 text-[#686868]">
                {faq?.answer}
              </Accordion.Content>
         
       
        </Accordion.Panel>
            ))
        }
        </Accordion>
        <p className='mt-6 text-[#59A4FF] font-[InterMedium] text-[16px]' >Have a question unanswered? Ask Here</p>
        </div>
    </div>
  )
}

export default Faqs