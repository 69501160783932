import React,{useState} from 'react'
import Login from './Login'
const UnAuthorizedAccess = () => {
    const [open, setOpen] = useState(false)
    const handleOpen = () =>{
        setOpen(!open)
    }
  return (
    <div className='flex  items-center flex-col justify-center h-[100vh]' >
    <Login open={open} handleOpen={handleOpen}  />
        <h4 className='text-[2rem] font-[InterBold] ' >Login to Access This Page</h4>
        <button onClick={handleOpen} className='font-[InterMedium] my-4 px-8 py-3 text-[1.2rem] text-white bg-black rounded-lg' >Login</button>
    </div>
  )
}

export default UnAuthorizedAccess