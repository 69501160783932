import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Navbar from '../Components/Layout/Navbar'
import { onValue, ref } from 'firebase/database';
import { database } from '../firebase.config';
import { saveRepairDevice } from '../actions/repairActions';
import IssueBanner from '../Components/Repair/IssueBanner';
import SelectPlan from '../Components/Repair/SelectPlan';
import GuildelinesModel from '../Components/Repair/GuildelinesModel';
import PackDetailsModal from '../Components/Repair/PackDetailsModal';
import AddressModel from '../Components/Repair/AddressModel';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TimeModal from '../Components/Repair/TimeModal';
import StepsToRepair from '../Components/Repair/StepsToRepair';
import DownloadApp from '../Components/Home/DownloadApp';
import BelowCTA from '../Components/Home/BelowCTA';
import { Footer } from 'flowbite-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import ChooseLocation from '../Components/Repair/ChooseLocation';

const RepairPlan = () => {
     const { error, loading, isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  );

  const { deviceInfo } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const navigate = useNavigate()
    const [plan, setPlan] = useState({})
    const [guildelinesModel, setGuildelinesModel] = useState(false)
    const [addressModal, setAddressModal] = useState(false)
    const [detailsModal, setDetailsModal] = useState(false)
    const [locationData, setLocationData] = useState({});
    const [openLocation, setOpenLocation] = useState(false);
    const [allowAccess, setAllowAccess] = useState(true)
       const [map, setMap] = useState(null);
   const [geometry, setGeometry] = useState({
    lat:0,
    lng:0
  })
    const [timeModal, setTimeModal] = useState(false)
     const [isLogin, setIsLogin] = useState(false);
     const [day, setDay] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [time, setTime] = useState("");
     const [addresses, setAddresses] = useState([]);
     const [address, setAddress] = useState({
    name: userProfile?.FirstName,
    location: "",
    addressTwo: "",
    landmark: "",
    latitude: 0,
    longitude: 0,
    shortAddress: "",
    longAddress: "",
    alternameNumber: userProfile?.ContactNo ? userProfile?.ContactNo : "",
  });
    const onLoad = (map) => {
    setMap(map);
    
  };
  
   const handleLocationData = (latitude, longitude) => {
    if (address)
    axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        )
        .then((response) => {
          // setLocationData(response?.data?.results);
          // console.log(response?.data);
          console.log(latitude,longitude)
          onLoad(map)
          setGeometry({
            lat:latitude,
            lng:longitude
          })
          console.log(address)
          setAddress((prevState)=>(
          { ...prevState,
            addressTwo: response?.data?.results[4]?.formatted_address,
            shortAddress: response?.data?.results[8]?.formatted_address,
            longAddress: response?.data?.results[3]?.formatted_address,
            latitude: latitude,
            longitude: longitude,}
          ));
          console.log(address)
        })
        
        .catch((err) => {
          console.log(err.message);
        });
  };
    const handleDetailsModal = () =>{
        setDetailsModal(!detailsModal)
    }
    const handleAddressOpenModal = () =>{
        setAddressModal(!addressModal)
    }
    const handleGuildlinesOpenModal = () =>{
        setGuildelinesModel(!guildelinesModel)
    }
    const handleTimeOpenModal = () =>{
        setTimeModal(!timeModal)
    }
     const handleOpenLocation = () =>{
       setOpenLocation(!openLocation)
    }
  const [issueDescription, setIssueDescription] = useState("");
  const [repairPacks, setRepairPacks] = useState([])
  
     const fetchAddresses = () => {
    const reviewCountRef = ref(database, "addresses/" + users);

    onValue(reviewCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newProducts = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setAddresses(newProducts);
      }
    });
  };

 
  const handleRepairInfo = () => {
    dispatch(
      saveRepairDevice({
       ...deviceInfo,
       
        packData : {
          warrantyType : "",
          packName : plan?.packName,
          serviceCost : "",
          PackCost : plan?.planCost,
          packValidity : "",
          PackId : plan?.id
        },
        personalData : {
      fullName : address.name,
      emailId : userProfile?.email ? userProfile?.email : '' ,
      contactNo : address.alternameNumber
    },
        pickUpData : {
          geoPoint: new firebase.firestore.GeoPoint(address.latitude, address.longitude),
          addLine1 : address.location,
          addLine2 : address.addressTwo,
          addLine3 :address.landmark,
          bookingTimeDate : day + "/" + monthYear + " - " + time + ":00"
        },
      })
    );
  };
   const fetchRepairPacks = () => {
    const repairCountRef = ref(database, "shieldPack/");

    onValue(repairCountRef, (snapshot) => {
      const data = snapshot.val();
      const newProducts = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
      setRepairPacks(newProducts);
    });
  };
  const handleSaveAddress = () => {
    setAddressModal(!addressModal);
    setTimeModal(!timeModal);
  };
  const checkDay = (currentDate) => {
    switch (currentDate.getDay()) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thrusday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "";
    }
  };
   const handleSetDate = () => {
     
    if (isAuthenticated) {
     
      navigate("/cart");
      
    } else {
      handleTimeOpenModal()
      setIsLogin(true);
    }
  };
   const currentLocation = () =>{
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(function(position) {
      handleLocationData(position.coords.latitude,position.coords.longitude)
    })
    }
    else{
      setAllowAccess(false)
    }
        

    }
  useEffect(() => {
   fetchRepairPacks()
   
    navigator.geolocation.getCurrentPosition(function (position) {
      const { latitude, longitude } = position.coords;
      setAddress({ ...address, latitude: latitude, longitude: longitude });
      handleLocationData(position.coords.latitude, position.coords.longitude);
    });

   
  
    if (users && isAuthenticated) {
      fetchAddresses();
    }
    if(time.length !== 0 && isAuthenticated && timeModal === false && loading===false){
      navigate("/cart")
    }
   

  }, [isAuthenticated,time,timeModal,loading]);
  return (
    <>
   {!loading && <div>
     {guildelinesModel && (
        <GuildelinesModel
          handleAddressOpenModal={handleAddressOpenModal}
          handleGuildlinesOpenModal={handleGuildlinesOpenModal}
          guildelinesModel={guildelinesModel}
          setGuildelinesModel={setGuildelinesModel}
           geometry={geometry}
            address={address}
            handleOpenLocation={handleOpenLocation}
        />
      )}
       <ChooseLocation
        handleLocationData={handleLocationData}
        currentLocation={currentLocation}
        geometry={geometry}
        setGeometry={setGeometry}
        openLocation={openLocation}
        setOpenLocation={setOpenLocation}
        handleOpenLocation={handleOpenLocation}
        handleAddressOpenModal={handleAddressOpenModal}
        allowAccess={allowAccess}
        
      />
       <PackDetailsModal
        repairPacks={repairPacks}
        detailsModal={detailsModal}
        handleDetailsModal={handleDetailsModal}
      />
        {addressModal && (
        <AddressModel
        currentLocation={currentLocation}
          setLocationData={setLocationData}
          addresses={addresses}
          locationData={locationData}
          handleSaveAddress={handleSaveAddress}
          address={address}
          setAddress={setAddress}
          addressModel={addressModal}
          setAddressModel={setAddressModal}
          handleLocationData={handleLocationData}
          geometry={geometry}
          setGeometry={setGeometry}
          map={map}
          setMap={setMap}
          onLoad={onLoad}
          handleOpenLocation={handleOpenLocation}
        handleAddressOpenModal={handleAddressOpenModal}
        />
      )}
        {timeModal && (
        <TimeModal
          monthYear={monthYear}
          setMonthYear={setMonthYear}
          addressModel={addressModal}
          setAddressModel={setAddressModal}
          handleSetDate={handleSetDate}
          day={day}
          handleRepairInfo={handleRepairInfo}
          time={time}
          setTime={setTime}
          setDay={setDay}
          checkDay={checkDay}
          address={address}
          timeModal={timeModal}
          handleTimeOpenModal={handleTimeOpenModal}
          setTimeModal={setTimeModal}
        
        />
      )}
        <Navbar isLogin={isLogin} />
        <div className="lg:mt-[80px] mt-[100px] md:mt-[120px]" >
             <IssueBanner
              color={deviceInfo?.deviceData?.deviceColor}
              device={deviceInfo?.deviceData?.deviceType}
              manufacturer={deviceInfo?.deviceData?.manufacturerDetails}
              model={deviceInfo?.deviceData?.modelName}
              issueList={deviceInfo?.problemList}
              issue={deviceInfo?.issue}
              issueDescription={deviceInfo?.problemDesc}
            />
            <SelectPlan
               handleDetailsModal={handleDetailsModal}
              repairPacks={repairPacks}
             
              handleGuildlinesOpenModal={handleGuildlinesOpenModal}

              plan={plan}
              setPlan={setPlan}
            />
             <StepsToRepair />
      
        <DownloadApp />
        <BelowCTA />
        
       
      </div>
      <Footer bg="#0D1F37" />
        
    </div>}
    </>
  )
}

export default RepairPlan