import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../Components/Layout/Navbar'
import OrderInfo from '../Components/OrderDetail/OrderInfo'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase.config'
import OrderStatus from '../Components/OrderDetail/OrderStatus'
import Footer from '../Components/Layout/Footer'
import OrderReschdule from '../Components/OrderDetail/OrderReschdule'

const OrderDetails = () => {
    const {id,type} = useParams()

    const [orderDetails, setOrderDetails] = useState({})
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [serviceData, setServiceData] = useState([])
    const [totalEstimatedValue, setTotalEstimatedValue] = useState(0)
    const [monthYear, setMonthYear] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [approved, setApproved] = useState(false)
    const handleOpenModal = () =>{
      setOpenModal(!openModal)
    
    }
    const handleOrders  = async () =>{
        const docRef = doc(db, type, id);
      const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setOrderDetails(docSnap.data())
        if(docSnap.data()?.ServiceData?.length !== 0){
           let totalPrice = 0
        docSnap.data()?.ServiceData?.map((service)=>(
          totalPrice = totalPrice+Number(service.servicePrice)
        ))
        setTotalEstimatedValue(totalPrice)
        }
      } else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
    }
    }
    const handleCancelOrder = async () =>{
      const orderRef = doc(db,type,id)
      await updateDoc(orderRef,{
        orderStatus:"Cancelled"
      })
      window.location.reload(true)
    }
    const handleApproveOrder = async () =>{
      const orderRef = doc(db,type,id)
      await updateDoc(orderRef,{
        orderStatus:"Placed",
        estimateCostStatus:"Approved"
      })
       window.location.reload(true)
      
    }
    const handleReschdule = async () =>{
       const orderRef = doc(db,type,id)
      await updateDoc(orderRef,{
        pickUpData:{
          ...orderDetails?.pickUpData,
          bookingTimeDate: date + "/" + monthYear + " - " + time + ":00"
        }
      })
      handleOpenModal()
       window.location.reload(true)
    }
     const checkDay = (currentDate) =>{
      switch (currentDate.getDay()) {
   case 0:
    return "Sunday"
  case 1:
   return "Monday"
  case 2:
  return "Tuesday"
  case 3:
    return "Wednesday"
  case 4:
     return "Thrusday"
  case 5:
     return "Friday"
  case 6:
    return "Saturday"
    default:
    return ""
       
}
    }

    useEffect(() => {
      handleOrders()
      
    }, [])
  return (
    <div>
        <Navbar />
        <OrderReschdule openModal={openModal} monthYear={monthYear} setMonthYear={setMonthYear} day={date} setDay={setDate} time={time} setTime={setTime} checkDay={checkDay} handleOpenModal={handleOpenModal} handleReschdule={handleReschdule} setOpenModal={setOpenModal} />
        <div className='mt-[80px]' >
            <OrderInfo  type={type} orderDetails={orderDetails}  />
            <OrderStatus orderId={id} serviceData={serviceData} approved={approved} handleApproveOrder={handleApproveOrder} totalEstimatedValue={totalEstimatedValue} handleCancelOrder={handleCancelOrder} handleOpenModal={handleOpenModal} type={type} orderDetails={orderDetails} />
            <Footer bg="#0D1F37" />
        </div>
    </div>
  )
}

export default OrderDetails