import React from 'react'
import MobileRepair from "../../asset/mobileDevice.png"
import TabRepair from "../../asset/tablet.png"
import LaptopRepair from "../../asset/laptop.png"
import { useNavigate } from 'react-router-dom'
const SelectDevice = ({device,setDevice,handleRepairInfo}) => {
    const navigate = useNavigate()
  return (
    <div>
        <div className='xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:py-[30px]' >
            <h3 className='lg:text-[24px] text-[20px] font-[InterBold] ' >Select a Device</h3>
            <div className='flex items-center mt-8 lg:mt-12 justify-start gap-4 lg:gap-12' >
                <div className='cursor-pointer' onClick={()=>{
                    setDevice("mobile_brands")
                    navigate("/repairManufacturer")
                    handleRepairInfo("mobile_brands")
                }} >
                    <img className='w-[200px]  rounded-lg lg:px-6 lg:py-6 py-3 px-3 bg-gray-200 ' src={MobileRepair} alt="mobile repair"/>
                    <p className='text-center lg:text-[20px] text-[16px] font-[InterMedium] mt-3 ' >Mobile</p>
                </div>
               <div className='cursor-pointer' onClick={()=>{
                    setDevice("tablet_brands")
                  navigate("/repairManufacturer")
                 handleRepairInfo("tablet_brands")
                }}>
                    <img src={TabRepair} className='w-[200px]  rounded-lg lg:px-6 lg:py-6 py-3 px-3 bg-gray-200 ' alt="mobile repair"/>
                    <p className='text-center lg:text-[20px] text-[16px] font-[InterMedium] mt-3'>Tablets</p>
                </div>
                <div  className='cursor-pointer' onClick={()=>{
                    setDevice("laptop_brands")
                   navigate("/repairManufacturer")
                    handleRepairInfo("laptop_brands")
                }}>
                    <img className='w-[200px]  rounded-lg lg:px-6 lg:py-6 py-3 px-3 bg-gray-200 ' src={LaptopRepair} alt="mobile repair"/>
                    <p className='text-center lg:text-[20px] text-[16px] font-[InterMedium] mt-3'>Laptop</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SelectDevice