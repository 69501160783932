import React from 'react'
import { Card, Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import {Link} from "react-router-dom"
import {TrashIcon} from "@heroicons/react/24/outline"



const TABLE_HEAD = ["Name","Product", "Price", "Type", "Order Status",  "View"];
const ShieldOrders = ({shieldOrderList}) => {
  return (
    <div className='col-span-3 mx-6 my-6' >
    <div className='my-3' >
    <h4 className='my-2 font-[600] text-[2rem] ' >My Shields</h4>
       
    </div>
         <Card className="overflow-scroll h-[100vh] w-full">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {shieldOrderList &&  shieldOrderList?.map(({data,id }, index) => {
            const isLast = index === shieldOrderList.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
 
            return (
              <tr key={data.deviceInformation.model}>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.deviceInformation.model}
                  </Typography>
                </td>
                <td className={`${classes} bg-blue-gray-50/50`}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                     {data.product}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                   Rs. {data.totalPrice}
                  </Typography>
                </td>
                 <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.deviceInformation.device}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {data.orderStatus}
                  </Typography>
                </td>
                <td className={`${classes} bg-blue-gray-50/50`}>
                <Link to={`/viewShieldOrder/${id}`} >
                  <Typography as="a" href="#" variant="small" color="blue" className="font-medium">
                   View
                  </Typography>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
    </div>
  )
}

export default ShieldOrders