import React from 'react'
import Samsung from "../../asset/brandGray/samsung.svg"
import Apple from "../../asset/brandGray/apple.svg"
import Honor from "../../asset/brandGray/honor.svg"
import Iqoo from "../../asset/brandGray/iqoo.svg"
import Mi from "../../asset/brandGray/mi.svg"
import Motorola from "../../asset/brandGray/motorola.svg"
import Oppo from "../../asset/brandGray/oppo.svg"
import Realme from "../../asset/brandGray/realme.svg"
import Vivo from "../../asset/brandGray/vivo.svg"
import Google from "../../asset/brandGray/google.svg"
import InfiniteLooper from '../Layout/InfiniteScrolling'
import { BrandBanner } from './BrandBanner'
const BrandList = () => {
    const images = [Apple,Samsung,Honor,Iqoo,Mi,Motorola,Oppo,Realme,Vivo,Google].map((image) => ({
  id: crypto.randomUUID(),
  image
}));
  return (
    <div className='bg-[#DEE0FF] px-[10px]' >
        <BrandBanner images={images} speed={12000}  />
    </div>
  )
}

export default BrandList