import React from 'react'
import ExtendedWarrenty from "../../asset/feature23.png"
import Accidental from "../../asset/feature2.png"
import { ArrowLeftCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
const SelectPlan = ({service,setService,stage,setStage,handleDetailsModal}) => {
  return (
     <div>
        <div className='lg:px-36 px-6  py-12 lg:py-24' >
      <div className='flex md:flex-row flex-col-reverse gap-4 items-start justify-between' >
     
    <h4>Select a Manufacturer</h4>
     <div onClick={()=>setStage("select device")} className='flex  text-red-400 font-[600]  cursor-pointer rounded-lg py-2 lg:py-3 px-2 lg:px-4 bg-gray-200 items-center justify-start gap-3 ' >
      <ArrowLeftIcon className='lg:w-[20px] w-[10px] ' />
      <p className='text-[.8rem] lg:text-[1rem]' >Back</p>
    </div>
    </div>
            <div className='flex items-start lg:items-center flex-wrap mt-12 justify-start gap-4 lg:gap-12' >
                <div className='cursor-pointer lg:w-[450px] w-full md:w-[350px] lg:h-[250px] flex items-start justify-start flex-col px-6  lg:px-12 rounded-lg py-3 lg:py-6 bg-gray-300'  >
                    <img className='lg:w-[80px] w-[50px] ' src={ExtendedWarrenty} alt="mobile repair"/>
                    <p onClick={()=>{
                    setService("Zippy X-Tended")
                    setStage("select manufacturer")
                    
                }} className='text-center text-[1rem] lg:text-[1.5rem] mt-3 font-[600]' >Extended Warrenty</p>
                    <p className='lg:text-[.9rem] text-[.7rem] my-2' >(Previous 6 months from today )</p>
                    <p onClick={()=>{
                        setService("Zippy X-Tended")
                        handleDetailsModal()
                    }} className='text-[#59A4FF] text-[.7rem] ' >View Details</p>
                </div>
                 <div className='cursor-pointer lg:w-[450px] w-full md:w-[350px] lg:h-[250px] flex items-start justify-start flex-col px-6  lg:px-12 rounded-lg py-3 lg:py-6 bg-gray-300' >
                    <img className='lg:w-[80px] w-[50px]' src={Accidental} alt="mobile repair"/>
                    <p onClick={()=>{
                    setService("Zippy Care")
                    setStage("select manufacturer")
                    
                }}  className=' lg:text-[1.5rem] text-[1rem] mt-3 font-[600]' >Accidental & Liquid Damage</p>
                    <p className='lg:text-[.9rem] text-[.7rem] my-2' >(3 days previous from today)</p>
                    <p onClick={()=>{
                       setService("Zippy Care")
                        handleDetailsModal()
                    }} className='text-[#59A4FF] text-[.7rem] ' >View Details</p>
                </div>
              
            </div>
        </div>
    </div>
  )
}

export default SelectPlan