import React from 'react'
import QR from "../../asset/qr.png"
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import BelowCtaImage from "../../asset/belowCta.svg"
import BelowCtaImageMobile from "../../asset/mobileAppQr.svg"
const BelowCTA = () => {
  return (
    // <div className='bg-[#E7F2FF]  xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px] py-8 xl:py-[60px]' >
    //     <h4 className='font-[700] font-[InterBold] text-[32px]  text-[#2967B0]' >Ready, Set, Insure!</h4>
    //     <p className='text-[1.3rem] font-[InterMedium] ' >It’s easier in the apps</p>
    //     <div className='mt-6  flex flex-col md:flex-row gap-6 items-center justify-start ' >
    //         <div className='bg-white relative lg:px-8 px-4 py-3 lg:py-6 w-full lg:w-[600px] flex items-center  justify-start ' >
    //             <div>
    //                 <img className='mr-6 w-[70px] lg:w-[100px]' src={QR} alt="" />
    //             </div>
    //             <div>
    //                 <h4 className='font-[600] text-[1rem] lg:text-[1.3rem]' >Download the Android App</h4>
    //                 <p className='text-gray-600 text-[.6rem] lg:text-[1rem]' >Scan to download</p>
                   
    //             </div>
    //              <ArrowRightIcon className='text-[#2967B0] absolute  w-[15px] lg:w-[25px] top-[50%]  right-[10%] ' />
    //         </div>
    //           <div className='bg-white relative lg:px-8 px-4 py-3 lg:py-6 lg:w-[600px] w-full flex items-center justify-start ' >
    //             <div>
    //                 <img className='mr-6 w-[70px] lg:w-[100px]' src={QR} alt="" />
    //             </div>
    //             <div>
    //                 <h4 className='font-[600] text-[1rem] lg:text-[1.3rem]' >Download the IOS App</h4>
    //                 <p className='text-gray-600 text-[.6rem] lg:text-[1rem] ' >Scan to download</p>
                   
    //             </div>
    //               <ArrowRightIcon className='text-[#2967B0] absolute  w-[15px] lg:w-[25px] top-[50%]  right-[10%] ' />
    //         </div>
    //     </div>
    // </div>
    <div>
      <img className='w-full md:block hidden ' src={BelowCtaImage} alt="" />
      <img className='w-full block md:hidden ' src={BelowCtaImageMobile} alt="" />
    </div>
  )
}

export default BelowCTA