import React,{useState,useEffect} from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { onValue, ref } from 'firebase/database'
import { database } from '../../firebase.config'

const Faq = () => {
    const [faqs, setFaqs] = useState([])
    const fetchFaqs = () =>{
     const repairCountRef = ref(database, 'faqs/' );
  if(repairCountRef){
   onValue(repairCountRef, (snapshot) => {
  const data = snapshot.val();
  if(data){
  const newProducts = Object.keys(data).map(key=>({
    id:key,
    ...data[key]
  }));
   setFaqs(newProducts)
}
 
},);
  }
  }

    useEffect(() => {
        fetchFaqs()
    }, [])

  return (
     <div  className='bg-white my-4 mx-3 rounded-lg px-4 py-4'> 
        
        <div className=' px-0 py-8 rounded-[16px] my-2' >
        <h4 className="text-[2rem] font-[600] ml-[5px]" >FAQS</h4>
             {
            faqs && faqs?.map((faq,key)=>(
                  <Disclosure className="" >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full my-6 font-[InterMedium] justify-between  bg-white border-b-[1px] border-[#d0d0d0] px-3  xl:px-6 xl:py-4 py-2 text-left text-[14px] xl:text-lg font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className='font-[InterMedium]'>{faq?.question}</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 font-[InterRegular] pb-2 text-lg text-gray-500">
                {faq?.answer}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
            ))
        }
        </div>
    </div>
  )
}

export default Faq