import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import GuildelinesIcon from "../../asset/guildelinesIcon.png"
import { MapPinIcon } from "@heroicons/react/24/solid";

const OrderReschdule = ({openModal,setOpenModal,handleOpenModal,handleReschdule,checkDay,day,setDay,time,setTime,setMonthYear,monthYear}) => {
       const currentDate = new Date()
   let nextDay = new Date(currentDate.getTime() + (24 * 60 * 60 * 1000));
    let thirdDay = new Date(nextDay.getTime() + (24 * 60 * 60 * 1000));
     let forthDay = new Date(thirdDay.getTime() + (24 * 60 * 60 * 1000));
      let fifthDay = new Date(forthDay.getTime() + (24 * 60 * 60 * 1000));
       let sixthDay = new Date(fifthDay.getTime() + (24 * 60 * 60 * 1000));
        let seventhDay = new Date(sixthDay.getTime() + (24 * 60 * 60 * 1000));
        const [timingList, setTimingList] = useState([])
    const [nowDate, setNowDate] = useState(null)
       
    const disableClass = "border-gray-400 w-[120px] text-gray-400 text-center rounded-lg border-[1px] px-4 py-3"
    const activeClass = "border-blue-400 cursor-pointer w-[80px] md:w-[120px] text-blue-400 text-center rounded-lg border-[1px] px-2 py-2 md:px-4 md:py-3"
    const inactiveClass = "border-gray-400 cursor-pointer w-[80px] md:w-[120px] text-black text-center rounded-lg border-[1px] px- py-2 md:px-4 md:py-3"
    const inactiveTimingClass = "md:px-3 px-2 cursor-pointer py-3 text-black border-gray-300 border-[1px] rounded-lg"
    const activeTimingClass = "md:px-3 px-2 cursor-pointer py-3 border-blue-400 text-blue-400 border-[1px] rounded-lg"
    const timeList = (date) =>{
      console.log(date.getDate())
      if(date !== currentDate ){
        console.log("hii")
        let timings = []
        for(let i = 10; i < 19; i++){
          timings.push(i)
          console.log(timings)
        }
        setTimingList(timings)
      }
      else if(date.getHours()<=18 && date === currentDate && date.getHours()>10 ){
         let timings = []
         for(let i = date.getHours() + 1; i < 19; i++){
          timings.push(i)
        }
        setTimingList(timings)
      }
      else if(date.getHours()<=18 && date === currentDate && date.getHours()<10 ){
         let timings = []
         for(let i = 10; i < 19; i++){
          timings.push(i)
        }
        setTimingList(timings)
      }
    }
  return (
    <div>
      <Dialog
        size="xl"
        open={openModal}
        handler={handleOpenModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto grid px-2 py-2 overflow-scroll lg:overflow-hidden  w-full ">
          <CardBody className="overflow-scroll lg:overflow-hidden ">
         
            <div className="md:my-8 my-3" >
                <h4 className="font-[600] text-black text-[1.2rem]" >When should the Zipster arrive?</h4>
                <p>Your service will take around 1hr and 40 mins</p>
                <div className="flex  mt-3 gap-4 flex-wrap items-center justify-start" >
                    <div onClick={()=>{
                      setDay(currentDate.getDate())
                      setMonthYear(currentDate.getMonth() + "/" + currentDate.getFullYear())
                      timeList(currentDate)
                      }} className={currentDate.getHours() >= '18' ? disableClass : inactiveClass && day === currentDate.getDate() ? activeClass : inactiveClass   } >
                <h4 className="font-[500] lg:text-[1rem] text-[.7rem] " > {checkDay(currentDate)}</h4>   
                     <p> {currentDate.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(nextDay.getDate())
                      setMonthYear(nextDay.getMonth() + "/" + nextDay.getFullYear())
                      timeList(nextDay)
                      }} className={day === nextDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className="font-[500] lg:text-[1rem] text-[.7rem]" > {checkDay(nextDay)}</h4>   
                     <p> {nextDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(thirdDay.getDate() )
                      setMonthYear(thirdDay.getMonth() + "/" + thirdDay.getFullYear())
                      timeList(thirdDay)
                      }} className={day === thirdDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className="font-[500] lg:text-[1rem] text-[.7rem]" > {checkDay(thirdDay)}</h4>   
                     <p> {thirdDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(forthDay.getDate())
                      setMonthYear(forthDay.getMonth() + "/" + forthDay.getFullYear())
                      timeList(forthDay)
                      }} className={day === forthDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className="font-[500] lg:text-[1rem] text-[.7rem]" > {checkDay(forthDay)}</h4>   
                     <p> {forthDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(fifthDay.getDate())
                      setMonthYear(fifthDay.getMonth() + "/" + fifthDay.getFullYear())
                      timeList(fifthDay)
                      }} className={day === fifthDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className="font-[500] lg:text-[1rem] text-[.7rem]" > {checkDay(fifthDay)}</h4>   
                     <p> {fifthDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(sixthDay.getDate() )
                      setMonthYear(sixthDay.getMonth() + "/" + sixthDay.getFullYear())
                      timeList(sixthDay)
                      }} className={day === sixthDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className="font-[500] lg:text-[1rem] text-[.7rem]" > {checkDay(sixthDay)}</h4>   
                     <p> {sixthDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(seventhDay.getDate() )
                      setMonthYear(seventhDay.getMonth() + "/" + seventhDay.getFullYear())
                      timeList(seventhDay)
                      }} className={day === seventhDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className="font-[500] lg:text-[1rem] text-[.7rem]" > {checkDay(seventhDay)}</h4>   
                     <p> {seventhDay.getDate()}</p>  
                    </div>
                </div>
            </div>
             <div className="md:my-8 my-4" >
                <h4 className="font-[600] text-black text-[.9rem] md:text-[1.2rem]" >Select start time of service</h4>
        
                <div className="flex  mt-3 gap-4 items-center flex-wrap justify-start" >
                
                   { timingList?.map((timing)=>(
                    <button  onClick={()=>setTime(timing)} className={timing === time ? activeTimingClass : inactiveTimingClass} >
                            <p className="text-[.6rem] lg:text-[1rem]" >{timing}:00 </p>
                        </button>
                   ))
                   }
                 
                </div>
            </div>
            <div className="flex items-center gap-3 justify-end" >
            <button onClick={handleOpenModal} className="bg-gray-500 px-6 py-3 text-white rounded-lg font-[500]" >Back</button>
              <button onClick={()=>{
                if(day!=="" && time!==""){
                     handleReschdule()
                }
                else{
                  alert("Select Date and Time")
                }
               }} className="bg-[#2967B0] px-6 py-3 text-white rounded-lg font-[500]" >Proceed to Checkout</button>
            </div>
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default OrderReschdule