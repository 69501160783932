import { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import Logo from "../../asset/Logo.png";
import { Link } from "react-router-dom";
import Logo2 from "../../asset/logo.svg";
import {
  Bars3Icon,
  XMarkIcon,
  InformationCircleIcon,
  HomeIcon,
  UserGroupIcon,
  HomeModernIcon,
  UserCircleIcon,
  ShieldCheckIcon,
  WrenchScrewdriverIcon,
  ArrowRightOnRectangleIcon,
  ArrowLeftOnRectangleIcon,
  WrenchIcon,
  MapPinIcon,
  ShoppingCartIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowSmallDownIcon,
  ChevronDownIcon,

  UserIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { logout } from "../../actions/userAction";
import Login from "../../Pages/Login";
import axios from "axios";
import MapIcon from "../../asset/MapIcon.png";
import ChooseLocation from "./ChooseLocation";
import AddressIcon from "../../asset/addressIcon.svg";
import ProfileIcon from "../../asset/profileIcon.svg";
import LogoutIcon from "../../asset/logoutIcon.svg";
import OrderIcon from "../../asset/orderIcon.svg";
import CartIcon from "../../asset/cart.png"
const mobileNav = [
  {
    name: "Home",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "/",
    icon: HomeIcon,
  },
  {
    name: "Zepair",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "/repair",
    icon: WrenchScrewdriverIcon,
  },

  {
    name: "About",
    description: "Connect with third-party tools that you're already using.",
    href: "/about",
    icon: InformationCircleIcon,
  },
  {
    name: "Success Stories",
    description: "Connect with third-party tools that you're already using.",
    href: "/stories",
    icon: UserGroupIcon,
  },
  {
    name: "Cart",
    description: "Connect with third-party tools that you're already using.",
    href: "/cart",
    icon: ShoppingCartIcon,
  },
];

const accountDetails = [
  {
    name: "Profile",
    description: "Connect with third-party tools that you're already using.",
    href: "/profile",
    status: "profile",
    icon: ProfileIcon,
  },
  {
    name: "Orders",
    description: "Connect with third-party tools that you're already using.",
    href: "/profile",
    status: "orders",
    icon: OrderIcon,
  },
  {
    name: "Manage Address",
    description: "Connect with third-party tools that you're already using.",
    href: "/profile",
    status: "address",
    icon: AddressIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ isLogin }) {
  const { error, loading, isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  );

  const [open, setOpen] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [openLocation, setOpenLocation] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [geometry, setGeometry] = useState({
    lat: 0,
    lng: 0,
  });
  const handleOpen = () => setOpen((cur) => !cur);
  const handleOpenLocation = () => setOpenLocation((curr) => !curr);
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
  };

  const handleLocationData = (latitude, longitude) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
      )
      .then((response) => {
        setLocationData(response?.data?.results);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(locationData);
  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      handleLocationData(position.coords.latitude, position.coords.longitude);
    });
  };

  useEffect(() => {
    if (isLogin) {
      handleOpen();
    }
    setActivePage(sessionStorage.getItem("activePage"));
  }, [isLogin, geometry]);

  return (
    <>
      <Login open={open} handleOpen={handleOpen} />
      <ChooseLocation
        handleLocationData={handleLocationData}
        currentLocation={currentLocation}
        geometry={geometry}
        setGeometry={setGeometry}
        openLocation={openLocation}
        setOpenLocation={setOpenLocation}
        handleOpenLocation={handleOpenLocation}
      />
      <Popover className=" w-full fixed top-0 font-[Inter]  bg-[#ffffff] text-black z-[999]">
        <div className="mx-0 max-w-full w-full py-2 px-6">
          <div className="flex items-center justify-between xl:mx-[140px]  py-3 md:justify-start md:space-x">
            <div className="flex  justify-start items-center lg:w-0 lg:flex-1">
              <Link
                onClick={() => {
                  sessionStorage.setItem("activePage", "Home");
                }}
                to="/"
              >
                <span className="sr-only">Your Company</span>
                <img className="lg:w-[110px] w-[80px] " src={Logo2} alt="" />
              </Link>
              <Popover.Group
                as="nav"
                className="hidden xl:ml-12 mt-1  lg:ml-6 md:ml-4 md:space-x-[10px] lg:space-x-[20px] md:flex"
              >
                <Link
                  onClick={() => {
                    sessionStorage.setItem("activePage", "Zepair");
                  }}
                  to="/repair"
                  className={` lg:text-[15px] md:text-[12px] pb-1 font-[InterMedium] tracking-wide  text-[#121212]`}
                >
                  Zepair
                </Link>

                <Link
                  to="/about"
                  onClick={() => {
                    sessionStorage.setItem("activePage", "About");
                  }}
                  className={` lg:text-[15px] md:text-[12px] pb-1 font-[InterMedium] tracking-wide  text-[#121212]`}
                >
                  About
                </Link>
                <Link
                  to="/stories"
                  onClick={() => {
                    sessionStorage.setItem("activePage", "Stories");
                  }}
                  className={` lg:text-[15px] md:text-[12px] pb-1 font-[InterMedium] tracking-wide  text-[#121212]`}
                >
                  Success Stories
                </Link>
              </Popover.Group>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md  p-2 text-[#2967B0]  hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-8 w-8" aria-hidden="true" />
              </Popover.Button>
            </div>

            {isAuthenticated ? (
              <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                {locationData.length !== 0 ? (
                  <div>
                    <p
                      onClick={handleOpenLocation}
                      className="ml-8 text-[14px] hidden hover:bg-gray-100 cursor-pointer font-[InterMedium]  xl:px-[20px] xl:py-[12px]  lg:inline-flex items-center justify-center whitespace-nowrap rounded-lg   text-[#575757]  px-4 py-2      font-[400]   "
                    >
                      <img src={MapIcon} className="w-4 h-4 mr-2   " />{" "}
                      {locationData[7]?.formatted_address}
                    </p>
                  </div>
                ) : (
                  <p
                    onClick={handleOpenLocation}
                    className="ml-8 text-[14px] hidden hover:bg-gray-100 cursor-pointer font-[InterMedium]  xl:px-[20px] xl:py-[12px]  lg:inline-flex items-center justify-center whitespace-nowrap rounded-lg   text-[#575757]  px-4 py-2     "
                  >
                    <img src={MapIcon} className="w-4 h-4 mr-2  " /> Choose a
                    Location{" "}
                    <ChevronDownIcon className="w-[20px] ml-3 text-gray-500" />
                  </p>
                )}
                <div className="  ">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`
               
                ml-2 text-[14px] outline-none hover:bg-gray-100 cursor-pointer font-[InterMedium]   xl:px-[20px] xl:py-[12px]  inline-flex items-center justify-center whitespace-nowrap rounded-lg   text-[#575757]  px-4 py-2   `}
                        >
                          <UserIcon className="w-[20px] mr-3 text-[#1081E0]" />
                          {userProfile?.FirstName ? (
                            <span className="text-[#4D4D4D] font-[InterMedium] capitalize">
                              {" "}
                              {userProfile?.FirstName}
                            </span>
                          ) : (
                            <span className="text-[#4D4D4D] font-[InterMedium]">
                              Hii, User
                            </span>
                          )}
                          {/* <UserCircleIcon className='w-[40px] text-[#DE562C] '  /> */}
                          <ChevronDownIcon
                            className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-6 w-6 text-[#4D4D4D] transition duration-150 ease-in-out group-hover:text-opacity-80`}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-[180px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-xl">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className=" bg-white w-full  ">
                                {accountDetails.map((item) => (
                                  <Link
                                    key={item.name}
                                    to={item.href}
                                    onClick={() => {
                                       sessionStorage.setItem("activePage", "Profile");
                                      sessionStorage.setItem(
                                        "profileStatus",
                                        item.status
                                      );
                                    }}
                                    className=" flex border-b-[1px] py-2 font-[InterMedium] px-2 items-center rounded-lg  transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                  >
                                    <div className="flex h-8 w-8 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                      <img
                                        src={item.icon}
                                        className="flex-shrink-0 text-[#1D1D1D] h-4 w-4 mr-1 text-sky-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="ml-0">
                                      <p className="text-[13px] font-[InterMedium]  text-[#1D1D1D]">
                                        {item.name}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                                <div
                                  onClick={onLogout}
                                  className="cursor-pointer flex border-b-[1px] py-2 font-[Inter] px-2 items-center rounded-lg  transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                >
                                  <div className="flex h-8 w-8 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                    <img
                                      src={LogoutIcon}
                                      className="flex-shrink-0 text-[#1D1D1D] h-4 w-4 mr-1 text-sky-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-0">
                                    <p className="text-[13px] font-[InterMedium] text-[#1D1D1D]">
                                      Logout
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                <Link to="/cart" >
                  <button className="flex xl:px-[20px] xl:py-[12px] hover:bg-gray-100 rounded-lg items-center justify-start gap-3" >
                    <img className="w-[20px] text-[#1081E0]" src={CartIcon} />
                    <p className="font-[InterMedium] text-[#4D4D4D] text-[14px]" >Cart</p>
                  </button>
                </Link>
              </div>
            ) : (
              <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                {locationData.length !== 0 ? (
                  <div>
                    <p
                      onClick={handleOpenLocation}
                      className="ml-8 cursor-pointer text-[12px] hidden font-[InterMedium]   xl:px-[29px] xl:py-[18px] xl:h-[40px] lg:inline-flex items-center justify-center whitespace-nowrap rounded-[50px]   text-[#575757]  px-4 py-2      "
                    >
                      <img src={MapIcon} className="w-4 h-4 mr-2  " />{" "}
                      {locationData[7]?.formatted_address}
                    </p>
                  </div>
                ) : (
                  <p
                    onClick={handleOpenLocation}
                    className="ml-8 text-[14px] hidden hover:bg-gray-100 cursor-pointer font-[InterMedium]   xl:px-[20px] xl:py-[12px]  lg:inline-flex items-center justify-center whitespace-nowrap rounded-lg   text-[#575757]  px-4 py-2     "
                  >
                    <img src={MapIcon} className="w-4 h-4 mr-2  " /> Choose a
                    Location{" "}
                    <ChevronDownIcon className="w-[20px] ml-3 text-gray-500" />
                  </p>
                )}
                <button
                  onClick={handleOpen}
                  className="ml-8 text-[10px] font-[InterMedium] lg:text-[16px] xl:px-[30px] xl:py-[10px]  inline-flex items-center justify-center whitespace-nowrap rounded-[10px] border border-transparent bg-[#2967B0] px-4 py-2   text-[#ffffff] shadow-sm hover:bg-gray-600"
                >
                  Get Started
                </button>
              </div>
            )}
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-[#ffffff] shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={Logo2} alt="Your Company" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:white">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6  " aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid grid-cols-2  pb-3 gap-y-6">
                    {mobileNav.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                      >
                        <item.icon
                          className="h-6 w-6 text-[#4D4D4D] flex-shrink-0 "
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-[InterMedium] ">
                          {item.name}
                        </span>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="space-y-6 pb-6 px-3">
                {isAuthenticated ? (
                  <div className="pb-3 ">
                    <h4 className="mb-3 font-[InterMedium] text-[1.3rem] capitalize  text-center ">
                      {userProfile?.FirstName}
                    </h4>
                    <div className="grid grid-cols-2 gap-y-6  ">
                      {accountDetails.map((item) => (
                        <Link
                          key={item.name}
                          onClick={()=> 
                            sessionStorage.setItem(
                                        "profileStatus",
                                        item.status
                                      )}
                          to={item.href}
                          className="text-base font-[500] py-2 px-3 hover:bg-[#3e3e3e42] mx-2 flex items-center justify-start text-gray-900 hover:text-gray-700"
                        >
                          <img
                            src={item.icon}
                            className="h-6 w-6 text-[#4D4D4D] flex-shrink-0 "
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base font-[InterMedium] ">
                            {item.name}
                          </span>
                        </Link>
                      ))}
                    </div>
                    <div className="  text-center mt-6 mb-2 text-white flex items-center justify-center ">
                      <button
                        onClick={onLogout}
                        className="outline-none bg-[#DE562C] rounded-lg px-12 py-3 rounded-[10px] flex items-center justify-center "
                      >
                        <ArrowLeftOnRectangleIcon className="w-[25px] mr-3" />{" "}
                        Sign Out
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={handleOpen}
                      className="flex font-[InterMedium]  w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r bg-[#2967B0] px-4 py-2 text-base font-medium text-white shadow-sm "
                    >
                      Login
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}
