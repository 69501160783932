import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
  
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import GuildelinesIcon from "../../asset/guildelinesIcon.png"
import GoogleMapReact from 'google-map-react'

import { Map } from '@googlemaps/react-wrapper'
import { MapPinIcon } from "@heroicons/react/24/solid";
import LocationMarker from "../Layout/LocationMarker";
import { MapIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Autocomplete from "react-google-autocomplete";
import PlaceAutocomplete from "../Repair/PlaceAutocomplete";
const EditAddressModel = ({editAddressModal,setEditAddressModal,handleEditAddressModal,singleAddress,setSingleAddress,setEditAddress,updateAddress,onLoad,map,setMap,geometry,setGeometry,handleLocationData,locationData,setLocationData,handleEditLocationData}) => {
 const defaultProps = {
    center: {
      lat: singleAddress?.latitude,
      lng: singleAddress?.longitude
    },
    zoom: 15
  };
  console.log(singleAddress)
  const [activeSection, setActiveSection] = useState("add address")
 const [locationError, setLocationError] = useState(false)
  const [locationChange, setLocationChange] = useState(false)
  const [landmarkError, setLandmarkError] = useState(false)
  const [landmarkChange, setLandmarkChange] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [contactChange, setContactChange] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [nameChange, setNameChange] = useState(false)
    const handleAddressSave = (id) =>{
    if(singleAddress?.name === ""){
        setNameError(true)
    }
    else if(singleAddress?.location.length === 0){
      setLocationError(true)
    }
   
    else if(singleAddress.alternameNumber.length === 0 || singleAddress.alternameNumber.length > 10 || singleAddress.alternameNumber.length < 10){
      setContactError(true)
    }
   
    else{
      updateAddress(id)
    }

  }

    const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCeV4tAAnF0MUsAiJL1vMBN-4AOzpIrj0A', // Replace with your API key
  });

  const mapStyles = {
    height: '450px',
    width: '100%',
    borderRadius:'10px'
  };
  const mapStylesTwo = {
    height: '250px',
    width: '100%',
    borderRadius:'10px'
  };


  const onMarkerDragEnd = (e) => {
    setGeometry({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
   
    handleLocationData(e.latLng.lat(),e.latLng.lng())
  };

 

    console.log(geometry)
  useEffect(() => {
   
  }, [])
  return (
     <div>
      <Dialog
        size="xl"
        open={editAddressModal}
        handler={handleEditAddressModal}
        className="bg-transparent  shadow-none"
      >
        <Card className="mx-auto relative overflow-scroll lg:overflow-hidden  w-full ">
        <div onClick={()=>setEditAddressModal(!editAddressModal)} className="absolute cursor-pointer top-2 right-[10px] flex items-center justify-center px-2 py-2 w-[25px] h-[25px] rounded-full bg-gray-200" >
          <p className="text-[.7rem]" >X</p>
        </div>
          <CardBody className="grid lg:gap-[44px]  grid-cols-1  h-[500px] overflow-y-scroll  lg:overflow-hidden  lg:grid-cols-2 ">
          <div className="col-span-1 w-full" >
           <div className="hidden lg:block " >
        
          {
            isLoaded &&
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={15}
     
  
      center={{lat:geometry.lat,lng:geometry.lng}}
      onLoad={onLoad}
    >
      <Marker
        position={{lat:geometry.lat,lng:geometry.lng}}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
    </GoogleMap>
  
          }
            
          </div>
            <div className="lg:hidden block " >
        
          {
            isLoaded &&
    <GoogleMap
      mapContainerStyle={mapStylesTwo}
      zoom={15}
     
  
      center={{lat:geometry.lat,lng:geometry.lng}}
      onLoad={onLoad}
    >
      <Marker
        position={{lat:geometry.lat,lng:geometry.lng}}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
    </GoogleMap>
  
          }
            
          </div>
          
            
          </div>
          { activeSection === "add address" ?  (<div className="lg:mx-12 md:mx-0 mx-0 mt-3 md:mt-6" >
              <h4 className="text-[16px] text-[#686868] font-[InterMedium] uppercase" >Select Location</h4>
              <div className="my-3 flex items-center justify-start gap-4 " >
              <div>
                <MapPinIcon className="w-[20px] text-blue-500" />
              </div>
                <div>
                  <p className="text-[#0D1F37] font-[InterBold] lg:text-[18px] " >{singleAddress.longAddress.slice(0,25)}...</p>
                 
                </div>
                <div>
                  <button onClick={()=>setActiveSection("change address")} className="bg-gray-100 rounded-lg text-[.7rem] px-6 py-2 font-[500] text-blue-500" >Change</button>
                </div>
              </div>
              <div className="my-6 flex items-center justify-center flex-col gap-2 lg:gap-4 " >
              <div className="lg:w-[450px] w-full" >
              <Input error={nameError ? true : false} value={singleAddress.name} onChange={(e)=>{
                setSingleAddress({...singleAddress,name:e.target.value})
                setNameChange(true)
              }}  label="Name" />
              <p className="text-red-600 mt-2 text-[.8rem]" >{nameError && "Enter a Valid Name"}</p>
              </div>
              <div className="lg:w-[450px] w-full" >
              <Input error={locationError ? true : false} value={singleAddress.location} onChange={(e)=>{
                setSingleAddress({...singleAddress,location:e.target.value})
                setLocationChange(true)
              }}  label="Apartment / Building / Area" />
               <p className="text-red-600 mt-2 text-[.8rem]" >{locationError && "Enter a Valid Location"}</p>
               </div>
               <div className="lg:w-[450px] w-full">
              <Input  value={singleAddress.landmark} onChange={(e)=>{
                setSingleAddress({...singleAddress,landmark:e.target.value})
                setLandmarkChange(true)
              }}  label="Landmark(optional)" />
           
              </div>
              <div className="lg:w-[450px] w-full">
                <Input error={contactError ? true : false} type="number" value={singleAddress.alternameNumber} onChange={(e)=>{
                setSingleAddress({...singleAddress,alternameNumber:e.target.value})
                setContactChange(true)
              }}  label="Contact Number" />
                 <p className="text-red-600 mt-2 text-[.8rem]" >{contactError && "Enter a Valid Contact Number"}</p>
                 </div>
              </div>
              <div className="flex items-start justify-start gap-4 md:gap-6" >
           
              <button onClick={()=>{
                updateAddress(singleAddress?.id)
                handleEditAddressModal()
              }} className="bg-[#2967B0] font-[InterMedium] px-4 w-[150px] text-[12px]  md:w-[242px] lg:px-8 font-[500]  py-3 rounded-lg text-white" >Save and Proceed</button>
              </div>
            </div>):(
                <div className="lg:mx-12 md:mx-0 mx-0 mt-3 md:mt-6">
                <div className="py-4 " >
                  <h4 className="font-[700] text-black text-[1.8rem]" >Help us locate you</h4>
                  <p className="my-1 text-gray-600" >This will ensure accurate choice of services</p>
                  <div className="my-2" >
                    
                   <PlaceAutocomplete geometry={geometry} setGeometry={setGeometry} handleLocationData={handleLocationData} address={singleAddress} setAddress={setSingleAddress} setLocationData={setLocationData} locationData={locationData}  />
                  </div>
                  <p onClick={()=>setActiveSection("add address")} className="text-blue-600 cursor-pointer flex items-center justify-start gap-4 my-4 font-[500]" ><MapIcon className=" w-[30px]"  /> Use current location using GPS</p>
                  </div>
                
                  <div className="flex items-center justify-end gap-4" >
                   <button onClick={()=>setActiveSection("add address")} className="bg-gray-400  w-[120px] font-[InterMedium] text-[.8rem]  md:w-[250px] px-4 lg:px-8 font-[500]  py-3 rounded-lg text-white" >Cancel</button>
                    <button onClick={()=>{
                setActiveSection("add address")
                handleEditLocationData(geometry.lat,geometry.lng)
                }} className="bg-[#2967B0] px-4 w-[full] text-[12px] font-[InterMedium]  md:w-[250px] lg:px-8 font-[500]  py-3 rounded-lg text-white" >Save and Proceed</button>
                  </div>
                </div>
            )}
            
              
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default EditAddressModel