import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import "swiper/css";

import { BrandBanner } from './BrandBanner';

const Brands = ({brandImages}) => {
    const images = brandImages?.slice(0,7)?.map((image) => ({
  id: crypto.randomUUID(),
  image
}));
   const imagesTwo = brandImages?.slice(7,14)?.map((image) => ({
  id: crypto.randomUUID(),
  image
}));
  return (
    <div  className='bg-[#FFFEEC] font-[Inter] lg:grid grid-cols-5 grid-flow-col items-start justify-start  xl:pl-[165px] lg:px-[60px] xl:pr-0 px-[0px] md:px-[30px] py-8 xl:py-[60px]'> 

    <div className=' col-span-2 md:w-[260px] px-[40px] md:px-0 my-0 xl:pt-0 ' >
            <h3  className='lg:text-[3rem] text-[1.5rem] leading-[3.5rem] text-left text-[#2967B0] font-[InterBlack]    ' >Brands we work for</h3>
            <p className='my-2 font-[InterMedium] text-[#5B5B5B]' >All-Round Protection for Your Beloved Devices</p>
        </div>
        <div className='col-span-3  ' >
        <BrandBanner images={images} speed={12000} />
        <div className='mt-2' > 
       <BrandBanner images={imagesTwo} speed={12000} />
       </div>
        </div>
        </div>
  )
}

export default Brands