import React from 'react'
import OntheSpotPlan from "../../asset/ots.svg"
import QuickFix from "../../asset/qf.svg"
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
const SelectPlan = ({plan,setPlan,handleGuildlinesOpenModal,stage,setStage,repairPacks,handleDetailsModal,geometry,address}) => {
  return (
    <div>
        <div className='xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:pt-[30px]' >
        <div className='flex items-start gap-6 md:items-center flex-col-reverse md:flex-row justify-start md:justify-between' >
        <div>
            <h3 className='md:text-[24px] font-[InterBold] text-[1.5rem]' >Pick Your Perfect Plan</h3>
            <p onClick={handleDetailsModal} className='text-[#59A4FF] font-[InterMedium] cursor-pointer ' >Learn more about our Plans</p>
             </div>
             
                 </div>
            <div className='pt-[45px] flex items-center flex-wrap justify-start gap-[24px] ' >
            <div>
                <div onClick={()=>{
                  setPlan(repairPacks[0])
                  handleGuildlinesOpenModal()
                  }} className='cursor-pointer w-full md:w-[300px] lg:w-full ' >
                <div className='mb-[16px] ' >
                    <img src={QuickFix} className='  ' alt="" />
                     </div>
                     <p className='text-[18px]  font-[InterBold]' >{repairPacks[0]?.packName}</p>
                     <p className='text-[15px] text-[#2967B0] font-[InterMedium]' >₹{repairPacks[0]?.planCost} Plan Cost </p>
                     <p className='text-[#686868] text-[14px] font-[InterRegular] ' >{repairPacks[0]?.shortDesc}</p>
                </div>
                <p onClick={handleDetailsModal}  className='text-blue-600 cursor-pointer font-[InterMedium] text-[12px] ' >View Details</p>
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default SelectPlan