import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import BannerOne from "../../asset/homeBanners/banner1.png"
import BannerTwo from "../../asset/homeBanners/banner2.png"
import BannerThree from "../../asset/homeBanners/banner3.png"
import HomeBanner from './HomeBanner';
import { Link } from 'react-router-dom';
import AddRepair from './AddRepair';
const HomeCarousel = ({repairProducts,shieldProducts,device,setDevice,deviceData,brands,brand,setBrand, mobileBrandList,models,model,setModel,handleAddRepair}) => {
    const data=[
      {
            image:BannerThree,
        },
        {
            image:BannerOne,
        },
        {
            image:BannerTwo,
        },
        
    ]
  return (
    <div  >
    <div className='relative' >
           <Swiper
          spaceBetween={0}
          className=""
          draggable={true}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction:false,
          }}
          loop={true}
          modules={[Autoplay]}
         
        >
            {
                data.map((banner,key)=>(
                     <SwiperSlide>
                     <Link to="/repair" >
                    <HomeBanner  banner={banner} />
                    </Link>
                    </SwiperSlide>
                ))
            }
        </Swiper>
        <AddRepair handleAddRepair={handleAddRepair} deviceData={deviceData} models={models} model={model} setModel={setModel} brands={brands} brand={brand} setBrand={setBrand} device={device} setDevice={setDevice} />
        </div>
    </div>
  )
}

export default HomeCarousel