import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ExtendedWarrenty from "../../asset/feature23.png"
import Accidental from "../../asset/feature2.png"
import Warrenty from "../../asset/warrenty.png"
import Care from "../../asset/care.png"
const ServiceDetailsModal = ({handleDetailsModal,detailsModal,setDetailsModal,stage,setStage,service}) => {
  return (
     <div>
      <Dialog
        size="lg"
        open={detailsModal}
        handler={handleDetailsModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto lg:grid px-2 lg:px-4 py-3 lg:py-6   w-full ">
          <CardBody className="flex flex-col gap-4">
          <div className="flex items-center justify-start" >
            {service==="Zippy X-Tended" && <img className="md:w-[60px] w-[35px]  mr-6" src={ExtendedWarrenty} />}
             {service==="Zippy Care" && <img className="md:w-[60px] w-[35px]  mr-6" src={Accidental} />}
            <div>
              { service==="Zippy X-Tended" &&  <h4 className="font-[600] text-black text-[1.3rem] lg:text-[2rem]">
              Extended Warranty
            </h4>}
             { service==="Zippy Care" &&  <h4 className="font-[600] text-black text-[1.3rem] lg:text-[2rem]">
             Accidental & Liquid Damage
            </h4>}
            </div>
          </div>
           <div className="mt-3" >
            <div className="border-b-[1px] flex items-start justify-start lg:gap-6 border-gray-200 py-4" >
            <div>
                <img className="hidden lg:block" src={Warrenty} alt="" />
            </div>
            <div>
                <h4 className="font-[600] text-[1.2rem]" >Extended Warrenty</h4>
                <p className="lg:text-[1rem] text-[.7rem]" >(Device purchased within 6 months) issue and if the device requires additional or different service, then the cost may differ.</p>
                </div>
            </div>
            <div className=" py-4 flex items-start justify-start lg:gap-6"  >
            <div>
                <img  className="hidden lg:block" src={Care} alt="" />
            </div>
            <div>
                <h4 className="font-[600] text-[1.2rem]" >Care+</h4>
                <p className="lg:text-[1rem] text-[.7rem]">(Screen protection, Accidental & Liquid Damage) Adding genuine issue will help you and us find the real solution, save time and cost.</p>
                  </div>
            </div>
            <div className="mt-4" >
                <button onClick={()=>{
                    handleDetailsModal()
                    
                }} className="bg-[#2967B0] px-12 py-3 text-white rounded-lg font-[500] " >Select Service</button>
            </div>
           </div>
           
           
            
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default ServiceDetailsModal