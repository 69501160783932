import React,{useState} from 'react'
import PhoneLogin from "../../asset/phoneLogin.png"
import Google from "../../asset/google.png"
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
  onSnapshot
} from "firebase/firestore";
import { InformationCircleIcon, EyeIcon,EyeSlashIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { db } from '../../firebase.config'
import { loginUsingEmail } from '../../actions/userAction';
import {useDispatch,useSelector} from "react-redux"
import {Link, useNavigate} from "react-router-dom"
const EmailLogin = ({handleOpen,active,setActive,activeLogin,setActiveLogin,handleGoogleLogin}) => {
  const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
     const [emailError, setEmailError] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false)
 
    const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch()
      const navigate = useNavigate()

      

  const handleLogin = async ()=>{
    const userRef = collection(db, "user");
    const emailQuery = query(userRef, where("email", "==", email));
    const getEmailInfo = await getDocs(emailQuery);
      var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
     
    if(email.length!==0 && getEmailInfo.docs.length===0 ){
      setEmailError(true)
      setEmailErrorMessage("Email Does Not Present")
    }
    else if(email.length=== 0  || emailRegex.test(email)===false){
     
      setEmailError(true)
      setEmailErrorMessage("Enter a Valid Email")
    }
    
    else if(password===""  ){
      alert("Enter Valid Password")
      setPasswordError(true)
      setPasswordErrorMessage("Enter a Valid Password")
    }
    else{
     dispatch(loginUsingEmail(email,password))
    if(!error){
      setEmail('')
      setPassword('')
      handleOpen()
    }
    else if (error){
      alert(error)
    }
    }
   
    
  }
  return (
    <div className="col-span-1">
           <CardBody className="flex flex-col gap-4">
            <Typography className='font-[InterBold]' variant="h4" color="blue-gray">
             Welcome to <span className="text-[#2967B0] italic " >Zepair</span> 
            </Typography>
            <Typography
              className="mb-2 font-[InterMedium] text-[#0D1F37] "
              variant="paragraph"
              color="gray"
            >
              Login or  <span className="font-[600]" > Get Started</span>
            </Typography>
            
             <Input type='email' className='active:outline-none'  value={email} error={emailError ? true : false }  onChange={(e)=>{setEmail(e.target.value)}} label="Email" size="lg" />
           {emailError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {emailErrorMessage && emailErrorMessage}</p>}
         
             <div className="relative flex w-full ">
      <Input
        
        label="Password"
        value={password} 
        error={passwordError ? true : false }
        onChange={(e)=>{setPassword(e.target.value)}}
        type={showPassword ? "text" : "password"}
        className="pr-20"
        containerProps={{
          className: "min-w-0",
        }}
      />
      <button
        
       
        disabled={!password}
        onClick={()=>setShowPassword(!showPassword)}
        className="!absolute  right-2 top-3 "
      >
        {showPassword ? <EyeIcon className='w-[15px]  text-blue-500' /> :<EyeSlashIcon className='w-[15px] text-blue-500' />}
      </button>
      
    </div>
    {passwordError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {passwordErrorMessage && passwordErrorMessage}</p>}
            
             <button onClick={handleLogin}  className="bg-[#2967B0] font-[600] text-white py-3 rounded-lg " >
              Login
            </button>
            <Typography variant="small" className="mt-4 flex justify-center">
              Don&apos;t have an account?
              <Typography
                as="p"
              
                variant="small"
                color="blue-gray"
                className="ml-1 font-bold cursor-pointer  "
                onClick={()=>setActiveLogin("signup")}
              >
                Sign up
              </Typography>
            </Typography>
            
          </CardBody>
          <CardFooter className="pt-0 border-t-[1px] border-gray-300 mx-6  ">
            <div className="flex mt-3 gap-4 items-center justify-start" >
            
      
            
            <div onClick={()=>setActive("phone")} className="px-2 py-2 w-[50px] h-[50px] flex items-center justify-center cursor-pointer   border-[1px] border-gray-300 rounded-full">
            <PhoneIcon  src={PhoneLogin} alt=""  />
            </div>
            <div onClick={handleGoogleLogin}  className="px-2 py-2 w-[50px] h-[50px] flex items-center justify-center cursor-pointer   border-[1px] border-gray-300 rounded-full">
            <img  src={Google} alt="" />
            </div>
            </div>
            <p className="mt-3 text-[.8rem] font-[InterRegular] " >By continuing, you agree to Zippy’s <Link to="/termsandcondition" className='underline' > Terms of Use.</Link> Read our <Link to="/privacy-policy" className='underline' > Privacy Policy</Link></p>
          </CardFooter>
        </div>
  )
}

export default EmailLogin