import React,{useState,useEffect} from 'react'
import DeviceBanner from '../Components/Repair/DeviceBanner'
import SelectManufacturer from '../Components/Repair/SelectManufacturer'
import { useSelector,useDispatch } from 'react-redux'
import { saveRepairDevice } from '../actions/repairActions'
import { onValue, ref } from 'firebase/database'
import { database } from '../firebase.config'
import Navbar from '../Components/Layout/Navbar'
import StepsToRepair from '../Components/Repair/StepsToRepair'
import Faqs from '../Components/Home/Faqs'
import Footer from '../Components/Layout/Footer'
import DownloadApp from '../Components/Home/DownloadApp'
import BelowCTA from '../Components/Home/BelowCTA'
const RepairManufacturer = () => {
   const [manufacturers, setManufacturers] = useState([])
   const [manufacturer, setManufacturer] = useState({})
    const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
 
   const { deviceInfo } = useSelector(
    (state) => state.modals
  );
  const dispatch = useDispatch();
   const [device, setDevice] = useState(deviceInfo?.deviceData?.deviceType)
   const handleGetManufacturer = async () =>{
        if(device==="mobile_brands"){
             const repairCountRef =  await ref(database, 'repair/mobileRepair');

     onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setManufacturers(newProducts)
       })
        }
        else if(device==="laptop_brands"){
              const repairCountRef = await ref(database, 'repair/mainLaptopRepair');

      onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setManufacturers(newProducts)
       })
        }
        else if(device==="tablet_brands"){
              const repairCountRef =  await ref(database, 'repair/laptopRepair');

     onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
         const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setManufacturers(newProducts)
       })
        }
        else{
            alert("Please Select a Device First")
        }
   }
   const handleRepairInfo = (manufacturerName) => {
    dispatch(
      saveRepairDevice({
        
        // problemList : issueList && issueList,
        // problemDesc : issueDescription,
        // recordedProblems : audioDetails,
        deviceData: {
          deviceType: device,
          brandName : manufacturerName?.brand,
          manufacturerDetails:manufacturerName
          // modelName : model,
          // deviceColor  : color,
          // devicePurchaseDate : "",
          // imeiNo : "",
          // devicePrice : "",
          // purchaseBill : ""
        },
        // packData : {
        //   warrantyType : "",
        //   packName : plan?.packName,
        //   serviceCost : "",
        //   PackCost : plan?.planCost,
        //   packValidity : "",
        //   PackId : plan?.id
        // },
        // pickUpData : {
        //   geoPoint: {
        //     latitude: address.latitude,
        //     longitude: address.longitude
        //   },
        //   addLine1 : address.location,
        //   addLine2 : address.addressTwo,
        //   addLine3 :address.landmark,
        //   bookingTimeDate : day + "/" + monthYear + " - " + time + ":00"
        // },
      })
    );
  };
   
      useEffect(() => {
       
         
           
      
        if(device.length!==0){
             handleGetManufacturer()
        }
          
      }, [deviceInfo,device])
  return (
    <div className=" font-[Inter] ">
    <Navbar />
    <div className='lg:mt-[80px] mt-[100px] md:mt-[120px]' >
        <DeviceBanner device={device} setDevice={setDevice}   />
        <SelectManufacturer handleRepairInfo={handleRepairInfo} manufacturer={manufacturer} setManufacturer={setManufacturer} manufacturers={manufacturers} />
          <StepsToRepair />
        
        <DownloadApp />
        <BelowCTA />
        {/* <RepairProducts repairProductsList={repairProductsList && repairProductsList} />
        <StepsToRepair />
        <Faqs /> */}
         <Footer bg="#0D1F37" />
         </div>
      </div>
     

  )
}

export default RepairManufacturer