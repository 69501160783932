import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const OrderingContent = () => {
  return (
    <div>
      <Disclosure className="">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full my-6 font-[InterMedium] justify-between  bg-white border-b-[1px] border-[#ececec] px-3  xl:px-6 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <span className="font-[InterMedium] font-[600] text-[1.1rem]">
                ORDERING:
              </span>
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-6 w-6 text-gray-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-6 pt-1 font-[InterMedium] pb-0 text-[1rem] ">
              <Disclosure className="mt-[-15px]">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full mt-[-10px]  font-[InterMedium] justify-between  bg-white  px-3  xl:px-2 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span className="font-[InterMedium] text-[.9rem] ">
                        RESCHEDULE ORDER:
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 rounded-lg mt-[-10px] bg-gray-100  my-2  py-4 font-[InterMedium]  text-[.8rem] text-gray-600">
                      <p>
                        <span>
                          {" "}
                          At Zippy, we understand that plans can change, and we
                          strive to accommodate your needs. If you find yourself
                          in a situation where you need to reschedule or cancel
                          your appointment, we've streamlined the process for
                          your convenience.
                        </span>
                        <br />
                        <br />
                        <span className="font-[InterMedium] text-[.9rem]">
                          {" "}
                          Rescheduling Your Appointment:
                        </span>{" "}
                        <br />
                        You have the flexibility to reschedule your appointment
                        up to 3 hours before the scheduled visit. To make
                        adjustments to your appointment time, please visit our
                        website or use our user-friendly app. Our rescheduling
                        policy is designed to offer you the most hassle-free
                        experience possible.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure className="mt-[-15px]">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full mt-[-10px]  font-[InterMedium] justify-between  bg-white  px-3  xl:px-2 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span className="font-[InterMedium] text-[.9rem] ">
                        CANCEL ORDER:
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 rounded-lg mt-[-10px] bg-gray-100  my-2  py-4 font-[InterMedium]  text-[.8rem] text-gray-600">
                      <p>
                        At Zippy, we understand that plans can change, and we
                        strive to accommodate your needs. If you find yourself
                        in a situation where you need to reschedule or cancel
                        your appointment, we've streamlined the process for your
                        convenience. <br />
                        <br /> In the event that you need to cancel your
                        appointment, we also allow cancellations up to 3 hours
                        before the scheduled visit. We encourage you to review
                        the details of our cancellation policy on our website or
                        app for a comprehensive understanding of the process.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure className="mt-[-15px]">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full mt-[-10px]  font-[InterMedium] justify-between  bg-white  px-3  xl:px-2 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span className="font-[InterMedium] text-[.9rem] ">
                        CASHBACK OR GIFT NOT RECEIVED:
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 rounded-lg mt-[-10px] bg-gray-100  my-2  py-4 font-[InterMedium]  text-[.8rem] text-gray-600">
                      <p>
                        If you find yourself in a situation where your expected
                        cashback or free gift has not been received, worry not –
                        we're here to assist you promptly. Follow the steps
                        below for a swift resolution: <br />
                        <br />{" "}
                        <span className="font-[600] text-[.9rem]">
                          {" "}
                          Contact Our Customer Support on WhatsApp:
                        </span>{" "}
                        <br />
                        Connect with our dedicated customer support team on
                        WhatsApp to report the issue. Our experienced
                        representatives are ready to assist you in resolving the
                        matter. We prioritize your satisfaction and aim to
                        address concerns related to cashback or free gift
                        discrepancies swiftly.
                        <br />
                        <br />{" "}
                        <span className="font-[600] text-[.9rem]">
                          Expect a Resolution Within 48 Hours:
                        </span>{" "}
                        <br />
                        Once you've reached out to our support team, rest
                        assured that we're committed to resolving the issue
                        promptly. Our team will diligently investigate the
                        matter and work towards a solution within 48 hours.
                        <br />
                        <br />
                        <span className="font-[600] text-[.9rem]">
                          {" "}
                          How to Reach Our Customer Support on WhatsApp:
                        </span>{" "}
                        <br />•{" "}
                        <span className="font-[600] text-[.9rem]">
                          Save our Customer Support Number:
                        </span>{" "}
                        Save our WhatsApp customer support number in your
                        contacts for easy access.
                        <br />
                        <span className="font-[600] text-[.9rem]">
                          {" "}
                          • Initiate a Chat:
                        </span>{" "}
                        Open WhatsApp and initiate a chat with our customer
                        support team.
                        <br />
                        <span className="font-[600] text-[.9rem]">
                          {" "}
                          • Provide Relevant Details:
                        </span>{" "}
                        Clearly communicate the details of the issue, including
                        your order number, the specific cashback or free gift in
                        question, and any other pertinent information.
                        <br /> •
                        <span className="font-[600] text-[.9rem]">
                          {" "}
                          Await Resolution:
                        </span>{" "}
                        Our team will work diligently to investigate and resolve
                        the matter. You can expect an update within 48 hours.
                        <br />
                        <br />
                        <span className="font-[600] text-[.9rem]">
                          {" "}
                          Your Satisfaction is Our Priority:
                        </span>{" "}
                        <br />
                        At Zippy, we value your trust and strive to ensure that
                        you have a positive experience. If you encounter any
                        challenges related to cashback or free gift issues, our
                        customer support team is dedicated to providing a timely
                        and satisfactory resolution. For any further assistance
                        or inquiries, please feel free to reach out through our
                        WhatsApp support or explore additional support options
                        available on our website. We appreciate your
                        understanding and look forward to resolving this matter
                        for you swiftly.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default OrderingContent;
