import React, { useState } from 'react'
import MobileRepair from "../../asset/mobileDevice.png"
import TabRepair from "../../asset/tablet.png"
import LaptopRepair from "../../asset/laptop.png"
import { ArrowLeftCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
const DeviceBanner = ({device,setDevice,}) => {
    const [active, setActive] = useState(device)
    const activeClass = "lg:w-[80px] bg-white flex items-center justify-center w-[60px] lg:px-4 px-2 lg:py-3 py-2 border-blue-400 rounded-md lg:rounded-lg border-[1px]"
    const nonActiveClass = "lg:w-[80px] w-[60px] bg-[#F1F1F1] flex items-center justify-center lg:px-4 px-2 lg:py-3 py-2 rounded-sm lg:rounded-lg"
  return (
    <div className=" bg-gradient-to-r from-[#2968b00f] to-[#f6eb1523]  xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:py-[30px]">
    <div className='flex items-center justify-between' >
     <h4 className='lg:text-[2rem] font-[InterBold] text-[1.5rem] font-[600]' >Repair Your Device</h4>
        
    </div>
       
        <div className='lg:mt-6 mt-2' >
                    <h4 className='font-[InterMedium] text-[#686868]' >Selected Device:</h4>
            <div  className='flex mt-6  items-center justify-start gap-8 ' >
                <div onClick={()=>{
                setDevice("mobile_brands")
                setActive("mobile")
               
            }} className='flex cursor-pointer items-center justify-center flex-col' >
            <div className={device === "mobile_brands" ? activeClass : nonActiveClass }>
                    <img  src={MobileRepair} alt="" />
                    </div>
                    <h4 className='mt-2 lg:text-[15px] font-[InterMedium] text-[.7rem] text-[#686868] ' >Mobile</h4>
                </div>
                 <div onClick={()=>{
                setDevice("tablet_brands")
                setActive("tablet")
                
            }} className='flex cursor-pointer items-center justify-center flex-col' >
            <div className={device === "tablet_brands" ? activeClass : nonActiveClass }>
                    <img  src={TabRepair} alt="" />
                    </div>
                    <h4 className='mt-2 lg:text-[15px] font-[InterMedium] text-[.7rem] text-[#686868]'>Tablet</h4>
                </div>
                 <div onClick={()=>{
                setDevice("laptop_brands")
                setActive("laptop")
                
            }} className='flex cursor-pointer items-center justify-center flex-col' >
            <div className={device === "laptop_brands" ? activeClass : nonActiveClass }>
                    <img  src={LaptopRepair} alt="" />
                    </div>
                    <h4 className='mt-2 lg:text-[15px] font-[InterMedium] text-[.7rem] text-[#686868]'>Laptop</h4>
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default DeviceBanner