import { Modals_REQUEST,Modals_SUCCESS,Modal_FAIL, SAVE_DEVICE_INFO,ORDER_FAIL,ORDER_REQUEST,ORDER_SUCCESS, CLEAR_ORDER, Empty_DEVICE_INFO } from "../contants/repairConstants";
import { CLEAR_ERRORS } from "../contants/userConstants";
export const modalsReducer = (state = {deviceInfo:{} }, action) =>{
    switch (action.type) {
    
   
      case SAVE_DEVICE_INFO:
      return {
        ...state,
        deviceInfo:action.payload,
      };
      case Empty_DEVICE_INFO:
      return {
        ...state,
        deviceInfo:{},
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}

export const repairOrderReducer = (state = {repairOrder:{} }, action) => {
   switch (action.type) {
    case ORDER_REQUEST:
      return {
        loading: true,
        isOrder: false,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
       loading: false,
        isOrder: false,
        repairOrder: action.payload,
      };
    
    case ORDER_FAIL:
      return {
        ...state,
       loading: false,
        isOrder: false,
        repairOrder: null,
        error: action.payload,
      };
      case CLEAR_ORDER:
        return {
            repairOrder:{}
        }
   
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
