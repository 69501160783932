import React from "react";
import About from "../../asset/aboutMain.svg";
import AboutMobile from "../../asset/aboutBannerMobile.svg";
const AboutBanner = () => {
  return (
    <div
      className="flex flex-col-reverse lg:flex-row bg-[#191617] py-12 px-8   lg:py-24 items-center lg:justify-center" >
    <img className="md:block hidden" src={About} alt="" />
    <img className="block md:hidden" src={AboutMobile} alt="" />
    </div>
  );
};

export default AboutBanner;
