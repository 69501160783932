import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import LocateIcon from "../../asset/locateIcon.svg"
import ChooseLocationImage from "../../asset/chooseLocationImage.svg"
import FindLocation from "./FindLocation"
const ChooseLocation = ({openLocation,handleOpenLocation,geometry,setGeometry,currentLocation,handleLocationData,handleAddressOpenModal,allowAccess}) => {
  return (
      <div>
      <Dialog
        size="xl"
        open={openLocation}
        handler={handleOpenLocation}
        className="bg-transparent font-[Inter] outline-none shadow-none"
      >
        <Card className="mx-auto relative outline-none grid py-2 lg:px-4  lg:py-4   w-full ">
        <div onClick={handleOpenLocation} className="absolute cursor-pointer top-2 right-[10px] flex items-center justify-center px-2 py-2 w-[25px] h-[25px] rounded-full bg-gray-200" >
          <p className="text-[.7rem]" >X</p>
        </div>
          <CardBody className=" grid grid-cols-2 grid-flow-col  gap-4">
          
            <div>
                <img src={ChooseLocationImage} alt="" />
            </div>
            <div className="px-8 py-0" >
                <h3 className=" text-[28px] font-[InterBold] text-[#0D1F37]" >Help us locate you!</h3>
                <p className="font-[400] mt-0 text-[16px] font-[Inter] text-[#686868]">This will ensure accurate choice of services</p>
                <div className="mt-4" >
                <FindLocation handleAddressOpenModal={handleAddressOpenModal} handleLocationData={handleLocationData} geometry={geometry} setGeometry={setGeometry} handleOpenLocation={handleOpenLocation}  />
                <div className="flex items-center justify-start gap-2" >
                <img className="w-[15px] mt-3" src={LocateIcon} alt="" />
                <p onClick={()=>{
                    currentLocation()
                    handleOpenLocation()
                    handleAddressOpenModal()
                }} className="mt-3 cursor-pointer font-[InterMedium] text-[#2967B0]" >Use current location using GPS</p>
                
                </div>
                {allowAccess && <p className="font-[InterRegular] my-3 text-red-600" >Please Allow Location in the Browser.</p>}
                 </div>
            </div>
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default ChooseLocation