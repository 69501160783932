import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import ShippingContent from "./ShippingContent";
import OrderingContent from "./OrderingContent";
import Faq from "./Faq";
import ContactInformation from "./ContactInformation";
const Help = () => {
  const [activeSection, setActiveSection] = useState("help");
  const hubCenterContent = [
    {
      title: "1. Getting Started:",
      content: [
        "How to Book a Repair: Step-by-step guide on scheduling your gadget repair at home.",
        "Account Setup: Assistance with creating and managing your Zippy account.",
      ],
    },
    {
      title: "2. Services & Repairs:",
      content: [
        "Service Offerings: Details on the various gadget repair services provided by Zippy.",
        "Repair Process: Understanding what happens during the repair, from booking to completion.",
      ],
    },
    {
      title: "3. Payments & Discounts:",
      content: [
        "Payment Options: Information on the payment methods accepted by Zippy.",
        "Discounts & Offers: Exploring ongoing promotions and how to redeem them.",
      ],
    },
    {
      title: "4. Booking & Appointments:",
      content: [
        "Booking a Repair: Troubleshooting tips for common issues encountered during the booking process.",
        "Rescheduling & Cancellations: Guidelines for adjusting your appointment as needed.",
      ],
    },
    {
      title: "5. Warranty & Returns:",
      content: [
        "Warranty Policy: Explanation of Zippy's warranty coverage for repair parts.",
        "Returns & Refunds: Understanding the process for returns and refunds.",
      ],
    },
    {
      title: "6. COVID-19 Safety Measures:",
      content: [
        "Safety Protocols: Information on the safety measures taken by Zippy during the COVID-19 pandemic.",
      ],
    },
    {
      title: "7. Account & Security:",
      content: [
        "Account Security: Tips on keeping your Zippy account secure.",
        "Forgotten Password: Assistance for recovering a forgotten password.",
      ],
    },
    {
      title: "8. Technical Support:",
      content: [
        "Technical Issues: Troubleshooting common technical problems with the Zippy platform.",
        "Contacting Support: How to reach out to our customer support team for assistance.",
      ],
    },
    {
      title: "9. Feedback & Suggestions:",
      content: [
        "Submit Feedback: Guidelines on providing feedback or suggestions to help us improve our services.",
      ],
    },
    {
      title: "10. General FAQs:",
      content: [
        "Common Questions: Answers to frequently asked questions about Zippy and our services.",
      ],
    },
  ];
  const shippingData = [
    {
      title: "Packaging",
      content: "",
    },
  ];
  return (
    <div className="font-[InterMedium]">
      <div className="bg-white my-4 mx-3 rounded-lg px-4 py-3">
        <div
          onClick={() => setActiveSection("help")}
          className="text-[#2967B0] px-2 flex cursor-pointer items-center justify-start py-4 border-b-[1px] border-gray-300"
        >
          <QuestionMarkCircleIcon className="w-[25px] mr-6" />
          <h4>Help Centre</h4>
        </div>
        <div
          onClick={() => setActiveSection("contact")}
          className="text-[#2967B0] px-2 cursor-pointer flex items-center justify-start py-4 border-b-[1px] border-gray-300"
        >
          <PhoneIcon className="w-[25px] mr-6" />
          <h4>Contact us</h4>
        </div>
        <div
          onClick={() => setActiveSection("faq")}
          className="text-[#2967B0] px-2 flex cursor-pointer items-center justify-start py-4 border-b-[1px] border-gray-300"
        >
          <InformationCircleIcon className="w-[25px] mr-6" />
          <h4>FAQ</h4>
        </div>
      </div>
      {activeSection === "help" && (
        <div className="bg-white my-4 mx-3 rounded-lg px-4 py-4">
          <h4 className="font-[InterBold] text-[1.3rem]">All Topics</h4>
          <div>
            <Disclosure className="">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full my-6 font-[InterMedium] justify-between  bg-white border-b-[1px] border-[#ececec] px-3  xl:px-6 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                    <span className="font-[InterMedium] text-[1.1rem]">
                      Welcome to Zippy's Help Center - Your Hub for Hassle-Free
                      Solutions!
                    </span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-6 pt-1 font-[InterMedium] pb-0 text-[1rem] ">
                    {hubCenterContent.map((content) => (
                      <Disclosure className="mt-[-15px]">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full mt-[-10px]  font-[InterMedium] justify-between  bg-white  px-3  xl:px-2 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                              <span className="font-[InterMedium] text-[.9rem] ">
                                {content.title}
                              </span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-6 w-6 text-gray-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 rounded-lg mt-[-10px] bg-gray-100  my-2  py-4 font-[InterMedium]  text-[.8rem] text-gray-600">
                              {content.content.map((item) => (
                                <p className="my-1">{item}</p>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <ShippingContent />
            <Disclosure className="font-[InterMedium]">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full my-6 font-[InterMedium] justify-between  bg-white border-b-[1px] border-[#ececec] px-3  xl:px-6 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                    <span className="font-[InterMedium] font-[600] text-[1.1rem]">
                      PAYMENT:
                    </span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-6 pt-1 font-[InterMedium] pb-0 text-[1rem] ">
                    <Disclosure className="mt-[-15px]">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full mt-[-10px]  font-[Neue Haas Grotesk Display Pro] justify-between  bg-white  px-3  xl:px-2 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <span className="font-[InterMedium] text-[.9rem] ">
                              PAY ON DELIVERY:
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-6 w-6 text-gray-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 rounded-lg mt-[-10px] bg-gray-100  my-2  py-4 font-[InterMedium]  text-[.8rem] text-gray-600">
                            <p>
                              At Zippy, we offer Pay on Delivery (POD) options,
                              encompassing both Cash on Delivery (COD) and
                              additional digital payment facilities such as UPI
                              and QR Code Scan & Pay. <br /> Pay on Delivery is
                              applicable as a payment method for all items that
                              are Fulfilled by Zippy.For Pay on Delivery orders,
                              you can make payments in cash or opt for digital
                              payment methods like UPI and QR Code Scan & Pay.
                              <br />
                              <br />
                              <span className="font-[InterMedium] text-[.9rem]">
                                {" "}
                                Simply request your Delivery Associate to show
                                you the Scan code for the payment To pay using
                                UPI QR code:
                              </span>{" "}
                              <br /> • Maintain a safe distance of 6 feet / 2
                              meters between yourself and the Delivery
                              Associate.
                              <br /> • Ask the delivery agent to provide a UPI
                              QR code
                              <br /> • Scan to complete the payment. • After a
                              successful payment, you'll receive a confirmation
                              message.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full my-6 font-[InterMedium] justify-between  bg-white border-b-[1px] border-[#ececec] px-3  xl:px-6 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                    <span className="font-[InterMedium] font-[600] text-[1.1rem]">
                      Warranty Policy:
                    </span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-6 bg-gray-100 py-4 rounded-lg font-[InterMedium]  text-[1rem] ">
                    • 6 months of warranty: The warranty period is variable
                    based on your part selection during the repair. In the case
                    of a genuine part repair, the warranty is for 6 months. In
                    the case of a standard part repair, the warranty is for 3
                    months.
                    <br />
                    • When it comes to the warranty for your repair, its
                    duration is contingent upon the specific components chosen
                    during the repair process. <br />
                    • Covers only out-of-warranty devices, i.e. devices that
                    have surpassed the OEM (company) warranty.
                    <br />
                    • There is no warranty applicable on Cosmetic Parts for
                    instance: Back Panels, Buttons of all kinds, Body of the
                    Mobile
                    <br />
                    • If authentic, genuine parts are utilized in the
                    restoration of your device, rest assured that you will
                    benefit from a robust and extended warranty period lasting a
                    commendable six months. <br />• This substantial warranty
                    duration underscores the confidence in the quality and
                    durability of the genuine components employed in the repair.
                    On the other hand, should the repair involve standard parts,
                    the warranty term is nonetheless respectable, offering a
                    duration of three months. <br />
                    • This dichotomy in warranty periods reflects the commitment
                    to providing customers with transparent and tailored
                    assurances based on the nature of the components
                    incorporated into the repair, ensuring that each client
                    receives an appropriate and fair warranty period
                    corresponding to the specificities of their device's
                    restoration.
                    <br />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <OrderingContent />
            <Disclosure className="">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full my-6 font-[InterMedium] justify-between  bg-white border-b-[1px] border-[#ececec] px-3  xl:px-6 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                    <span className="font-[InterMedium] font-[600] text-[1.1rem]">
                      CONTACT US:
                    </span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-6 bg-gray-100 py-4 rounded-lg font-[InterMedium]  text-[1rem] ">
                    At Zippy, we are committed to providing exceptional customer
                    service to ensure your satisfaction. Should you need
                    assistance or have any inquiries, our dedicated customer
                    service team is here to help. <br />
                    <br />{" "}
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      Contacting Us on WhatsApp:{" "}
                    </span>
                    <br />
                    Feel free to reach out to us on WhatsApp for a quick and
                    convenient resolution. <br /> Our customer service hours are
                    from 9 am to 7 pm, providing you with a window to connect
                    with us during your convenience. <br /> <br />
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      How to Reach Us on WhatsApp:
                    </span>{" "}
                    <br />{" "}
                    <span className="font-[600] text-[.9rem]">
                      • Save Our WhatsApp Number:
                    </span>{" "}
                    Ensure you have our WhatsApp number saved in your contacts
                    for easy access. <br />
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      • Initiate a Chat:
                    </span>{" "}
                    Open WhatsApp and initiate a chat with our customer service
                    team. <br />
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      • Provide Details:
                    </span>{" "}
                    Clearly communicate your query or concern, including
                    relevant details such as your order number or any specific
                    information related to your inquiry.
                    <br />
                    <br />
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      Our Commitment to You:
                    </span>{" "}
                    <br />
                    <span className="font-[600] text-[.9rem]">
                      • Timely Assistance:
                    </span>{" "}
                    We understand the importance of timely assistance. Our team
                    is dedicated to responding promptly during our service
                    hours. <br />
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      • Problem Resolution:{" "}
                    </span>
                    Whether you have questions about our services, need help
                    with an order, or encounter any challenges, we are here to
                    assist you in finding a satisfactory resolution.
                    <br />
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      • Customer-Focused Support:
                    </span>{" "}
                    Your satisfaction is our priority. Our customer service team
                    is trained to provide courteous and effective support,
                    ensuring a positive experience.
                    <br /> <br />
                    <span className="font-[600] text-[.9rem]">
                      {" "}
                      Additional Support Options:
                    </span>{" "}
                    <br />
                    If WhatsApp is not convenient for you or if you have
                    inquiries outside our service hours, explore additional
                    support options available on our website's Help Center.{" "}
                    <br /> Here, you can find helpful resources, FAQs, and
                    alternative contact methods. At Zippy, we appreciate your
                    trust, and our customer service team is dedicated to making
                    your experience with us seamless and enjoyable. Thank you
                    for choosing Zippy for your gadget repair needs.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      )}
      {activeSection === "faq" && <Faq />}
      {activeSection === "contact" && <ContactInformation />}
    </div>
  );
};

export default Help;
