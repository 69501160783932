import { CLEAR_ORDER, Empty_DEVICE_INFO, Modal_FAIL,Modals_REQUEST,Modals_SUCCESS, ORDER_FAIL, ORDER_REQUEST, ORDER_SUCCESS, SAVE_DEVICE_INFO } from "../contants/repairConstants";
import  axios  from "axios";
import { auth, db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  onSnapshot,
  set
} from "firebase/firestore";

import { database } from "../firebase.config";
import emailjs from "@emailjs/browser";



export const saveRepairDevice = (data) => async (dispatch) => {
   dispatch({
    type: SAVE_DEVICE_INFO,
    payload: data,
  });

  sessionStorage.setItem("repairInfo", JSON.stringify(data));
}
export const EmptyRepairDevice = () => async (dispatch) => {
  dispatch({
   type: Empty_DEVICE_INFO,
   
 });

 sessionStorage.setItem("repairInfo", {});
}

export const saveOrder = (data,userId,orderId) => async (dispatch) => {
  try{
   
    
        dispatch({ type: ORDER_REQUEST });
          console.log(data)
       await  setDoc(doc(db, "repairOrders",orderId ),data ).then(async (res)=>{
        emailjs.init("-z72C4VL89mvbh6WS")
      const SERVICE_ID = "service_aatz8eg"
      const TEMPLATE_ID = "template_n56hco8"
     await emailjs.send(SERVICE_ID, TEMPLATE_ID, {
      toEmail:data?.personalData?.emailId,
      order_id:orderId,
      service_type:data?.packData?.serviceCost,
      device_brand:data?.deviceData?.brandName,
      device_modal:data?.deviceData?.modelName,
      device_issues:data?.problemList,
      location:data?.pickUpData?.addLine1,
      date:data?.pickUpData?.bookingTimeDate,
      time:data?.pickUpData?.bookingTimeDate,
      });
     
      // await axios.post(`https://backend.api-wa.co/campaign/neodove/api?apiKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NzA0YmRlNDM4OWE2MjdiNTZhZmMzYiIsIm5hbWUiOiJGaXh6aXAiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjU3MDRiZGU0Mzg5YTYyN2I1NmFmYzM2IiwiYWN0aXZlUGxhbiI6Ik5PTkUiLCJpYXQiOjE3MDE4NTgyNzB9.eaXw69kT37t5a9AOaX9mIyIxqVZBLtWewYvpjgrinMs&campaignName=OrderCompleted&destination=${"+91"+data?.personalData?.contactNo}&userName=${data?.personalData?.fullName}&templateParams=[ ]`)
      //  await axios.get(`http://146.88.24.53/api/mt/SendSMS?user=FIXZIPTECH&password=ABC@999&senderid=ZEPAIR&channel=Trans&DCS=0&flashsms=0&number=${data?.personalData?.contactNo}&text=Thanks for registration. Your registration number is ${orderId} Team Zepair&route=06&Peid=1001101425723246870&DLTTemplateId=1007597155581940100`)
       await axios.post("https://api.neodove.com/integration/custom/f78c4cf8-cde2-4f0d-a26a-b0d16c3477b6/leads",{
         "name": data?.personalData?.fullName,
  "mobile": data?.personalData?.contactNo,
  "orderId": orderId,
  "email": data?.personalData?.emailId,
  'paymentMethod': data?.paymentData?.paymentMethod,
  'paymentStatus': data?.paymentData?.paymentStatus,
  'transactionId': data?.paymentData?.transactionId,
  'paidAmt': data,
  "orderStatus" : data?.orderStatus,
  'problemList': data?.problemList,
  'problemDesc': data?.problemDesc,
  'deviceType': data?.deviceData?.deviceType,
  'brandName': data?.deviceData?.brandName,
  'modelName': data?.deviceData?.modelName,
  'deviceColor': data?.deviceData?.deviceColor,
  'packName': data?.packData?.packName,
  'serviceCost': data?.packData?.serviceCost,
  'address' : data?.pickUpData?.addLine1 + ", " +  data?.pickUpData?.addLine2 + ", " +  data?.pickUpData?.addLine3,
  'bookingTimeDate': data?.pickUpData?.bookingTimeDate,
  'pickUpContact': data?.personalData?.contactNo,
  'Source':'Web'
       })
       dispatch({ type: ORDER_SUCCESS,payload:data });

    }).catch((err)=>{
       dispatch({
      type: ORDER_FAIL,
      payload: err.message,
    });
    })
     
  }catch(err){
     dispatch({
      type: ORDER_FAIL,
      payload: err.message,
    });
  }
}

export const clearOrder = () => async (dispatch) => {
  dispatch({ type: CLEAR_ORDER });
};

