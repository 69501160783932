import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
  
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const AddTestimonials = ({openModal,handleOpenModal,handleAddTestimonial,testimonialDetail,setTestimonialDetail}) => {
  return (
     <div>
     
      <Dialog
        size="sm"
        open={openModal}
        handler={handleOpenModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto relative  w-full ">
         <div onClick={handleOpenModal} className="absolute cursor-pointer top-2 right-[10px] flex items-center justify-center px-2 py-2 w-[25px] h-[25px] rounded-full bg-gray-200" >
          <p className="text-[.7rem]" >X</p>
        </div>
          <CardBody className="">
         
            <div className="md:mx-6 mx-0 mt-2" >
              <h4 className="text-[1.5rem] text-black font-[600]  " >Enter Testimonials</h4>
            <div className="my-2" >
                <Input value={testimonialDetail.name} onChange={(e)=>{
                    setTestimonialDetail({...testimonialDetail,name:e.target.value})
                }} label="Enter Your Name" />
            </div>
             <div className="my-2">
                <Input value={testimonialDetail.position} onChange={(e)=>{
                    setTestimonialDetail({...testimonialDetail,position:e.target.value})
                }} label="Enter Your Position" />
            </div>
             <div className="my-2">
                <Input value={testimonialDetail.company} onChange={(e)=>{
                    setTestimonialDetail({...testimonialDetail,company:e.target.value})
                }} label="Enter Your Company" />
            </div>
             <div className="my-2">
                <Textarea value={testimonialDetail.testimonial} onChange={(e)=>{
                    setTestimonialDetail({...testimonialDetail,testimonial:e.target.value})
                }} label="Enter Testimonial " />
            </div>
            <div className="my-3 flex items-center justify-center" >
                <button onClick={handleAddTestimonial} className="bg-blue-600 py-3 px-12 rounded-lg text-white font-[500]" >Submit</button>
            </div>
             </div>
            
              
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default AddTestimonials