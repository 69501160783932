import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import ArrowSelect from "../../asset/arrowSelect.svg"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
const PlacesAutocomplete = ({setLocationData,locationData,setAddress,address,geometry,setGeometry,handleLocationData}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "init_map",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const [selected, setSelected] = useState(false)
  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When the user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      setSelected(true)
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
       
        console.log(results[0])
         const { lat, lng } = getLatLng(results[0]);
        console.log("📍 Coordinates: ", { lat, lng });
        console.log(results[0])
       console.log(lat,lng)
      
        handleLocationData(lat,lng)
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
       <li className="py-2  border-b-[1px] border-gray-400 cursor-pointer" key={place_id} onClick={handleSelect(suggestion)}>
        <div className="flex items-center justify-start gap-4" >
         <div>
          <img className="w-[20px]" src={ArrowSelect} alt="" />
          </div>
        <div>
          <p className="font-[InterBold] text-[#0D1F37] text-[.8rem] " >{main_text}</p> 
       
          </div>
          </div>
            <p className="font-[InterMedium] text-[#686868] text-[.6rem]">{secondary_text}</p>
            
        </li>
      );
    });

  return (
    <div ref={ref}>
    <div className="relative" >
      <input
      className={`lg:w-[400px] font-[InterMedium] text-[#0D1F37] outline-none xl:w-[450px] md:w-[300px] w-[260px]   px-4 py-2 rounded-lg ${selected ? "border-[1px] border-[#2967B0] drop-shadow-lg" : "border-[1px]  border-gray-300"} `}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter Your Location"
      />
  
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul className=" z-[999] lg:w-[450px]  md:w-[300px] w-[260px]  bg-white py-4 px-6 rounded-lg border-[1px] border-gray-400" >{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutocomplete