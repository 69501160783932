import React,{useState,useEffect} from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import CartLeft from '../Components/Cart/CartLeft'
import CartRight from '../Components/Cart/CartRight'
import CartModel from '../Components/Cart/CartModel'
import { useDispatch, useSelector } from 'react-redux'
import { EmptyRepairDevice, saveOrder, saveRepairDevice } from '../actions/repairActions'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase.config'
import toast, { Toaster } from 'react-hot-toast';
import { collection, query, where, getDocs, getDoc, addDoc } from "firebase/firestore";
import CoupanModal from '../Components/Cart/CoupanModal'
import EmptyCart from '../Components/Cart/EmptyCart'
import useRazorpay from "react-razorpay"

const Cart = () => {
    const [openModal, setOpenModal] = useState(false)
    const [emptyCart, setEmptyCart] = useState(false)
    const navigate = useNavigate()
     const {  isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
    const { deviceInfo } = useSelector(
    (state) => state.modals
  );
  const [Razorpay] = useRazorpay();
  const {error, loading  } = useSelector(
    (state)=>state.repairOrders
  )

  const dispatch = useDispatch();
  const [repairOrderList, setRepairOrderList] = useState([])
  const [paymentMethod, setPaymentMethod] = useState("")
  const [orderId, setOrderId] = useState("")
  const [coupanCode, setCoupanCode] = useState({})
  const [coupanList, setCoupanList] = useState([])
  const [coupanModal, setCoupanModal] = useState(false)
//   const handleOrder = async () =>{
//     const date = new Date()
//     if(deviceInfo  && paymentMethod.length !== 0 ){
//       dispatch(saveOrder({...deviceInfo,
//     paymentData: {
//       paymentMethod : paymentMethod,
//       paymentStatus : paymentMethod === 'Cash on Delivery' ?'Unpaid': "Paid",
//       transactionId : "86833bjkqhoih8",
//       paidAmt : coupanCode?.coupanValue ? Number(deviceInfo?.packData?.PackCost)-Number(coupanCode?.coupanValue) : deviceInfo?.packData?.PackCost
//     },
     
//     orderId:"ZIPR" + new Date().getFullYear() + (repairOrderList.length +1),
//     createdAt:date,
//     orderStatus:"Pending",
//     userUid : users && users ,
//   },users,"ZIPR" + new Date().getFullYear() + (repairOrderList.length +1)))
//   if(error){
//      toast.error("error: "+ error ,{
//         position:"bottom-center"
//       })
  
//   }
//   else{
    
  
    
//      navigate("/success")
//      dispatch(saveRepairDevice({}))
//      }



//   }
// }

const handleCODOrder = async () =>{
      const date = new Date()
      const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '/' + mm + '/' + yyyy;
    if(deviceInfo ){
      dispatch(saveOrder({...deviceInfo,
    paymentData: {
      paymentMethod : "Cash on Delivery",
      paymentStatus : 'Unpaid',
      transactionId : "",
      paidAmt : coupanCode?.coupanValue ? Number(deviceInfo?.packData?.PackCost)-Number(coupanCode?.coupanValue) : deviceInfo?.packData?.PackCost
    },
     
    orderId:"ZIPR" + new Date().getFullYear() + (repairOrderList.length +1),
    createdAt:date,
    orderDate:formattedToday,
    orderStatus:"Pending",
    userUid : users && users ,
  },users,"ZIPR" + new Date().getFullYear() + (repairOrderList.length +1)))
  if(error){
     toast.error("error: "+ error ,{
        position:"bottom-center"
      })
  
  }
  else{
    
  
    
     navigate("/success")
     dispatch(saveRepairDevice({}))
     }



  }
}

const handlePayment = async (params) => {
  
  console.log(process.env.REACT_APP_RAZORPAY_API_KEY)
  const options = {
    key: process.env.REACT_APP_RAZORPAY_API_KEY,
    amount: (coupanCode?.coupanValue ? Number(deviceInfo?.packData?.PackCost)-Number(coupanCode?.coupanValue) : deviceInfo?.packData?.PackCost)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "Zepair",
    description: "Test Transaction",
    

    
    handler: function (response) {
      const date = new Date()
      const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '/' + mm + '/' + yyyy;
        
    if(deviceInfo){
      dispatch(saveOrder({...deviceInfo,
    paymentData: {
      paymentMethod : "Online",
      paymentStatus : "Paid",
      transactionId : response.razorpay_payment_id,
      paidAmt : coupanCode?.coupanValue ? Number(deviceInfo?.packData?.PackCost)-Number(coupanCode?.coupanValue) : deviceInfo?.packData?.PackCost
    },
     
    orderId:"ZIPR" + new Date().getFullYear() + (repairOrderList.length +1),
    createdAt:date.getUTCDate(),
    orderStatus:"Pending",
    orderDate:formattedToday,
    userUid : users && users ,
  },users,"ZIPR" + new Date().getFullYear() + (repairOrderList.length +1)))
  if(error){
     toast.error("error: "+ error ,{
        position:"bottom-center"
      })
  
  }
  else{
    
  
    
     navigate("/success")
     dispatch(EmptyRepairDevice())
     }



  }
      
    },
    prefill: {
      name: deviceInfo?.personalData?.fullName,
      email: deviceInfo?.personalData?.emailId,
      contact: deviceInfo?.personalData?.contactNo,
    },
    notes: {
      address: "Unit No 30, VTM 1, Mehra Industrial Compound, Opp Telephone Exchange, Andheri Kurla Road, Saki Naka, Mumbai 400072",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzp1 = new Razorpay(options);

  rzp1.on("payment.failed", function (response) {
    toast.error("Payment Cancelled " ,{
      position:"top-center"
    })
  });

  rzp1.open();
};
    const fetchRepairOrder =  async () =>{
    console.log("newOrder")
   const querySnapshot = await getDocs(collection(db, "repairOrders"));
    querySnapshot.forEach((doc) => {
     setRepairOrderList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
    });
    }
    const fetchCoupans = async () =>{
  const querySnapshot = await getDocs(collection(db, "coupans"));
    querySnapshot.forEach((doc) => {
     setCoupanList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
    });
    }
    console.log()
    const handleOpenModal = () =>{
        setOpenModal(!openModal)
    }
    const handleCoupanModal = () =>{
      setCoupanModal(!coupanModal)
    }
    useEffect(() => {
      fetchRepairOrder()
      fetchCoupans()
      if(Object.keys(deviceInfo).length === 0){
        setEmptyCart(true)
      }
      
      sessionStorage.setItem("activePage", "Cart");
    }, [deviceInfo])
    
  return (
    <>
  {!emptyCart ?   <div>
        <Navbar />
        <Toaster />
        <CoupanModal deviceInfo={deviceInfo} handleCoupanModal={handleCoupanModal} coupanCode={coupanCode} setCoupanCode={setCoupanCode} coupanModal={coupanModal} coupanList={coupanList} />
        <CartModel openModal={openModal} handleOpenModal={handleOpenModal}  />
        <div className='mt-[80px] font-[Inter]'>
                <div className='lg:grid grid-cols-3 gap-6 grid-flow-col xl:px-[160px] lg:px-[60px] px-[0px] md:px-[30px]   py-8 xl:py-[30px]' >
                    <CartLeft handleCODOrder={handleCODOrder} paymentMethod={paymentMethod} deviceInfo={deviceInfo} setPaymentMethod={setPaymentMethod} handleOrder={handlePayment} handleOpenModal={handleOpenModal} />
                    <CartRight handleCoupanModal={handleCoupanModal} coupanCode={coupanCode} setCoupanCode={setCoupanCode} deviceInfo={deviceInfo}  />
                </div>
             <Footer bg="#0D1F37"/>
        </div>
   
    </div> :
    <EmptyCart />
    }
    </>
  )
}

export default Cart