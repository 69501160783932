import React from 'react'
import { Stepper, Step, Button, Typography } from "@material-tailwind/react";
import { CogIcon, UserIcon, BuildingLibraryIcon,ShoppingBagIcon, CubeIcon, TruckIcon, ClipboardDocumentCheckIcon, ClipboardDocumentIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import ConfirmedIcon from "../../asset/statusIcon/confirmed.svg"
import PlacedIcon from "../../asset/statusIcon/placed.svg"
import AgentAssigned from "../../asset/statusIcon/agentAssigned.svg"
import OutForPick from "../../asset/statusIcon/outForPickup.svg"
import Pick from "../../asset/statusIcon/pickedUp.svg"
import Service from "../../asset/statusIcon/outInService.svg"
import Delivering from "../../asset/statusIcon/outForDelivery.svg"
import Approve from "../../asset/statusIcon/complete.svg"
import "./OrderDetails.css"
const OrderSteps = ({orderStatus}) => {
    const [activeStep, setActiveStep] = React.useState(orderStatus && orderStatus);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  return (
    <div className="w-full hidden  md:block px-2 py-12">
      <Stepper
        activeStep={orderStatus && orderStatus}
        className=' '
        lineClassName="bg-white-50 dashed"
        
          activeLineClassName="bg-[#59A4FF]"
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
      >
        <Step className={orderStatus === "Pending" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6   text-white" : "blue-gray bg-gray-200 lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" }>
        <img className='' src={PlacedIcon} alt="" />
         
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]"  color={orderStatus === "Purchased" ? "blue" : "blue-gray"}>
              Order Placed
            </Typography>
           
          </div>
        </Step>
        <Step className={orderStatus === "Placed" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" : "blue-gray lg:h-10 bg-gray-200 lg:w-10  xl:w-12 xl:h-12  w-6 h-6   text-white" } >
           <img className='' src={ConfirmedIcon} alt="" />
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]" color={orderStatus === "Packaging" ? "blue" : "blue-gray"}>
              Order Confirmed
            </Typography>
           
          </div>
        </Step>
        <Step className={orderStatus === "Agent Assigned" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" : "blue-gray bg-gray-200 lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" } >
          <img className='' src={AgentAssigned} alt="" />
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]"  color={orderStatus === "Shipped" ? "blue" : "blue-gray"}>
             Agent Assigned
            </Typography>
            
          </div>
        </Step>
         <Step className={orderStatus === "Pickup" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" : "blue-gray bg-gray-200 lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" }>
          <img className='' src={OutForPick} alt="" />
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]" color={orderStatus === "Delivering" ? "blue" : "blue-gray"}>
            Agent out for pickup
            </Typography>
            
          </div>
        </Step>
          <Step className={orderStatus === "Pickedup" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" : "blue-gray bg-gray-200 lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6 text-white" }>
          <img className='' src={Pick} alt="" />
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]" color={orderStatus === "Delivering" ? "blue" : "blue-gray"}>
           Order Picked up
            </Typography>
            
          </div>
        </Step>
          <Step className={orderStatus === "Service" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" : "blue-gray bg-gray-200 lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6   text-white" } >
          <img className='' src={Service} alt="" />
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]" color={orderStatus === "Delivered" ? "blue" : "blue-gray"}>
            Order In Service
            </Typography>
            
          </div>
        </Step>
          <Step className={orderStatus === "Out for Delivery" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" : "blue-gray lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6   text-white" } >
         <img className='' src={Delivering} alt="" />
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]" color={orderStatus === "Return" ? "blue" : "blue-gray"}>
             Out for Delivery
            </Typography>
            
          </div>
        </Step>
         <Step className={orderStatus === "Completed" ? "border-blue-400 border-[3px] lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6 text-white" : "blue-gray lg:h-10 lg:w-10  xl:w-12 xl:h-12  w-6 h-6  text-white" } >
          <img className='' src={Approve} alt="" />
          <div className="absolute lg:-bottom-[2.5rem] -bottom-[1.3rem] w-max text-center">
            <Typography className="text-[.6rem] font-[InterMedium] text-gray-600  lg:text-[.7rem]" color={orderStatus === "Return" ? "blue" : "blue-gray"}>
             Order Complete
            </Typography>
            
          </div>
        </Step>
        
       
      </Stepper>
    
    </div>
  )
}

export default OrderSteps