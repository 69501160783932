import React, { useState } from 'react'
import PhoneLogin from "../../asset/phoneLogin.png"
import Google from "../../asset/google.png"
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  InformationCircleIcon
} from "@material-tailwind/react";
import {useDispatch,useSelector} from "react-redux"
import {Link, useNavigate} from "react-router-dom"
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
  onSnapshot
} from "firebase/firestore";
import { db } from '../../firebase.config';
import { signUpUsingEmail, signUpUsingGoogle } from '../../actions/userAction';
import {  EyeIcon,EyeSlashIcon, PhoneIcon } from '@heroicons/react/24/outline';
const SignUp = ({handleOpen,activeLogin,setActiveLogin,handleGoogleLogin}) => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [nameErrorMessage, setNameErrorMessage] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState("")
  const [phoneError, setPhoneError] = useState(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
  const [showPassword, setShowPassword] = useState(false)
     const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch()
      const navigate = useNavigate()
  const handleGoogleRegister = async () =>{
    dispatch(signUpUsingGoogle())
  }
  const handleRegister = async ()=>{
    
    const userRef = collection(db, "user");
    const emailQuery = query(userRef, where("email", "==", email));
    const getEmailInfo = await getDocs(emailQuery);
    const userMobileRef = collection(db, "user");
    const mobileQuery = query(userRef, where("phone", "==", phone));
    const getMobileInfo = await getDocs(mobileQuery);
      var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    if(name===""){
      setNameErrorMessage("Enter a name")
       
    }
    else if(email.length!==0 && getEmailInfo.docs.length!==0 ){
    
      alert("Email Already Present")
      setEmailError(true)
      setEmailErrorMessage("Email is Already Present")
      setNameErrorMessage("")
    }
    else if(email.length===0  || emailRegex.test(email)===false){
      
      setEmailError(true)
      setEmailErrorMessage("Enter a Valid Email")
      setNameErrorMessage("")
    }
    else if(phone.length===0  || phoneRegex.test(phone)!==true){
    
      setPhoneError(true)
      setPhoneErrorMessage("Enter a Valid Phone Number")
      setEmailErrorMessage("")
      setNameErrorMessage("")
    }
    
    else if(phone.length!==0 && getMobileInfo.docs.length!==0){
       alert("Phone Number Already Present")
      setPhoneError(true)
      setPhoneErrorMessage("Phone Number is Already Present")
      setEmailErrorMessage("")
      setNameErrorMessage("")
    }
    
    else if(password.length===0  ){
      alert("Enter Valid Password")
      setPasswordError(true)
      setPasswordErrorMessage("Enter a Valid Password")
      setPhoneErrorMessage("")
      setEmailErrorMessage("")
      setNameErrorMessage("")
    }
    else{
     dispatch(signUpUsingEmail(email,password,name,phone))
    if(!error){
      setName('')
      setEmail('')
      setPassword('')
      setPhone('')
      handleOpen()
    }
    }
    
  }

  return (
    <div className="col-span-1">
           <CardBody className="flex flex-col gap-4">
            <Typography className='font-[InterBold] text-[#0D1F37] ' variant="h4" color="blue-gray">
            Create  an Account
            </Typography>
           
          
           
            <Input value={name} onChange={(e)=>{setName(e.target.value)}} label="Name" className=' ' size="lg" />
         {nameErrorMessage.length !== 0 && <p className='font-[InterRegular] my-[-5px] text-[.8rem] text-red-600' >{nameErrorMessage}</p>}
            <Input value={phone} error={phoneError ? true : false } onChange={(e)=>{setPhone(e.target.value)}} label="Phone" size="lg" />
          {phoneErrorMessage.length !== 0 && <p className='font-[InterRegular] my-[-5px] text-[.8rem] text-red-600' >{phoneErrorMessage}</p>}
           
            <Input type='email' error={emailError ? true : false } value={email} onChange={(e)=>setEmail(e.target.value)} label="Email" size="lg" />
          {emailErrorMessage.length !== 0 && <p className='font-[InterRegular] my-[-5px] text-[.8rem] text-red-600' >{emailErrorMessage}</p>}
           
            <div className="relative flex w-full ">
      <Input
        
        label="Password"
        value={password} 
        error={passwordError ? true : false }
        onChange={(e)=>{setPassword(e.target.value)}}
        type={showPassword ? "text" : "password"}
        className="pr-20"
        containerProps={{
          className: "min-w-0",
        }}
      />
      <button
        
       
        disabled={!password}
        onClick={()=>setShowPassword(!showPassword)}
        className="!absolute  right-2 top-3 "
      >
        {showPassword ? <EyeIcon className='w-[15px]  text-blue-500' /> :<EyeSlashIcon className='w-[15px] text-blue-500' />}
      </button>
      
    </div>
   {passwordErrorMessage.length !== 0 && <p className='font-[InterRegular] my-[-5px] text-[.8rem] text-red-600' >{passwordErrorMessage}</p>}
            
             <button onClick={handleRegister}  className="bg-[#2967B0] font-[600] text-white py-3 rounded-lg " >
              Sign Up
            </button>
            <Typography variant="small" className="mt-4 flex font-[InterRegular] justify-center">
              Already have an account?
              <Typography
                as="p"
              
                variant="small"
                color="blue-gray"
                className="ml-1 font-[InterBold] cursor-pointer "
                onClick={()=>setActiveLogin("login")}

              >
                Sign In
              </Typography>
            </Typography>
            
          </CardBody>
          <CardFooter className="pt-0 border-t-[1px] border-gray-300 mx-6  ">
            <div className="flex mt-3 items-center justify-start" >
          
            <div onClick={handleGoogleLogin}  className="px-2 py-2 w-[50px] h-[50px] flex items-center justify-center cursor-pointer   border-[1px] border-gray-300 rounded-full">
            <img  src={Google} alt="" />
            </div>
            
            </div>
           <p className="mt-3 text-[.8rem] font-[InterRegular] " >By continuing, you agree to Zepair’s <Link to="/termsandcondition" className='underline' > Terms of Use.</Link> Read our <Link to="/privacy-policy" className='underline' > Privacy Policy</Link></p>
          </CardFooter>
        </div>
  )
}

export default SignUp