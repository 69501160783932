import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const SelectColor = ({color,setColor,handleColorModal,activeColor}) => {
  return (
     <div>
      <Dialog
        size="md"
        open={activeColor}
        handler={handleColorModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto grid px-12 py-8   w-full ">
          <CardBody className="flex flex-col gap-4">
            <h4 className="font-[600] text-[1.2rem]">
              Enter the Color
            </h4>
           
          <div>
            <Input value={color} onChange={(e)=>setColor(e.target.value)} label="Enter Color" />
          </div>
            <div className="flex mt-6 items-center justify-center" >
              <button onClick={handleColorModal} className="px-4 py-2 w-[130px] rounded-lg mx-3 bg-gray-300">Cancel</button>
              <button onClick={()=>{
                if(color !== ''){
                    handleColorModal();
                }
                else{
                    alert('Please enter color');
                }
              }} className="px-4 py-2 text-white w-[130px] rounded-lg mx-3 bg-blue-300">Continue</button>
            </div> 
          
           
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default SelectColor