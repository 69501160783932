import React from 'react'
import MobileRepair from "../../asset/mobileDevice.png"
import TabRepair from "../../asset/tablet.png"
import LaptopRepair from "../../asset/laptop.png"
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { ArrowLeftCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
const IssueBanner = ({device,manufacturer,issueList,issue,model,color,issueDescription}) => {
  return (
     <div className="  bg-gradient-to-r from-[#2968b01e] to-[#f6eb1523] xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:py-[30px]">
     
        <div className='flex items-center justify-between' >
    <h4 className='lg:text-[35px] text-[20px] font-[InterBold]' >Repair Your Device</h4>
     
    </div>
        <div className='flex md:items-start  mt-[32px] flex-wrap md:flex-nowrap justify-start gap-3' >
        <div className='md:w-[145px] w-[125px] flex md:items-center justify-center flex-col'  >
                <h5 className='my-2 text-[10px] md:text-[13px] md:text-center  text-[#686868] font-[InterRegular] mb-4' >Selected Device</h5>
                <div className='flex  md:items-center justify-center flex-col'>
                <div className='rounded-lg w-[80px] h-[80px]   flex items-center justify-center bg-white flex-col  border-[1px] border-[#59A4FF]' >
                  {  device === "mobile_brands" &&  <img className='w-[53px] h-[57px]  ' src={MobileRepair} alt="" />}
                  {  device === "tablet_brands" &&  <img className='w-[53px] h-[57px]  ' src={TabRepair} alt="" />}
                  {  device === "laptop_brands" &&  <img className='w-[53px] h-[57px]  ' src={LaptopRepair} alt="" />}
                  </div>
                     {  device === "mobile_brands" &&  <p className='text-[15px] w-[80px] text-[#0D1F37]  font-[InterMedium] capitalize text-center  mt-2 '   > Mobile</p>}
                      {  device === "tablet_brands" &&  <p className='text-[15px] w-[80px] text-[#0D1F37]  font-[InterMedium] capitalize text-center  mt-2 '   > Tablet</p>}
                       {  device === "laptop_brands" &&  <p className='text-[15px] w-[80px] text-[#0D1F37]  font-[InterMedium] capitalize text-center  mt-2 '  > Laptop</p>}
                </div>
             
            </div>
              <div className='hidden lg:block w-[21px] mt-20 h-[40px]' >
                <ArrowRightIcon className=' text-gray-700' />
               </div>
           <div className='md:w-[145px] w-[125px] flex md:items-center justify-center flex-col' >
                <h5 className='my-2 text-[10px] md:text-[13px] md:text-center  text-[#686868] font-[InterRegular] mb-4 ' >Selected Brand</h5>
                <div className='flex md:items-center justify-center flex-col' >
                <div className='rounded-lg w-[80px] h-[80px]   flex items-center justify-center bg-white flex-col  border-[1px] border-[#59A4FF]'>
                     <img className=' w-[53px] h-[57px] ' src={manufacturer?.image} alt="" />
                        </div>
                     <p  className='text-[15px] w-[80px] text-[#0D1F37] font-[InterMedium] text-center capitalize   mt-2 ' >{manufacturer.brand}</p>
             
                </div>
               
            </div>
             <div className='hidden lg:block w-[21px] mt-20 h-[40px]'>
                <ArrowRightIcon className=' text-gray-700' />
               </div>
              <div className='md:w-[145px] w-[125px] flex md:items-center justify-center flex-col' >
                <h5 className='my-2 text-[10px] md:text-[13px] md:text-center  text-[#686868] font-[InterRegular] mb-4  ' >Selected Model</h5>
                <div className='flex md:items-center justify-center flex-col'>
                <div className='rounded-lg w-[80px] h-[80px]   flex items-center justify-center bg-white flex-col  border-[1px] border-[#59A4FF]'>
                     <img className='w-[53px] h-[57px]  ' src={manufacturer?.image} alt="" />
                     </div>
                     <p  className='text-[15px] w-[80px] text-[#0D1F37] font-[InterMedium] text-center capitalize   mt-2 ' >{model}</p>
                </div>
               
            </div>
             {color !=="" && <div className='hidden lg:block w-[21px] mt-20 h-[40px]'>
                <ArrowRightIcon className=' text-gray-700' />
               </div>}
                {color !=="" && <div className='md:w-[145px] w-[125px] flex md:items-center justify-center flex-col' >
                <h5 className='my-2 text-[10px] md:text-[13px] md:text-center  text-[#686868] font-[InterRegular] mb-4 ' >Selected Color</h5>
                <div className='flex md:items-center justify-center flex-col'>
                <div className='rounded-lg w-[80px] h-[80px]   flex items-center justify-center bg-white flex-col  border-[1px] border-[#59A4FF]' >
                     <img className='md:w-[60px] w-[40px] ' src={manufacturer?.image} alt="" />
                        </div>
                     <p  className='mt-2 text-center text-[.8rem] capitalize ' >{color}</p>
             
               </div>
            </div>}
             <div className='hidden lg:block w-[21px] mt-20 h-[40px]'>
                <ArrowRightIcon className='text-gray-700' />
               </div>
              <div className='lg:w-[350px] md:w-[300px] w-[265px] flex md:items-start justify-center flex-col' >
                <h5 className='my-2 text-[10px] md:text-[13px] text-left  text-[#686868] font-[InterRegular] mb-4 ' >Issues</h5>
                <div className='rounded-lg lg:w-[350px] overflow-auto md:w-[300px] w-[265px] h-[80px] px-3 py-3    bg-white flex-wrap  border-[1px] border-[#59A4FF]'>
                <div className=' flex items-start justify-start gap-4' >
                    {
                        issueList.map((item) =>(
                            <p className='font-[InterMedium] text-[#0D1F37] text-[.7rem] md:text-[12px] mb-1' >{item}</p>
                        ))
                    }
                    </div>
                    <p>{issueDescription && issueDescription} </p>
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default IssueBanner