import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Input } from '@material-tailwind/react'
import React,{useState} from 'react'
import NewModel from '../Repair/NewModel'

const SelectModel = ({model,setModel,manufacturer,openModal,setOpenModal,handleDeviceModal,filterBySearch,filteredModelList,stage,setStage}) => {
  const [newModel, setNewModel] = useState(false)
  const handleNewModel = () =>{
    setNewModel(!newModel)
  }
  console.log(filteredModelList)
  return (
     <>
    <NewModel stage={stage} openModal={openModal} setOpenModal={setOpenModal} setStage={setStage} newModel={newModel} model={model} setModel={setModel} handleNewModel={handleNewModel} setNewModel={setNewModel} />
    <div className='lg:px-24 px-6 md:px-12 py-12 lg:py-24'>

        <div className='flex items-start flex-col-reverse md:flex-row gap-6  justify-between' >
     <div className='flex items-center gap-4 flex-col  justify-start' >
     <div>
    <h4 className='font-[600] text-[1.5rem]'>Select a Model:  </h4>
    <p className='text-gray-600' > Can’t find ,  <span onClick={()=>setNewModel(!newModel)} className='text-blue-400 cursor-pointer' > Enter your Model </span></p>
    <div className='mt-2'>
    <Input onChange={filterBySearch} label='Search Model'   />
    </div>
    </div>
    
    </div>
     <div onClick={()=>setStage("select manufacturer")} className='flex  text-red-400 font-[600]  cursor-pointer rounded-lg py-3 px-4 bg-gray-200 items-center justify-start gap-3 ' >
      <ArrowLeftIcon className='lg:w-[20px] w-[10px] ' />
      <p className='text-[.8rem] lg:text-[1rem]' >Back</p>
    </div>
    </div>
         <div className='flex items-center flex-wrap gap-6 justify-start  mt-12 ' >
         {
            filteredModelList && filteredModelList.map((model)=>(
                 <div onClick={()=>{
                    setModel(model)
                    handleDeviceModal()
                    }} className='md:w-[200px] w-[45%] cursor-pointer rounded-lg py-6  text-center bg-gray-100 ' >
                <h4 className='md:text-[1rem] text-[.8rem] font-[600]' >{model}</h4>
            </div>
            ))
         }
           
          

         </div>
    </div>
    </>
  )
}

export default SelectModel