import React from 'react'
import { Button } from '@material-tailwind/react'
import { Link } from 'react-router-dom'
const SingleShieldDetails = ({shieldOrderList,id}) => {
  return (
    <div>
      <div className='my-12 mx-48' >
      <h4 className=' text-[2rem] font-[600] ' >Order Information:</h4>
      <p className='text-gray-500' >Order#{id}</p>
      
      <div className='border-[1px] flex items-start flex-wrap justify-between border-gray-300 px-12 py-6 rounded-lg my-3' >
        <div>
          <p className='text-[1.5rem] font-[600]' >Shipping Information:</p>
          <div>
            <p>{shieldOrderList?.data?.personalInfo?.name}</p>
             <p>{shieldOrderList?.data?.personalInfo?.phone}</p>
               <p>{shieldOrderList?.data?.personalInfo?.address}</p>
               <p>{shieldOrderList?.data?.personalInfo?.city}, {shieldOrderList?.data?.personalInfo?.pinCode}</p>
                <div className='mt-4' >
          <p className='text-[1.5rem] font-[600] ' >Repair Information:</p>
          <div>
       <p>  <span className='font-[600]' > Device Name:</span>  {shieldOrderList?.data?.deviceInformation?.model}</p>
       <p>  <span className='font-[600]' > Device IMEI:</span>  {shieldOrderList?.data?.deviceInformation?.imei}</p>
       <p>  <span className='font-[600]' > Device Type:</span>  {shieldOrderList?.data?.deviceInformation?.device}</p>
          </div>
        </div>
          </div>
        </div>
        
         <div>
          <p className='text-[1.8rem] font-[600]' >Order Summary:</p>
          <div>
            <p className='text-[1.2rem]' > <span className='font-[600] ' > Repair Status: </span>{shieldOrderList?.data?.orderStatus}</p>
             <p className='text-[1.2rem]'> <span className='font-[600]' >Total Price: </span>Rs.{shieldOrderList?.data?.totalPrice}/-</p>
              <p className='text-[1.2rem]'> <span className='font-[600]' >Product: </span>{shieldOrderList?.data?.product}</p>
          </div>
    <Link to="/repair" >   <Button className='my-4'>Repair More </Button></Link> 
        </div>
      </div>
      </div>
    </div>
  )
}

export default SingleShieldDetails