import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import GuildelinesIcon from "../../asset/guildelinesIcon.png"
import { MapPinIcon } from "@heroicons/react/24/solid";
import CrossIcon from "../../asset/crossIcon.svg"
import { InformationCircleIcon } from "@heroicons/react/24/outline";
const TimeModal = ({timeModal,handleTimeOpenModal,address,day,checkDay,time,setTime,setDay,handleSetDate,handleRepairOrder,addressModel,setAddressModel,setMonthYear,monthYear,handleRepairInfo}) => {
    const currentDate = new Date()
   let nextDay = new Date(currentDate.getTime() + (24 * 60 * 60 * 1000));
    let thirdDay = new Date(nextDay.getTime() + (24 * 60 * 60 * 1000));
     let forthDay = new Date(thirdDay.getTime() + (24 * 60 * 60 * 1000));
      let fifthDay = new Date(forthDay.getTime() + (24 * 60 * 60 * 1000));
       let sixthDay = new Date(fifthDay.getTime() + (24 * 60 * 60 * 1000));
        let seventhDay = new Date(sixthDay.getTime() + (24 * 60 * 60 * 1000));
        const [timingList, setTimingList] = useState([])
    const [nowDate, setNowDate] = useState(null)
     const [errorDate, setErrorDate] = useState("")  
    const disableClass = "border-[#DCDCDC] md:w-[70px] w-[50px] text-gray-400 text-center rounded-lg border-[1px] px-4 py-3"
    const activeClass = "border-[#59A4FF] cursor-pointer md:w-[70px] w-[50px] text-[#59A4FF] text-center bg-[#F6FBFF] rounded-lg border-[1px] px-2 py-2 md:px-4 md:py-3"
    const inactiveClass = "border-[#DCDCDC] text-[#0D1F37] cursor-pointer md:w-[70px] w-[50px] text-black text-center rounded-lg border-[1px] px- py-2 md:px-4 md:py-3"
    const inactiveTimingClass = "border-[#DCDCDC] text-[#0D1F37] cursor-pointer md:w-[70px] w-[50px] text-black text-center rounded-lg border-[1px] px- py-2 md:px-4 md:py-3"
    const activeTimingClass = "border-[#59A4FF] cursor-pointer md:w-[70px] w-[50px] text-[#59A4FF] text-center bg-[#F6FBFF] rounded-lg border-[1px] px-2 py-2 md:px-4 md:py-3"
    const timeList = (date) =>{
      console.log(date.getDate())
      if(date !== currentDate ){
        console.log("hii")
        let timings = []
        for(let i = 10; i < 19; i++){
          timings.push(i)
          console.log(timings)
        }
        setTimingList(timings)
      }
      else if(date.getHours()<=18 && date === currentDate && date.getHours()>10 ){
         let timings = []
         for(let i = date.getHours() + 1; i < 19; i++){
          timings.push(i)
        }
        setTimingList(timings)
      }
      else if(date.getHours()<=18 && date === currentDate && date.getHours()<10 ){
         let timings = []
         for(let i = 10; i < 19; i++){
          timings.push(i)
        }
        setTimingList(timings)
      }
    }
    console.log(time)
  return (
     <div>
      <Dialog
        size="md"
        open={timeModal}
        handler={handleTimeOpenModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto grid px-0 py-0    w-full ">
          <CardBody className="overflow-y-scroll  lg:overflow-hidden ">
          <div className="flex items-center justify-between" >
            <div>
                <p className="flex items-center justify-start" >
                 <MapPinIcon className="w-[20px] mr-3 text-blue-400" />
                 <span className="text-[1.1rem] mr-1 font-[500]" >{address?.name}</span>
                 -
                  <span className="text-[1rem] ml-1  font-[400]" >{address?.location}</span>
                  </p>
            </div>
            <div onClick={()=>setAddressModel(!addressModel)} className=" cursor-pointer  w-[40px] h-[40px] " >
          <img src={CrossIcon} alt="" />
        </div>
        </div>
            <div className="lg:my-8 md:my-6 my-4" >
            {errorDate.length !== 0 && <p className="text-red-600 flex items-center justify-start gap-3 font-[InterMedium]" ><InformationCircleIcon className="w-[20px]" /> {errorDate}</p>}
                <h4 className="font-[InterBold] text-[18px]  md:text-[24px]" >When should the Zipster arrive?</h4>
                <p className="font-[InterMedium] mt-[5px]  lg:mt-[16px] text-[12px] md:text-[16px]"> Your service will take around 1hr and 40 mins</p>
                <div className="flex mt-[12px] md:mt-[15px] lg:mt-[22px] gap-2 md:gap-4 flex-wrap items-center justify-start" >
                    <div onClick={()=>{
                      setDay(currentDate.getDate())
                      setMonthYear(currentDate.getMonth() + "/" + currentDate.getFullYear())
                      timeList(currentDate)
                      }} className={currentDate.getHours() >= '18' ? disableClass : inactiveClass && day === currentDate.getDate() ? activeClass : inactiveClass   } >
                <h4 className=" lg:text-[16px]  font-[InterRegular] text-[12px] " > {checkDay(currentDate).slice(0,3)}</h4>   
                     <p className="font-[InterMedium]   text-[12px] lg:text-[16px]" > {currentDate.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(nextDay.getDate())
                      setMonthYear(nextDay.getMonth() + "/" + nextDay.getFullYear())
                      timeList(nextDay)
                      }} className={day === nextDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className=" lg:text-[16px]  font-[InterRegular] text-[12px]" > {checkDay(nextDay).slice(0,3)}</h4>   
                     <p className="font-[InterMedium]   text-[12px] lg:text-[16px]"> {nextDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(thirdDay.getDate() )
                      setMonthYear(thirdDay.getMonth() + "/" + thirdDay.getFullYear())
                      timeList(thirdDay)
                      }} className={day === thirdDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className=" lg:text-[16px]  font-[InterRegular] text-[12px]" > {checkDay(thirdDay).slice(0,3)}</h4>   
                     <p className="font-[InterMedium]   text-[12px] lg:text-[16px]"> {thirdDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(forthDay.getDate())
                      setMonthYear(forthDay.getMonth() + "/" + forthDay.getFullYear())
                      timeList(forthDay)
                      }} className={day === forthDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className=" lg:text-[16px]  font-[InterRegular] text-[12px]" > {checkDay(forthDay).slice(0,3)}</h4>   
                     <p className="font-[InterMedium]   text-[12px] lg:text-[16px]"> {forthDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(fifthDay.getDate())
                      setMonthYear(fifthDay.getMonth() + "/" + fifthDay.getFullYear())
                      timeList(fifthDay)
                      }} className={day === fifthDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className=" lg:text-[16px]  font-[InterRegular] text-[12px]" > {checkDay(fifthDay).slice(0,3)}</h4>   
                     <p className="font-[InterMedium]   text-[12px] lg:text-[16px]"> {fifthDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(sixthDay.getDate() )
                      setMonthYear(sixthDay.getMonth() + "/" + sixthDay.getFullYear())
                      timeList(sixthDay)
                      }} className={day === sixthDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className=" lg:text-[16px]  font-[InterRegular] text-[12px]" > {checkDay(sixthDay).slice(0,3)}</h4>   
                     <p className="font-[InterMedium]   text-[12px] lg:text-[16px]"> {sixthDay.getDate()}</p>  
                    </div>
                     <div onClick={()=>{
                      setDay(seventhDay.getDate() )
                      setMonthYear(seventhDay.getMonth() + "/" + seventhDay.getFullYear())
                      timeList(seventhDay)
                      }} className={day === seventhDay.getDate() ? activeClass : inactiveClass  } >
                <h4 className=" lg:text-[16px]  font-[InterRegular] text-[12px]" > {checkDay(seventhDay).slice(0,3)}</h4>   
                     <p className="font-[InterMedium] text-[]  text-[12px] lg:text-[16px]"> {seventhDay.getDate()}</p>  
                    </div>
                </div>
            </div>
             <div className="lg:my-8 md:my-6 my-4" >
                <h4 className="font-[InterBold] text-[#0D1F37] text-[.8rem] md:text-[18px]" >Select start time of service</h4>
        
                <div className="flex  mt-3 gap-2 md:gap-4 items-center flex-wrap justify-start" >
                
                   { timingList?.map((timing)=>(
                    <button  onClick={()=>{
                      setTime(timing)
                      }} className={timing === time ? activeTimingClass : inactiveTimingClass} >
                            <p className="text-[.6rem] lg:text-[1rem]" >{timing}:00 </p>
                        </button>
                   ))
                   }
                 
                </div>
            </div>
            <div className="flex items-center gap-2 md:gap-3 justify-start md:justify-end" >
            
              <button onClick={()=>{
                if(day!=="" && time!==""){
                     handleSetDate()
                     handleRepairInfo()
                }
                else{
                  setErrorDate("Select Date and Time")
                }
               }} className="bg-[#2967B0] px-6 py-3 md:text-[14px]  lg:text-[18px] text-[12px] text-white rounded-lg font-[InterMedium]" >Proceed to Checkout</button>
            </div>
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default TimeModal