import React, { useEffect,useState } from 'react'
import { Checkbox } from '@material-tailwind/react'
import { ref, set, push, onValue,remove, update } from "firebase/database";
import { database } from '../../firebase.config';
const EditAddress = ({setIsDefault,setAddressName,addressName,briefAddress,setBriefAddress,landmark,setLandmark,updateAddressInfo,singleAddress,setSingleAddress,updateAddress}) => {

  

   


    useEffect(() => {
        
    }, [])

  return (
    <div className='bg-white mx-4 my-6 rounded-lg border-gray-300 border-[1px] py-4 px-6'>
        <div>
            <h4 className='font-[InterBold] mt-4 text-[1.3rem] ' >Edit Address</h4>
            <div className='flex items-start my-8  flex-col gap-6 justify-start' >
                <input
                        className="py-3 px-3 w-[350px] font-[InterMedium] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={singleAddress?.name}
                        onChange={(e) => {
                            setSingleAddress({...singleAddress,name:e.target.value})
                        }}
                        
                        placeholder="Name"
                        type="text"
                      />
                      <input
                        className="py-3 px-3 w-[350px] font-[InterMedium] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={singleAddress?.location}
                        onChange={(e)=>{
                            setSingleAddress({...singleAddress,location:e.target.value})
                        }}
                        placeholder="Apartment / Building / Area "
                        type="text"
                      />
                          <input
                        className="py-3 px-3 w-[350px] font-[InterMedium] border-[1px] border-gray-200 outline-none rounded-[10px] "
                       value={singleAddress?.landmark}
                        onChange={(e)=>{
                            setSingleAddress({...singleAddress,landmark:e.target.value})
                        }}
                        placeholder="Landmark(optional) "
                        type="text"
                      />
                    
                      <div>
                         <button
                        onClick={()=>{
                            updateAddress(singleAddress?.id)
                        }}
                        className="bg-[#2967B0] px-3 py-3 rounded-[10px] w-[200px] text-white font-[600] text-[16px] "
                      >
                        Update Address{" "}
                      </button>
                      </div>
            </div>
        </div>
    </div>
  )
}

export default EditAddress