import React,{useEffect} from 'react'
import { Button, Spinner } from '@material-tailwind/react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { clearOrder } from '../actions/repairActions';
import Lottie from 'react-lottie';
import PaymentSuccess from "../lottiees/payment_success.json"
import ReactPixel from "react-facebook-pixel"
const Success = () => {
   const { repairOrder,loading,error } = useSelector(
    (state) => state.repairOrders
  );
  const { deviceInfo } = useSelector(
    (state) => state.modals
  );
   const dispatch = useDispatch();
  const navigate = useNavigate()
  const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: PaymentSuccess,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  useEffect(() => {
    if(error){
      navigate("/cart")
    }
    if(!error && !loading && Object.keys(repairOrder).length === 0){
      navigate("/")

    }
    
    ReactPixel.trackCustom("Order Success", repairOrder?.orderId)
  }, [])
  
  return (
    <div className='flex items-center mx-4  h-[100vh] justify-center mt-[0%] ' >
     { !loading  ? <div className='flex items-center justify-center flex-col' >
        <Lottie 
      
	    options={defaultOptions}
        height={400}
        width={400}
      />
            <h4 className='font-[600] text-[2rem]' >Yay! Order Received</h4>
            <p className='font-[400] my-2 text-[1.2rem] text-center' >Your Order <strong> #{repairOrder?.orderId}</strong> for <br/> {repairOrder?.packData?.packName} is placed.</p>
            <div className='flex items-center flex-wrap justify-center mt-6 gap-6' >
       { repairOrder?.orderId &&  <Link onClick={()=>{
        dispatch(clearOrder())
       }} className='border-[#2967B0] border-[2px] py-3 px-8 rounded-lg text-[#2967B0] font-[500]' to={`/orderDetails/repairOrders/${repairOrder?.orderId}`} > Checkout Order</Link>}
       <Link onClick={()=>{
        dispatch(clearOrder())
       }} className='bg-[#2967B0] border-[#2967B0] border-[2px] py-3 px-12 rounded-lg text-white font-[500]' to="/">Go to Home</Link>
       </div>
        </div> : 
        <div className='' >
          <Spinner />
        </div>
        }
    </div>
  )
}

export default Success