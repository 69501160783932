import React from 'react'
import OtpInput from 'react-otp-input';
import "./Login.css"
import Email from "../../asset/email.png"
import Google from "../../asset/google.png"
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Spinner,
} from "@material-tailwind/react";
import { useSelector, useDispatch } from 'react-redux'
const OTP = ({otp,setOtp,handleMobileRegister,phone,otpLoading}) => {
  const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  return (
    <>
    { !otpLoading ?  (<div className='col-span-1' >
          <CardBody className="flex flex-col gap-4"> 
          <h4 className='font-[InterMedium] text-[1.6rem] text-black' >Enter verification code</h4>
          <p className='mt-[-10px] text-[.9rem] font-[InterRegular] text-black ' >We have sent you a 6 digit verification code on</p>
          <p className='mt-[-15px] text-[.9rem] font-[InterMedium] text-black ' >{phone}</p>
          <div className='mt-6' >
          <OtpInput
      value={otp}
      onChange={setOtp}
      inputStyle="inputStyle"
      numInputs={6}
      inputType='number'
      renderSeparator={<span className='ml-2' >  </span>}
      renderInput={(props) => <input {...props} />}
    />
    {!error && <p>{error}</p>}
     <button onClick={handleMobileRegister}   className="bg-[#2967B0] w-full font-[InterBold] mt-6 text-white py-3 rounded-lg " >
              Verify OTP
            </button>
          </div>
            <CardFooter className="pt-0 border-t-[1px] border-gray-300 mx-3  ">
            
            <p className="mt-3 text-[.8rem] font-[InterMedium] " >By continuing, you agree to Zippy’s Terms of Use. Read our Privacy Policy</p>
          </CardFooter>
          </CardBody>
    </div>) : (
      <Spinner />
    )}
    </>
  )
}

export default OTP