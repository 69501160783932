import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'

const SelectManufacturer = ({laptopRepairList,tabRepairList,mobileRepairList,stage,setStage,manufacturer,setManufacturer,device,filteredModelList,setFilteredModelList}) => {
  return (
      <div>
        <div className='lg:px-36 px-6 py-12  lg:py-24' >
            <div className='flex md:flex-row flex-col-reverse gap-4 items-start justify-between' >
     
    <h4 className='text-[1.4rem] font-[600]' >Select a Manufacturer</h4>
     <div onClick={()=>setStage("select service")} className='flex  text-red-400 font-[600]  cursor-pointer rounded-lg py-2 lg:py-3 px-2 lg:px-4 bg-gray-200 items-center justify-start gap-3 ' >
      <ArrowLeftIcon className='lg:w-[20px] w-[10px] ' />
      <p className='text-[.8rem] lg:text-[1rem]' >Back</p>
    </div>
    </div>
            { device === "Laptops" && laptopRepairList.length !== 0 &&  <div className="flex items-center justify-start flex-wrap gap-3 md:gap-6 mt-6 md:mt-12  " >
           
          {
            laptopRepairList &&  laptopRepairList.map((item)=>(
              <div onClick={()=>{
                setManufacturer(item)
                setFilteredModelList(item?.models)
                setStage("select model")
              
                }} className='flex rounded-lg cursor-pointer items-center border-gray-200 border-[1px] justify-center flex-col px-6 py-2 lg:px-12 lg:py-4' >
                <img className='lg:w-[100px] w-[50px] md:w-[70px]' src={item.image} alt="" />
                <p className='md:text-[1rem text-[.7rem] font-[500] text-gray-500' >{item.brand}</p>
              </div>
            ))
          }
        
        </div>}
        { device === "Tablets" && laptopRepairList.length !== 0 &&  <div className="flex items-center justify-start flex-wrap gap-3 md:gap-6 mt-6 md:mt-12   " >
           
          {
            tabRepairList &&  tabRepairList.map((item)=>(
              <div onClick={()=>{
                setManufacturer(item)
                setFilteredModelList(item?.models)
                setStage("select model")
                
                }} className='flex rounded-lg cursor-pointer items-center border-gray-200 border-[1px] justify-center flex-col px-6 py-2 lg:px-12 lg:py-4' >
                <img className='lg:w-[100px] w-[50px] md:w-[70px]' src={item.image} alt="" />
                <p className='md:text-[1rem text-[.7rem] font-[500] text-gray' >{item.brand}</p>
              </div>
            ))
          }
        
        </div>}
         { device === "Mobiles" && mobileRepairList.length !== 0 &&  <div className="flex items-center justify-start flex-wrap gap-3 md:gap-6 mt-6 md:mt-12    " >
           
          {
            mobileRepairList &&  mobileRepairList.map((item)=>(
              <div onClick={()=>{
                setManufacturer(item)
                setFilteredModelList(item?.models)
                setStage("select model")
                
                }} className='flex rounded-lg cursor-pointer items-center border-gray-200 border-[1px] justify-center flex-col px-6 py-2 lg:px-12 lg:py-4' >
                <img className='lg:w-[100px] w-[50px] md:w-[70px]' src={item.image} alt="" />
                <p className='md:text-[1rem text-[.7rem] font-[500] text-gray' >{item.brand}</p>
              </div>
            ))
          }
        
        </div>}
        </div>
    </div>
  )
}

export default SelectManufacturer