import { PhoneIcon,EnvelopeIcon } from '@heroicons/react/24/solid'
import WhatshappIcon from "../../asset/whatsapp.png"
import React from 'react'
import ReactWhatsapp from 'react-whatsapp';

const ContactInformation = () => {
  return (
    <div className='bg-white my-4 mx-3 rounded-lg px-8 py-4' >
            <div>
                <h4 className='text-black text-[2rem] font-[600]' >Contact Now </h4>
                <p className='text-gray-500' >to reach out</p>
            </div>
            <div className='my-16' >
            <a href="tel:+91 8339833985">
                <div className='flex my-10  items-center justify-start gap-6' >
                    <PhoneIcon className='w-[30px] text-black' />
                    <h4 className='text-black' >Get on call with us</h4>
                </div>
                </a>
                <a href="mailTo:care@zippyindia.in">
                  <div className='flex my-10  items-center justify-start gap-6' >
                    <EnvelopeIcon className='w-[30px] text-black' />
                    <h4 className='text-black' >Send us an email</h4>
                </div>
                </a>
                <ReactWhatsapp number="8282822979" message="Hii" >
                  <div className='flex my-0  items-center justify-start gap-6' >
                    <img className='w-[30px]' src={WhatshappIcon} alt="" />
                    <h4 className='text-black' >Connect on WhatsApp</h4>
                </div>
                </ReactWhatsapp>
            </div>
        </div>
  )
}

export default ContactInformation