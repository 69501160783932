import React from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'

const TermsandCondition = () => {
  return (
    <div>
        <Navbar />
        <div className='my-12 mt-[80px] mx-8' >
            <p>
          <strong>   Product Terms and Conditions.</strong>
<br />
Please read this document carefully. It sets out the information and terms and conditions of
Your contract with Zippy Membership services.
<br /><br />
<strong>General Conditions. </strong>
<br />
Before handing over the device to pick up executive, you shall read below terms and conditions in detail.
1. This Agreement shall commence from the date you book a repair and shall continue until we have repaired or otherwise returned your Device, whichever is sooner, and received any payment due from you.
<br />
2. We shall make reasonable efforts to repair your Device subject to the availability of any parts required and/or the terms of any relevant guarantee or warranty. We shall perform the Service using our utmost care and skill.
<br />
3. We shall use Genuine, OEM or High-Quality compatible parts for the repairs of all Devices as per the requirement of customer, and shall notify you about the same.
<br />
4. We shall notify you when the Device has been repaired. If necessary, we shall send a reminder and the device will be recycled if not collected by day 90 to cover our costs.
<br />
5. Is responsible to back up all the data and software/app residing on your device whether in working condition or not.
<br />
6. User/customer is responsible to un-install all add-ons and/or accessories from your device and maintain backup copy of all software’s/apps/contacts and data stored in your device, before providing the same to us. User/customer shall take backup of all data and erase all personal and confidential data from your device before handing over to us.
<br />
7. User/customer is to handover device only after removing all passwords/Locks in your device. Please Sign out of Apple ID, Cloud in case its Apple device.
<br />
8. User/customer is responsible for backing up of Data/ Software/Firmware/OS/paid software/free software/apps and charges involved in recovery/installation etc of your device.
<br />
9. Kindly take careful note that during repair process, Our Service Centre/OEM repair center may delete some or all the contents stored on your device and reformat the storage media if so required.
<br />
10. During diagnosis, repairs and conducting other services, it might happen that the data on device may get corrupted, wiped out, lost, and may not be recovered at all, for which we shall not be responsible.
<br />
11. Service Centre may install latest software/app updates as part of the fulfilment of the service, which may prevent your device from reverting to an earlier version of the OS.
<br />
12. Third party applications installed on your device may not be compatible or work with your device as a result of the OS or other updates. You shall be solely responsible for reinstalling all other software programs, apps, data and passwords.		
<br />
13. If we are unable to complete the Service for any reason, or the Service will incur further costs payable by you beyond that initially estimated by us, we will notify you immediately via telephone and/or email. If no fault is found on your Device or you do not accept our revised estimate, we will return your Device to you unrepaired and we reserve the right to charge you an inspection fee in accordance with our standard charges
<br />
14. We do not take responsibility for any progression in damage for Devices that have been damaged by liquid. Although unlikely, opening the Device could set off further damage and, in some cases, may be very serious and irreparable.
<br />
15. Neither company employees, directors and agents, Service Centers will under any circumstances be held liable to you, for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages or defects, including but not limited to costs of recovering, reprogramming, or reproducing any program, software, app or data or the failure to maintain the confidentiality of data, any loss of business, profits, revenue, data, software, app, product key, subscriptions, passwords or anticipated savings, resulting from obligations under these services.

            </p>
        </div>
        <Footer bg="#0D1F37" />
    </div>
  )
}

export default TermsandCondition