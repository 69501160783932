import React,{useState,useEffect} from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import CartLeft from '../Components/Cart/CartLeft'
import CartRight from '../Components/Cart/CartRight'
import CartModel from '../Components/Cart/CartModel'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { db } from '../firebase.config'
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import { saveOrder } from '../actions/shieldActions'
const ShieldCart = () => {
    const [openModal, setOpenModal] = useState(false)
    const navigate = useNavigate()
     const {  isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
    const { deviceInfo } = useSelector(
    (state) => state.shieldModals
  );
  const {error, loading  } = useSelector(
    (state)=>state.shieldOrders
  )
  const dispatch = useDispatch();
  const [repairOrderList, setRepairOrderList] = useState([])
  const [paymentMethod, setPaymentMethod] = useState("")
  const [orderId, setOrderId] = useState("")
  const handleOrder = () =>{
    const date = new Date()
    if(deviceInfo &&  paymentMethod.length !== 0 ){
      dispatch(saveOrder({...deviceInfo,
    paymentData: {
      paymentMethod : paymentMethod,
      paymentStatus : "Paid",
      transactionId : "86833bjkqhoih8",
      paidAmt : "199"
    },
     personalData : {
      fullName : userProfile?.FirstName,
      emailId : userProfile?.email,
      contactNo : userProfile?.ContactNo
    },
    orderId: "ZIPS" + new Date().getFullYear() + (repairOrderList.length +1),
    createdAt:date,
    userUid : users && users ,
  },users,"ZIPS" + new Date().getFullYear() + (repairOrderList.length +1)))
  if(!error){
    navigate("/shieldSuccess")
  }
  else{
    alert('Error Occurred',error)
  }
    }
  

  }
    const fetchRepairOrder =  async () =>{
    console.log("newOrder")
   const querySnapshot = await getDocs(collection(db, "shieldOrders"));
    querySnapshot.forEach((doc) => {
     setRepairOrderList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
     
    
     
     
  
    });
    }
    console.log()
    const handleOpenModal = () =>{
        setOpenModal(!openModal)
    }
    useEffect(() => {
      fetchRepairOrder()
    }, [])
  return (
    <div>
        <Navbar />
        <CartModel openModal={openModal} handleOpenModal={handleOpenModal}  />
        <div className='mt-[80px] font-[Inter]'>
                <div className='md:grid grid-cols-3 gap-6 grid-flow-col px-4 md:px-8 lg:px-16 py-4' >
                    <CartLeft deviceInfo={deviceInfo} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} handleOrder={handleOrder} handleOpenModal={handleOpenModal} />
                    <CartRight />
                </div>
             <Footer bg="#0D1F37"/>
        </div>
   
    </div>
  )
}

export default ShieldCart