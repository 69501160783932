import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import CrossIcon from "../../asset/crossIcon.svg"
import { LockClosedIcon } from "@heroicons/react/24/outline";
const AccessModal = ({confirmModal,handleConfirmModal,}) => {
  return (
    <div>
      <Dialog
        size="sm"
        open={confirmModal}
        handler={handleConfirmModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto relative grid py-2 lg:px-0  lg:py-2   w-full ">
        <div onClick={handleConfirmModal} className="absolute cursor-pointer top-4 right-[10px] w-[40px] h-[40px]" >
          <img src={CrossIcon} alt="" />
        </div>
          <CardBody className="flex flex-col gap-0">
          <div className="flex items-start gap-3 justifystart" >
            
            <div>
            <h4 className="font-[InterBold]  flex items-center justify-start gap-3 text-[#0D1F37] text-[1.2rem] md:text-[24px]">
          <LockClosedIcon className="text-blue-600 w-[30px]" />    Login
            </h4>
            <p className="font-[InterMedium] mt-3" >Login to Access this Resource </p>
            </div>
          </div>
           <div className="mt-0" >
           
           
            <div className="mt-2" >
            
             
            </div>
           </div>
           
           
            
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default AccessModal
