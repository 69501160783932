import React from 'react'
import Zippy from "../../asset/Logo.png"
import Mobile from "../../asset/mobile.png"
import Service from "../../asset/service.png"
import PriceIcon from "../../asset/priceIcon.png"
import Schedule from "../../asset/schedule.png"
import Repaired from "../../asset/repaired.png"
import Delivered from "../../asset/delivered.png"
import Form from "../../asset/form.png"
import { Swiper, SwiperSlide } from "swiper/react";
import {Button} from "@material-tailwind/react"

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import LineDown from "../../asset/lineDown.svg"
import LineUp from "../../asset/lineUp.svg"
const RepairSteps = ({pinCodeModal, setPinCodeModal}) => {
  return (
    <div  className='xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]  z-[-1] mt-[13.5rem] xl:mt-[50px]  md:mt-[30px] py-8 xl:py-[30px]'>
        <div className='pt-8  md:px-0 md:pt-0' >
            <h3  className='lg:text-[48px] md:text-[35px] text-[30px] font-[InterBlack] font-[700] text-[#DE562C]    ' >How it Works</h3>
            <p className='lg:font-[16px] font-[12px] font-[InterMedium] mt-1 text-[#18191F]' >Our Skilled Technicians to Your Rescue</p>
        </div>
     
           <div className='flex mt-4 md:items-center gap-4 md:gap-2 justify-start flex-col  md:flex-row' >
             <div className='bg-white xl:w-[300px]   xl:h-[300px]    rounded-[16px]  ' >
             <div className='flex items-center justify-start gap-3 ' >
               <img src={PriceIcon} className='lg:w-[30px] md:w-[15px] w-[25px] ' alt="" />
               <h4 className='font-[InterMedium] text-[#0D1F37] text-left mt-6 mb-2 lg:text-[18px] md:text-[14px] xl:text-[24px]' > Check Price</h4>
               </div>
               <div>
                <p className=' lg:text-[12px] text-[#393939] md:text-[10px] xl:text-[16px] font-[InterRegular]'>Tell us which phone has to be repaired. Get the best <br className='hidden lg:block'/> pricing.</p>
                </div>
            </div>
          <div>
            <img className='w-[120px] md:block hidden' src={LineDown} alt="" />
          </div>
      
     
             <div className='bg-white xl:w-[280px] xl:h-[300px]    rounded-[16px]   ' >
             <div className='flex items-center justify-start gap-3 '>
               <img src={Schedule} className='lg:w-[30px] md:w-[15px] w-[25px] ' alt="" />
                <h4 className='font-[InterMedium] text-[#0D1F37] text-left mt-6 mb-2 lg:text-[18px] md:text-[14px] xl:text-[24px]' > Schedule</h4>
                </div>
                <p className='lg:text-[12px] text-[#393939] md:text-[10px] xl:text-[16px] font-[InterRegular]' >Book a free pickup from your home or work at a time slot as per your convenience</p>
            </div>
           <div>
            <img className='w-[120px] md:block hidden' src={LineUp} alt="" />
          </div>
       
               <div className='bg-white xl:w-[280px]  xl:h-[300px]   rounded-[16px]  ' >
               <div className='flex items-center justify-start gap-3 '>
               <img src={Repaired} className='lg:w-[30px] md:w-[15px] w-[25px] ' alt="" />
                <h4 className='font-[InterMedium] text-[#0D1F37] text-left mt-6 mb-2 lg:text-[18px] md:text-[14px] xl:text-[24px]' > Get Repaired</h4>
                </div>
                <p className='lg:text-[12px] text-[#393939] md:text-[10px] xl:text-[16px] font-[InterRegular]'>Sit back and relax, Our super-skilled technician will be there and make it as good as new.</p>
            </div>
            <div>
            <img className='w-[120px] md:block hidden' src={LineDown} alt="" />
          </div>
          
              <div className='bg-white xl:w-[280px]  xl:h-[300px] rounded-[16px]   ' >
              <div className='flex items-center justify-start gap-3 '>
               <img src={Delivered} className='lg:w-[30px] md:w-[15px] w-[25px] ' alt="" />
                <h4 className='font-[InterMedium] text-[#0D1F37] text-left mt-6 mb-2 lg:text-[18px] md:text-[14px] xl:text-[24px]' > Delivered</h4>
                </div>
                <p className='lg:text-[12px] text-[#393939] md:text-[10px] xl:text-[16px] font-[InterRegular]'>Tell us which phone has to be repaired. Get the <br className='hidden lg:block' /> best pricing.</p>
            </div>
       
           </div>
        
            
     
      
    </div>
  )
}

export default RepairSteps