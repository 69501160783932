import React, { useState, useEffect } from "react";
import ManufacturerBanner from "./ManufacturerBanner";
import SelectModel from "./SelectModel";
import Navbar from "../Layout/Navbar";
import { useDispatch, useSelector } from "react-redux";
import StepsToRepair from "./StepsToRepair";
import DownloadApp from "../Home/DownloadApp";
import BelowCTA from "../Home/BelowCTA";
import Footer from "../Layout/Footer";
import IssuesModel from "./IssuesModel";
import { database } from "../../firebase.config";
import { onValue, ref } from "firebase/database";
import { saveRepairDevice } from "../../actions/repairActions";
import SelectColor from "./SelectColor";

const RepairModels = () => {
  const { error, loading, isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  );

  const { deviceInfo } = useSelector((state) => state.modals);
  const [model, setModel] = useState(deviceInfo?.deviceData?.modelName);
  const dispatch = useDispatch();
  const [device, setDevice] = useState(deviceInfo?.deviceData?.deviceType);
  const [openModal, setOpenModal] = useState(false);
  const [color, setColor] = useState("");
  const [audioDetails, setAudioDetails] = useState("");
  const [activeColor, setActiveColor] = useState(false);
  const [issueList, setIssueList] = useState([]);
  const [issue, setIssue] = useState([]);
  const [problemsList, setProblemsList] = useState([]);
  const [issueDescription, setIssueDescription] = useState("");
  const handleColorModal = () => {
    setActiveColor(!activeColor);
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const [manufacturer, setManufacturer] = useState(
    deviceInfo?.deviceData?.manufacturerDetails
  );
  const [filteredModelList, setFilteredModelList] = useState(
    deviceInfo?.deviceData?.manufacturerDetails?.models
  );

  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedList = [...manufacturer?.models];
    updatedList = updatedList.filter((item) => {
      return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredModelList(updatedList);
  };
  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);

    setAudioDetails(url);
    console.log(audio, url);
  };
  const issuesList = () => {
    const repairCountRef = ref(database, "issueList");

    onValue(repairCountRef, (snapshot) => {
      const data = snapshot.val();
      const newProducts = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
      setProblemsList(newProducts);
    });
  };
  const handleRepairInfo = () => {
    dispatch(
      saveRepairDevice({
        problemList: issueList && issueList,
        problemDesc: issueDescription,
        recordedProblems: audioDetails,
        issue:issue,
        deviceData: {
          deviceType: device,
          brandName: manufacturer?.brand,
          manufacturerDetails: manufacturer,
          modelName: model,
          deviceColor: color,
          devicePurchaseDate: "",
          imeiNo: "",
          devicePrice: "",
          purchaseBill: "",
        },
        
      })
    );
  };
  console.log(issueList)
  useEffect(() => {
    issuesList();
   if(deviceInfo?.deviceData?.modelName!==undefined){
    handleOpenModal()
   }
  }, []);
  console.log(deviceInfo?.deviceData?.modelName)
  return (
    <div>
      {openModal && (
        <IssuesModel
          AudioDetails={audioDetails}
          setAudioDetails={setAudioDetails}
          addAudioElement={addAudioElement}
          activeColor={activeColor}
          setActiveColor={setActiveColor}
          color={color}
          setColor={setColor}
          issueDescription={issueDescription}
          setIssueDescription={setIssueDescription}
          issueList={issueList}
          setIssueList={setIssueList}
          issue={issue}
          setIssue={setIssue}
          problemsList={problemsList}
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          handleRepairInfo={handleRepairInfo}
        />
      )}
      <SelectColor color={color} setColor={setColor} handleColorModal={handleColorModal} activeColor={activeColor} />
      <Navbar />
      <div className="lg:mt-[80px] mt-[100px] md:mt-[120px]">
        <ManufacturerBanner device={device} manufacturer={manufacturer} />
        <SelectModel
          filterBySearch={filterBySearch}
          filteredModelList={filteredModelList}
          setFilteredModelList={setFilteredModelList}
          model={model}
          setModel={setModel}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
        <StepsToRepair />
       
        <DownloadApp />
        <BelowCTA />

        <Footer bg="#0D1F37" />
      </div>
    </div>
  );
};

export default RepairModels;
