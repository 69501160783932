import React from 'react'
import SelectBanner from "../../asset/shield/laptopxtended.png"
const SelectDeviceBanner = () => {
  return (
    <div className='xl:px-60 flex gap-6 items-center justify-between bg-gradient-to-r from-[#e0572d76] to-[#FFFFFF]  px-4 py-4 xl:py-12' >
        <div>
            <h4 className='lg:text-[2rem] text-[1.3rem] font-[700]' >Ultimate Device <br /> Protection</h4>
        </div>
        <div className='flex items-center justify-center gap-3' >
        <div>
            <img className='lg:w-[200px] w-[150px] ' src={SelectBanner} alt="" />
            </div>
            <div>
              <h4 className='font-[600] text-[1rem] lg:text-[1.3rem]' >Buy Insurance</h4>
              <p className='text-[.6rem] lg:text-[.8rem]' >(Device purchased within 6 months)</p>
            </div>
        </div>
    </div>
  )
}

export default SelectDeviceBanner