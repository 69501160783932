import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Repair from "../../asset/repair.png"
const CartModel = ({openModal,handleOpenModal}) => {
  return (
      <div>
      <Dialog
        size="sm"
        open={openModal}
        handler={handleOpenModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto grid px-4 py-6   w-full ">
          <CardBody className="flex flex-col gap-4">
           <div>
                <img className="w-[80px] mt-1 mr-3" src={Repair} alt="" />
            </div>
          <div className="flex items-start justifystart" >
           
            <div>
                 <h4 className="font-[600] text-black text-[1.5rem]">
              Zippy on the Spot
            </h4>
             <p>
             Our Zipster will evaluate the issue and if the device requires additional or different service, then the cost may differ.
            </p>
            </div>
          </div>
           <div className="mt-2" >
           <ul className="list-disc text-black" >
            <li className="font-[500] mt-2" >100% Genuine parts</li>
            <li className="font-[500] mt-2" >Upto 6 months of warrenty</li>
            <li className="font-[500] mt-2" >1 year of warrenty</li>
           </ul>
           <div className="mt-3" >
            <p className="text-black font-[600] text-[2rem]" >₹499 <span className="text-gray-700 font-[400] text-[1rem]">(plan cost)</span>  </p>
           </div>
           </div>
           
           
            
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default CartModel