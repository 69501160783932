import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { MapPinIcon } from '@heroicons/react/24/solid'
import React,{Fragment} from 'react'
import { Popover, Transition } from '@headlessui/react'
const ManageAddress = ({addresses,removeAddresss,activeSection,setActiveSection,addressId,setAddressId,fetchSingleAddress,handleAddressModal,handleEditAddressModal}) => {
  return (
    <div className='bg-white mx-4 my-6 rounded-lg border-gray-300 border-[1px] py-4 px-6' >
        <div>
        <div className='flex items-center justify-between' >
            <h4 className='font-[InterBold] text-[1.2rem]' >Saved Addresses</h4>
            <div>
              <button onClick={handleAddressModal} className='px-6 py-2 bg-[#2967B0] font-[InterMedium] text-white rounded-lg' >Add Address</button>
            </div>
            </div>
            <div className='flex mt-4 flex-col items-center justify-start' >
             { addresses?.map((address)=>(
                <div className='flex w-full py-4 px-3 border-b-[1px] border-gray-300 items-center justify-between' >
                    <div className='flex items-center justify-start' > 
                    <div>
                        <MapPinIcon className='mr-3 text-[#2967B0] w-[30px]' />
                    </div>
                        <div>
                            <h4 className='font-[InterBold] text-[14px] text-[#0D1F37] ' >{address?.name}</h4>
                            <p className='text-[16px] font-[InterRegular] text-[#686868]' >{address?.location + ", " + address?.landmark}</p>
                            <p className='text-[16px] font-[InterRegular] text-gray-600' >{address?.shortAddress + ", +91-"  + address?.alternameNumber}</p>
                        </div>
                    </div>
                    <div>
                     
                        <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md bg-transparent px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >

             {/* <UserCircleIcon className='w-[40px] text-[#DE562C] '  /> */}
                <EllipsisVerticalIcon className='text-gray-500 cursor-pointer w-[30px]' />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-[150px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 font-[InterMedium] ring-black ring-opacity-5">
                  <div className=" bg-white  ">
                  <div onClick={()=>{
                    handleEditAddressModal()
                    fetchSingleAddress(address?.id)
                    }} className='border-b-[1px] cursor-pointer border-gray-300 py-2 px-3' >
                   <h4>Edit</h4>
                  </div>
                   <div onClick={()=>{
                    removeAddresss(address?.id)
                   }} className=' py-2 cursor-pointer px-3' >
                   <h4>Delete</h4>
                  </div>
                  </div>
                 
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
                    </div>
                </div>
             ))  }
            </div>
        </div>
    </div>
  )
}

export default ManageAddress