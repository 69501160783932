import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
const FAQ = () => {
     const faqs=[
        {
            question:"Why Zippy?",
            answers:"You will be given the best possible quote for your mobile repair, which we can assure will be the best price for genuine parts. Zippy repair technicians will then arrive at an address and repair the smartphone on the spot. We even offer a 6-month guarantee."
        },
        {
             question:"How can I book ‘Zippy’s Mobile repair services?",
            answers:"To book Zippy’s mobile repair services, you can Download our Zippy Mobile app on play store or visit our website (www.zippyrepair.in) once you provide details about your phone's issue, a technician will be assigned to visit your doorstep and fix the issue."
        },
         {
             question:"What types of issues can be resolved using a Zippy app?",
            answers:"A Zippy app can help you resolve various issues, including software glitches, slow performance, network connectivity problems, battery drain issues, screen or display malfunctions, and other common mobile device problems. "
        },
         {
             question:"Which Smartphone brands does Zippy repair",
            answers:"Zippy specializes in repairing various smartphone brands, including Apple, One Plus, Samsung, Vivo, Redmi, Xiaomi, Oppo, Real me, and many more. They use the latest supplies and equipment to diagnose and repair smartphones, android phones, iPhones, and tablets. "
        },
         {
             question:"How does Zippy ensure fast and efficient mobile repair service?",
            answers:"Zippy uses the latest and distinctive supplies and equipment to diagnose and repair your smartphones, android phones, iPhones, and tablets. Our team of experts has comprehensive knowledge and expertise in the field, which enables us to deliver high-quality services to our customers. "
        },
         {
             question:"Do you provide any warranty for your repair services?",
            answers:"Yes, we provide a warranty for all our repair services, which varies depending on the type of repair service. Our warranty covers from 6 months with a Genuine Parts Warranty and 3 months with an Ordinary Parts Warranty. "
        },
         {
             question:"What other services are offered?",
            answers:"We offer sell for Refurbished Mobiles phones and mobile accessories in B2B and B2C marketplace.  "
        },
         {
             question:"What does Zippy shield typically cover?",
            answers:"Accidental damage: Coverage for accidental drops, spills, and physical damage to the device. "
        },
         {
             question:"Are there additional charges applicable for Extended Warranty plans during repair requests?",
            answers:"No, raising a repair request during the Extended Warranty is completely free. All the costs are covered as part of the plan. "
        },
         {
             question:"Which mobile protection plan is best suited for me?",
            answers:"if you want your mobile phone to work smoothly for at least 2 years, you should ideally get Zippy Shield damage protection along with a mobile extended warranty plan for your smartphone. "
        },
         {
             question:"When should I protect the Smartphone?",
            answers:"Do not delay it. Purchase Zippy Shield damage protection on the within the three days of your device purchase. "
        },
    ]
  return (
     <div  className='xl:px-[130px] px-[20px] xl:py-[80px]'> 
        <div>
            <h3 cla className='text-[2rem] font-[inter] font-[600]   px-3 ' >Get Your Questions Answered</h3>
        </div>
        <div className='bg-white px-0 py-8 rounded-[16px] my-2' >
             {
            faqs && faqs.map((faq,key)=>(
                  <Disclosure className="" >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full my-6 font-[Neue Haas Grotesk Display Pro] justify-between  bg-white border-b-[1px] border-[#d0d0d0] px-3  xl:px-6 xl:py-4 py-2 text-left text-[14px] xl:text-lg font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className='font-[inter]'>{faq.question}</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 font-[inter] pb-2 text-lg text-gray-500">
                {faq.answers}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
            ))
        }
        </div>
    </div>
  )
}

export default FAQ