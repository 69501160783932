import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import { ArrowLeftCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
const SelectPack = ({filteredList,handleAddressOpenModal,plan,setPlan,stage,setStage}) => {
    console.log(filteredList)
  return (
    <div>
        <div className='lg:px-28 px-4 py-12  lg:py-24'>
             <div className='flex md:flex-row flex-col-reverse gap-4 items-start justify-between' >
     
    <h4>Select a Pack</h4>
     <div onClick={()=>setStage("select model")} className='flex  text-red-400 font-[600]  cursor-pointer rounded-lg py-2 lg:py-3 px-2 lg:px-4 bg-gray-200 items-center justify-start gap-3 ' >
      <ArrowLeftIcon className='lg:w-[20px] w-[10px] ' />
      <p className='text-[.8rem] lg:text-[1rem]' >Back</p>
    </div>
    </div>
            
         { filteredList.length !== 0 &&   <Swiper
          spaceBetween={6}
          className="mt-8"
          draggable={true}
          breakpoints={{
         300: {
          width: 300,
          slidesPerView: 1,
        },
        370: {
          width: 370,
          slidesPerView: 1,
        },
        780: {
          width: 780,
          slidesPerView: 2.3,
        },
       1200: {
          width: 1200,
          slidesPerView: 2.8,
        },
         1300: {
          width: 1300,
          slidesPerView: 2.8,
        },
         1440:{
          width:1440,
          slidesPerView:3
        }
      }}
          autoplay={{
            delay: 3000,
            disableOnInteraction:true,
          }}
          loop={false}
          modules={[Autoplay]}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
            {
                    filteredList?.map((item)=>(
                        <SwiperSlide>
                        <div className='py-6  px-6 lg:w-[380px] md:w-[300px]  w-[90%] rounded-lg border-gray-300 border-[1px] '>
                            <div className='pb-4 border-gray-300 border-b-[1px]' >
                                <h4 className='font-[700] text-[1.5rem] text-black' >{item?.PackName}</h4>
                            </div>
                            <div>
                               <ul className='list-disc px-4 my-4 ' >
                                {
                                    item?.features?.map((feature)=>(
                                        <li className='text-[.9rem] my-2' >{feature}</li>
                                    ))
                                }
                               </ul>
                               <div>
                                <h4 className='text-[#2967B0] text-[1.1rem] font-[600] ' >₹{item?.price} Plan Cost</h4>
                                <button onClick={()=>{
                                    setPlan(item)
                                    handleAddressOpenModal()
                                }} className='border-[1px] hover:bg-[#2967B0] hover:text-white border-[#2967B0] my-6 font-[500] py-2 px-6 text-[#2967B0] rounded-lg' >Select Plan</button>
                               </div>
                            </div>
                        </div>
                        </SwiperSlide>
                    ))
                }
        </Swiper>}
                
          
        </div>
    </div>
  )
}

export default SelectPack