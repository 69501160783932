import React from 'react'
import BuyInsurance from "../../asset/shield/laptopxtended.png"
import ClaimInsurance from "../../asset/shield/xtendedcare.png"
import { Link } from 'react-router-dom'
const SelectService = () => {
  return (
    <div className='py-12 px-12 lg:px-36' >
        <div>
            <h4 className='lg:text-[2rem] text-[1.5rem] font-[600]' >What are you waiting for? </h4>
            <p className='lg:text-[1rem] text-[.8rem] text-gray-600 mt-2 font-[400]'>Choose a plan, protect your devices, and breathe easy with Zippy Shield!</p>
            <div className='mt-12 gap-12 flex flex-col md:flex-row items-center justify-center' >
            <Link to="/shieldFlow" >
                <div className='px-4 grid grid-cols-3 items-center gap-8 grid-flow-col py-8 rounded-lg bg-[#FFF275]' >
                    <div className='col-span-1 pl-2' >
                        <img className='w-[140px]' src={BuyInsurance} alt="" />
                    </div>
                    <div className='col-span-2' >
                        <h4 className='font-[500] text-[1.5rem] ' >Buy Insurance</h4>
                        <p className='text-[.9rem]' >(Device purchased within 6 months)</p>
                    </div>
                </div>
                </Link>
                 <div className='px-4 grid grid-cols-3 items-center gap-8 grid-flow-col py-8 rounded-lg bg-[#fff17575]' >
                    <div className='col-span-1 pl-2' >
                        <img className='w-[140px]' src={ClaimInsurance} alt="" />
                    </div>
                    <div className='col-span-2' >
                        <h4 className='font-[500] text-[1.5rem] ' >Claim Insurance</h4>
                        <p className='text-[.9rem]' >(Screen protection, Screen protection, Screen protection )</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SelectService