import React,{useState,useEffect} from 'react'
import Navbar from '../Components/Layout/Navbar'
import ShieldOrder from '../Components/Profile/ShieldOrders'
import { ref, onValue, get,remove} from "firebase/database";
import {database,db} from "../firebase.config"
import { collection, query, where, getDocs } from "firebase/firestore";
import { useSelector } from 'react-redux';
import Footer from '../Components/Layout/Footer';

const MyShield = () => {
    const [shieldOrderList, setShieldOrderList] = useState([])

   const { error, loading, isAuthenticated,user, } = useSelector(
    (state) => state.user
  );
   const { userProfile } = useSelector(
    (state) => state.userProfile
  );
    console.log(userProfile.uid)
  useEffect(() => {
    const fetchOrders = async () =>{
         const q = query(collection(db, "shieldOrders"), where("data.userId", "==", user.uid));
       console.log("newOrder")
 
   
  const querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
     setShieldOrderList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
  
    });
    }
    fetchOrders()
  }, [user?.uid]) 
  console.log(shieldOrderList)
  return (
    <div>
        <Navbar />
        <div className='mt-[80px]' >
    <ShieldOrder shieldOrderList={shieldOrderList} />
    </div>
     <Footer bg="#0D1F37" />
    </div>
  )
}

export default MyShield