
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
  
} from "@material-tailwind/react";
const PhoneEmail = ({phoneEmail,handlePhoneEmail,phone,setPhoneEmail,fullName,setFullName,email,setEmail,handleLoginRegister}) => {

    

  return (
    <div>
     
      <Dialog
        size="sm"
        open={phoneEmail}
        handler={handlePhoneEmail}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto   w-full ">
          <CardBody className="">
         
            <div className="md:mx-6 mx-0 mt-2" >
              <h4 className="text-[1.5rem] text-black font-[600]  " >Register User Details</h4>
             <div>
             <div className="my-6 w-full " >
           
            
                </div>
                <div className="my-6">
                <Input type="text" value={fullName} onChange={(e)=>setFullName(e.target.value)}  className="" label="Name" />
                
                  {/* <p className="mt-2 text-red-600" >{!imeiChange && "Please Enter IMEI Number "} {  <p className="mt-2 text-red-600" >{imeiError && imeiChange && "Please Enter Correct IMEI Number "}</p>}</p>
                  */}
                
                </div>
                <div className="my-6">
              <Input type="text" value={email} onChange={(e)=>setEmail(e.target.value)}  className="" label="Email" />
                
                  {/* <p className="mt-2 text-red-600" >{!imeiChange && "Please Enter IMEI Number "} {  <p className="mt-2 text-red-600" >{imeiError && imeiChange && "Please Enter Correct IMEI Number "}</p>}</p> */}
                </div>
                 <div className="my-6">
               
                </div>
             </div>
             <div className="flex items-center mt-12 mb-8 justify-center" >
                <button onClick={handleLoginRegister} className="py-3 px-6 mx-4 w-[150px] text-white rounded-lg font-[600] bg-blue-600 " >Continue</button>
             </div>
             </div>
            
              
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default PhoneEmail