import React from 'react'
import Navbar from "../Components/Layout/Navbar"
import Footer from "../Components/Layout/Footer"

import { useEffect,useState } from 'react';
import { ref, onValue, get} from "firebase/database";
import {database} from "../firebase.config"
import ShieldBanner from '../Components/Shield/ShieldBanner';
import SelectService from '../Components/Shield/SelectService';
import HowItWorks from '../Components/Shield/HowItWorks';
import DownloadApp from '../Components/Home/DownloadApp';
import BelowCTA from '../Components/Home/BelowCTA';
import FAQ from '../Components/Shield/FAQ';
import axios from "axios"
const ZShield = () => {
  
   useEffect(() => {
    
 


  },[] )
  return (
    <div className=' font-[Inter]' >
        <Navbar />
        <div className='lg:mt-[80px] mt-[80px] md:mt-[100px] ' >
        <ShieldBanner />
        <SelectService />
        <HowItWorks />
        <FAQ />
        <DownloadApp />
        <BelowCTA />
        {/* <ShieldBannerOne />
        <ShieldProducts shieldProductsList={shieldMobileProductsList} />
        <ShieldProductTwo shieldProductsList={shieldLaptopProductsList} />
        <ShieldWorks />
        <Faqs />
        <DownloadApp /> */}
        </div>
        <Footer bg="#DE562C" />
    </div>
  )
}

export default ZShield