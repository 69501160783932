import { UserIcon, UserCircleIcon, ShoppingBagIcon, HomeIcon, InformationCircleIcon, MapPinIcon } from '@heroicons/react/24/outline'
import React,{useEffect} from 'react'
import AddressIcon from "../../asset/addressIcon.svg"
import ProfileIcon from "../../asset/profileIcon.svg"
import LogoutIcon from "../../asset/logoutIcon.svg"
import OrderIcon from "../../asset/orderIcon.svg"
const ProfileLeft = ({userProfile, updateProfileInfo, isOpen, setIsOpen, name,email,phone, address, setName,setEmail,setPhone,setAddress,activeSection,setActiveSection,isAuthenticated,handleConfirmModal}) => {
    
    const activeButton = "py-4 px-4 w-full rounded-lg  my-1 flex items-center font-[InterMedium] text-black text-[.9rem] bg-[#2968b018] justify-start "
    const inActiveButton = "py-4 px-4 w-full rounded-lg  my-1 flex items-center font-[InterMedium] text-[#686868] text-[14px] hover:bg-[#2968b018] justify-start "
    const disabledButton = "py-4 px-4 w-full rounded-lg  my-1 flex items-center font-[InterMedium] text-[#686868] text-[14px] bg-gray-200 justify-start"
    useEffect(() => {
     setActiveSection(sessionStorage.getItem("profileStatus"))
    }, [sessionStorage.getItem("profileStatus")])
  return (
    <div className='col-span-1 py-4 px-3  border-t-[1px] border-r-[1px] border-gray-300 ' >
      <div className='flex items-start justify-start flex-col' >
        <button onClick={()=>{
          if(isAuthenticated){
            setActiveSection("profile")
          }
         else{
          handleConfirmModal()
         }
        }} className={activeSection === "profile" ? activeButton : inActiveButton } ><img className='w-[15px] mr-3' src={ProfileIcon} alt="" /> Profile</button>
      <button onClick={()=>{
          if(isAuthenticated){
            setActiveSection("orders")
          }
         else{
          handleConfirmModal()
         }
        }} className={activeSection === "orders" ? activeButton : inActiveButton } ><img className='w-[15px] mr-3' src={OrderIcon} alt="" /> Order</button>
      <button onClick={()=>{
          if(isAuthenticated){
            setActiveSection("address")
          }
         else{
          handleConfirmModal()
         }
        }} className={activeSection === "address" ? activeButton : inActiveButton } ><img className='w-[15px] mr-3' src={AddressIcon} alt="" /> Manage Address</button>

      <button onClick={()=>setActiveSection(("help"))} className={activeSection === "help" ? activeButton : inActiveButton } ><InformationCircleIcon className='w-[20px] mr-2' /> Help </button>
      </div>
    </div>
  )
}

export default ProfileLeft