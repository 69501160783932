import React from 'react'
import About from "../../asset/about.png"
import AboutText from "../../asset/aboutText.svg"
import VisionText from "../../asset/visionText.svg"
import VisionImage from "../../asset/visionImage.png"

const Vision = () => {
  return (
      <div className='md:grid grid-cols-2 grid-flow-col' >
      <div className='bg-[#59a4ff3f] py-6  lg:py-28 px-8 xl:px-36 lg:px-16' >
        <img className='my-4 ml-[-15px] md:ml-[-40px]' src={AboutText} alt="" />
        <p className='font-[InterMedium]' >Zippy is a leading gadget repair service provider. We understand the needs of our special customers. Our team of skilled technicians, known as Zippie, are experts in their field and can perform magic right in front of your eyes.</p>
        <div className='flex items-center justify-center' >
        <img className='lg:w-[300px]' src={About} alt="" />
        </div>
      </div>
      <div className='bg-[#FFC9B8] px-8 py-6 lg:py-28 xl:px-36 lg:px-16' >
      <img className='my-4 ml-[-15px] md:ml-[-40px]' src={VisionText} alt="" />
        <p className='font-[InterMedium]' >Zippy is a leading gadget repair  service provider. We understand the needs of our special customers. Our team of skilled technicians, known as Zippie, are experts in their field and can perform magic right in front of your eyes.</p>
        <div className='flex items-center mt-16 justify-center' >
        <img className='lg:w-[300px]' src={VisionImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Vision