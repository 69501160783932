import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import CrossIcon from "../../asset/crossIcon.svg"
const ConfirmDialog = ({confirmModal,handleConfirmModal,handleCancelOrder,orderId}) => {
  return (
    <div>
      <Dialog
        size="sm"
        open={confirmModal}
        handler={handleConfirmModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto relative grid py-2 lg:px-0  lg:py-2   w-full ">
        <div onClick={handleConfirmModal} className="absolute cursor-pointer top-4 right-[10px] w-[40px] h-[40px]" >
          <img src={CrossIcon} alt="" />
        </div>
          <CardBody className="flex flex-col gap-0">
          <div className="flex items-start gap-3 justifystart" >
            
            <div>
            <h4 className="font-[InterBold]  text-[#0D1F37] text-[1.2rem] md:text-[24px]">
              Order Cancel?
            </h4>
            <p className="font-[InterMedium]" >Do you Really Want to Cancel your Order - #{orderId} </p>
            </div>
          </div>
           <div className="mt-0" >
           
           
            <div className="mt-2" >
            
              <button onClick={handleConfirmModal} className="bg-red-600 outline-none px-4 mr-3 py-2 md:text-[1rem] text-[.8rem] text-white rounded-lg font-[InterMedium] " >Cancel</button>
              <button onClick={()=>{
                handleCancelOrder()
                handleConfirmModal()
                }} className="bg-[#2967B0] px-4 py-2 md:text-[1rem] text-[.8rem] outline-none text-white rounded-lg font-[InterMedium] " >Confirm</button>
            </div>
           </div>
           
           
            
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog
