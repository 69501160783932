import React from 'react'
import LoginVector from "../../asset/loginVector.svg"
const LoginLeft = () => {
  return (
       <div className="col-span-1  hidden lg:block px-12 relative py-8 rounded-l-lg bg-[#2967B0] " >
            <h4 className="text-white font-[700] text-[2rem] " >Sit back, relax</h4>
            <p className="text-gray-200" >let the repair come to you</p>
            <div className=" absolute bottom-[10px] left-[60px] right-auto " >
            <img className="w-[300px]" src={LoginVector} alt="" />
            </div>
        </div>
  )
}

export default LoginLeft