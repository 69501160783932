import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const NewModel = ({newModel,setNewModel,handleNewModel,model,setModel,stage,setStage,openModel,setOpenModal}) => {
  console.log(model)
  return (
    <div>
      <Dialog
        size="md"
        open={newModel}
        handler={handleNewModel}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto grid lg:px-12 py-8   w-full ">
          <CardBody className="flex flex-col gap-4">
            <h4 className="font-[600] text-[1.2rem]">
              Can't find your Model
            </h4>
           
          <div>
            <Input value={model} onChange={(e)=>setModel(e.target.value)} label="Enter Model Name" />
          </div>
            <div className="flex mt-6 items-center gap-4 justify-center" >
              <button onClick={handleNewModel} className="bg-gray-300 rounded-md w-[120px] px-6 py-3 font-[InterMedium] ">Cancel</button>
              <button onClick={()=>{
                setOpenModal(true)
              }} className="bg-[#2967B0] px-6 py-3 font-[InterMedium] rounded-md w-[120px] text-white">Continue</button>
            </div> 
          
           
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default NewModel