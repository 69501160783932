import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  
  Option,
  Textarea,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import SelectColor from "./SelectColor";
import { AudioRecorder } from 'react-audio-voice-recorder';
import { TrashIcon } from "@heroicons/react/24/solid";
import MultiSelectDropdown from "../Layout/MultiSelectDropdown";
import CrossIcon from "../../asset/crossIcon.svg"
const IssuesModel = ({
  openModal,
  handleOpenModal,
  problemsList,
  issue,
  setIssue,
  issueList,
  setIssueList,
  issueDescription,
  setIssueDescription,

  color,
  setColor,
  activeColor,
  setActiveColor,
   AudioDetails,
   setAudioDetails,
   addAudioElement,
   handleRepairInfo
}) => {
  console.log(problemsList);
  const activeList =
    "px-2 py-2 rounded-[12px] cursor-pointer text-[#59A4FF] bg-[#F6FBFF] border border-[#59A4FF]";
  const inActiveList =
    "px-2 py-2 rounded-[12px] cursor-pointer  border-[#DCDCDC] border-[1px]";
    const navigate = useNavigate()
    const [dropdown, setDropdown] = useState(false);
    const [addDescribe, setAddDescribe] = useState(false)
     const toogleDropdown = () => {
        setDropdown(!dropdown)
    };
    const [selectedIssues, setSelectedIssues] = useState([])
     const addTag = (item) => {
        if(issue.includes(item)){
             const filtered = issue.filter((e) => e.name !== item.name);
        setIssue(filtered);
        }
        else{
            setIssue([...issue,item])
        setDropdown(false);
        }
        
    };
    // removes item from multiselect
    const removeTag = (item) => {
        const filtered = issue.filter((e) => e.name !== item.name);
        setIssue(filtered);
    }
    const removeIssue = (issueName) =>{
      let newList=issueList.filter((el)=> el !== issueName)
      setIssueList(newList)
    }
    console.log(AudioDetails)
  return (
    <div>
   
      <Dialog
        size="lg"
        open={openModal}
        handler={handleOpenModal}
        className="bg-white outline-none "
      >
      <DialogHeader className="md:flex items-center  justify-between" >
        <h4 className="font-[InterBold] text-[#0D1F37]  text-[14px] md:text-[24px]">
              Select the issue you are facing in your device{" "}
            </h4>
       <div onClick={handleOpenModal} className="absolute cursor-pointer top-2 right-[10px] w-[20px]  md:w-[40px] " >
          <img src={CrossIcon} alt="" />
        </div>
      </DialogHeader>
      <DialogBody className="h-[30rem] dialogBox overflow-y-scroll">  
            <div className="flex md:items-center flex-col md:flex-row justify-between" >
            <MultiSelectDropdown addTag={addTag} removeTag={removeTag} dropdown={dropdown} toogleDropdown={toogleDropdown} options={problemsList} issue={issue} setIssue={setIssue} />   
            <p onClick={()=>setAddDescribe(true)} className="font-[InterMedium] text-left text-[16px] text-[#0D1F37]" >Couldn’t find your problem? <span className="text-[#59A4FF] cursor-pointer " > Add here</span></p>
            </div>
           {issueList.length !== 0 && <div>
              <p className="font-[InterMedium] text-black" >Selected Issues Are: </p>
              <div className="flex flex-wrap items-center mt-2 justify-start gap-2 lg:gap-6" >
              
              {
                issueList.map((item)=>(
                  <div className="px-4 relative py-2 w-[full] rounded-[12px]  text-[#59A4FF] bg-[#F6FBFF] border border-[#59A4FF]">
                  <span className="text-[.7rem] font-[InterMedium] " >{item}</span>
                  <span onClick={()=>removeIssue(item)} className="absolute right-2 top-[3px] cursor-pointer font-[InterMedium] text-[.6rem]" >X</span>
                  </div>
                ))
              }
              </div>
            </div>}
            <div className="my-6 flex items-start flex-wrap gap-4 justify-start" >
             {
              issue.map((item)=>(
                <div className="border-[1px] w-[400px] px-3 py-4  rounded-[5px] border-[#F5F5F5]" >
                  <div className="flex items-center justify-between" >
                    <h4 className="font-[InterBold] text-[16px] text-[#0D1F37]" >{item.name}</h4>
                    <div onClick={()=>removeTag(item)} className="bg-[#F4F4F4] cursor-pointer w-[24px] h-[24px] rounded-full flex items-center justify-center " >
                      <p className="text-[10px] font-[600]" >X</p>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center gap-3 mt-4 justify-start" >
                  {
                    item?.ProblemList?.map((problem)=>(
                      <div onClick={() => {
                           
                           if (issueList.includes(problem)) {
                              const result = issueList.filter(
                                (issue) => issue !== problem
                              );
                              setIssueList(result);
                            }
                            else if(problem==="Broken Back Cover" || problem==="SIM Card Tray Issue (for cellular-enabled iPads)"){
                              setActiveColor(!activeColor)
                              setIssueList([...issueList, problem]);
                            }
                             else {
                              setIssueList([...issueList, problem]);
                             
                            }
                         
                          }}  className={issueList.includes(problem) ? activeList : inActiveList} >
                        <p className="font-[InterRegular] text-[13px] text-center text-[#4D4D4D]" >{problem}</p>
                      </div>
                    ))
                  }
                  </div>
                </div>
              ))
             }
            </div>
         { color !=="" &&  (
          <div>
         <p className="text-[1.2rem]" >  Device Color :  <span className="font-[600]" > {color}  </span></p> 
            </div>)}
          { addDescribe &&  <div className="lg:hidden block" >
            <h4 className="font-[600] text-black my-3" >Describe a Problem <span className="font-[400] text-[.7rem]" >(click save to upload)</span> </h4>
           { AudioDetails !=="" && <div className="flex items-center gap-6 justify-start " >
              <audio className="w-[270px]" src={AudioDetails} controls ></audio>
              <TrashIcon onClick={()=>setAudioDetails("")} className="text-red-400 w-[20px]" />
            </div>}
          <div className="my-3" >
               <AudioRecorder 
      onRecordingComplete={addAudioElement}
      audioTrackConstraints={{
        noiseSuppression: true,
        echoCancellation: true,
      }} 
     
      downloadOnSavePress={false}
      downloadFileExtension="webm"
    />
    </div>

            </div>}
            <div>
           {addDescribe &&   <Textarea
                value={issueDescription}
                onChange={(e) => setIssueDescription(e.target.value)}
                label="Describe your issue"
              />}
            </div>
            <div className="flex items-center gap-6 justify-center lg:justify-end">
              <button
                onClick={handleOpenModal}
                className="bg-gray-300 rounded-md w-[150px] px-6 py-3 font-[InterMedium] "
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if(issueList.length !== 0 || issueDescription.length !==0 || AudioDetails.length!==0){
                  navigate("/repairPlan")
                  handleRepairInfo()
                  }
                  else{
                    alert("Select a Issue")
                  }
                }}
                className="bg-[#2967B0] px-6 py-3 font-[InterMedium] rounded-md w-[150px] text-white"
              >
                Continue
              </button>
            </div>
        
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default IssuesModel;
