import { Modal_FAIL,Modals_REQUEST,Modals_SUCCESS, SAVE_SHIELD_DEVICE_INFO,SHIELD_ORDER_FAIL,SHIELD_ORDER_REQUEST,SHIELD_ORDER_SUCCESS } from "../contants/shieldContants";
import  axios  from "axios";
import { ref, set, push } from "firebase/database";
import { database,db } from "../firebase.config";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  onSnapshot
} from "firebase/firestore";
export const fetchModal = (brandname) => async (dispatch) => {
     try{
        dispatch({ type: Modals_REQUEST });
      const brandData =[]
      if(brandname !== '') {
  const response =   await axios.get('https://mobile-phones1.p.rapidapi.com/v1/api/get-devices-by-brand',{
     params: {
    limit: '1000',
    brand_id: brandname,
    page: '1'
  },
    headers:{
    'X-RapidAPI-Key': '94f67b5d6fmshe1e75502efa5125p12e8a4jsn4f1bf50e0c8f',
    'X-RapidAPI-Host': 'mobile-phones1.p.rapidapi.com'
    }
  })
   if(response.data.data.devices){
        response.data.data.devices.map((brand)=>{
            if(!brand.name.includes("Watch") && !brand.name.includes("watch")){
                brandData.push(brand)
            }
           
        })
    }
  dispatch({ type: Modals_SUCCESS, payload:brandData });

}
  }catch(err){
     dispatch({
      type: Modal_FAIL,
      payload: err.response.data.message,
    });
  }
}

export const saveShieldDevice = (data) => async (dispatch) => {
   dispatch({
    type: SAVE_SHIELD_DEVICE_INFO,
    payload: data,
  });

  sessionStorage.setItem("deviceInfo", JSON.stringify(data));
}


export const saveOrder = (data,userId,orderId) => async (dispatch) => {
  try{
   
  
        dispatch({ type: SHIELD_ORDER_REQUEST });
          console.log(data)
       await  setDoc(doc(db, "shieldOrders",orderId ),data).then((res)=>{
       dispatch({ type: SHIELD_ORDER_SUCCESS,payload:data });
    }).catch((err)=>{
       dispatch({
      type: SHIELD_ORDER_FAIL,
      payload: err.message,
    });
    })
     
  }catch(err){
     dispatch({
      type: SHIELD_ORDER_FAIL,
      payload: err.message,
    });
  }
}