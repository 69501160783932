import React from "react";
import Navbar from "../Components/Layout/Navbar";
import Footer from "../Components/Layout/Footer";
import RepairBannerOne from "../Components/Repair/RepairBannerOne";
import RepairProducts from "../Components/Repair/RepairProducts";
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import StepsToRepair from "../Components/Repair/StepsToRepair";
import { useEffect, useState } from "react";
import { ref, onValue, get } from "firebase/database";
import { database } from "../firebase.config";
import PinCodeModal from "../Components/Layout/PinCodeModal";
import SelectDevice from "../Components/Repair/SelectDevice";
import DownloadApp from "../Components/Home/DownloadApp";
import BelowCTA from "../Components/Home/BelowCTA";
import Faqs from "../Components/Home/Faqs";
import DeviceBanner from "../Components/Repair/DeviceBanner";
import SelectManufacturer from "../Components/Repair/SelectManufacturer";
import ManufacturerBanner from "../Components/Repair/ManufacturerBanner";
import SelectModel from "../Components/Repair/SelectModel";
import { db } from "../firebase.config";
import IssuesModel from "../Components/Repair/IssuesModel";
import IssueBanner from "../Components/Repair/IssueBanner";
import SelectPlan from "../Components/Repair/SelectPlan";
import GuildelinesModel from "../Components/Repair/GuildelinesModel";
import AddressModel from "../Components/Repair/AddressModel";
import TimeModal from "../Components/Repair/TimeModal";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveRepairDevice } from "../actions/repairActions";
import SelectColor from "../Components/Repair/SelectColor";
import PackDetailsModal from "../Components/Repair/PackDetailsModal";
import axios from "axios";
const ZRepair = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading, isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  );
  const { deviceInfo } = useSelector((state) => state.modals);
  const [repairProductsList, setRepairProductsList] = useState([]);
  const [repairOrderList, setRepairOrderList] = useState([]);

  const [color, setColor] = useState("");
  const [pinCodeModal, setPinCodeModal] = useState(false);
  const [stage, setStage] = useState("select device");
  const [device, setDevice] = useState("");
  const [model, setModel] = useState("");
  const [manufacturer, setManufacturer] = useState({});
  const [issue, setIssue] = useState({});
  const [issueList, setIssueList] = useState([]);
  const [issueDescription, setIssueDescription] = useState("");
  const [problemsList, setProblemsList] = useState([]);
  const [plan, setPlan] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [guildelinesModel, setGuildelinesModel] = useState(false);
  const [timeModal, setTimeModal] = useState(false);
  const [addressModel, setAddressModel] = useState(false);
  const [day, setDay] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [time, setTime] = useState("");
  const [filteredModelList, setFilteredModelList] = useState(
    manufacturer?.models
  );
  const [locationData, setLocationData] = useState({});
  const [orderId, setOrderId] = useState("");

  const [addresses, setAddresses] = useState([]);
  const [faqs, setFaqs] = useState([]);

  const [audioDetails, setAudioDetails] = useState("");
  const [repairPacks, setRepairPacks] = useState([]);
  const [detailsModal, setDetailsModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const handleDetailsModal = () => {
    setDetailsModal(!detailsModal);
  };
  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);

    setAudioDetails(url);
    console.log(audio, url);
  };

  const [alternameNumber, setAlternameNumber] = useState();
  const [address, setAddress] = useState({
    name: userProfile?.FirstName,
    location: "",
    addressTwo: "",
    landmark: "",
    latitude: 0,
    longitude: 0,
    shortAddress: "",
    longAddress: "",
    alternameNumber: userProfile?.ContactNo ? userProfile?.ContactNo : "",
  });
  const [activeColor, setActiveColor] = useState(false);
  const handleColorModal = () => {
    setActiveColor(!activeColor);
    handleOpenModal();
  };
  const [repairData, setRepairData] = useState();

  const checkDay = (currentDate) => {
    switch (currentDate.getDay()) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thrusday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "";
    }
  };

  const issuesList = () => {
    const repairCountRef = ref(database, "problems");

    onValue(repairCountRef, (snapshot) => {
      const data = snapshot.val();
      const newProducts = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
      setProblemsList(newProducts);
    });
  };
  const handleOpenModal = () => {
    setOpenModal(!openModal);
    issuesList();
  };
  const handleGuildlinesOpenModal = () => {
    setGuildelinesModel(!guildelinesModel);
  };
  const handleAddressOpenModal = () => {
    setAddressModel(!addressModel);
  };
  const handleTimeOpenModal = () => {
    setTimeModal(!timeModal);
  };
  const handleSaveAddress = () => {
    setAddressModel(!addressModel);
    setTimeModal(!timeModal);
  };
  const handleSetDate = () => {
    setTimeModal(!timeModal);
    if (isAuthenticated) {
      navigate("/cart");
    } else {
      setIsLogin(true);
    }
  };
  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    var updatedList = [...manufacturer?.models];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setFilteredModelList(updatedList);
  };
  const handleRepairInfo = (deviceName) => {
    dispatch(
      saveRepairDevice({
        orderType: "Repair Pack",
        orderStatus: "Pending",
        // problemList : issueList && issueList,
        // problemDesc : issueDescription,
        // recordedProblems : audioDetails,
        deviceData: {
          deviceType: deviceName,
          // brandName : manufacturer?.brand,
          // modelName : model,
          // deviceColor  : color,
          // devicePurchaseDate : "",
          // imeiNo : "",
          // devicePrice : "",
          // purchaseBill : ""
        },
        // packData : {
        //   warrantyType : "",
        //   packName : plan?.packName,
        //   serviceCost : "",
        //   PackCost : plan?.planCost,
        //   packValidity : "",
        //   PackId : plan?.id
        // },
        // pickUpData : {
        //   geoPoint: {
        //     latitude: address.latitude,
        //     longitude: address.longitude
        //   },
        //   addLine1 : address.location,
        //   addLine2 : address.addressTwo,
        //   addLine3 :address.landmark,
        //   bookingTimeDate : day + "/" + monthYear + " - " + time + ":00"
        // },
      })
    );
  };

  const fetchRepairOrder = async () => {
    console.log("newOrder");
    const querySnapshot = await getDocs(collection(db, "repairOrders"));
    querySnapshot.forEach((doc) => {
      setRepairOrderList((prev) => [
        ...prev,
        {
          id: doc.id,
          ...doc.data(),
        },
      ]);
    });
  };
  const fetchFaqs = () => {
    const repairCountRef = ref(database, "faqs/");
    if (repairCountRef) {
      onValue(repairCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const newProducts = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setFaqs(newProducts);
        }
      });
    }
  };
  const fetchRepairPacks = () => {
    const repairCountRef = ref(database, "shieldPack/");

    onValue(repairCountRef, (snapshot) => {
      const data = snapshot.val();
      const newProducts = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
      setRepairPacks(newProducts);
    });
  };

  const handleLocationData = (latitude, longitude) => {
    if (address)
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        )
        .then((response) => {
          setLocationData(response?.data?.results);
          console.log(response?.data);
          setAddress({
            ...address,
            addressTwo: response?.data?.results[4]?.formatted_address,
            shortAddress: response?.data?.results[8]?.formatted_address,
            longAddress: response?.data?.results[3]?.formatted_address,
            latitude: latitude,
            longitude: longitude,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
  };
  const fetchAddresses = () => {
    const reviewCountRef = ref(database, "addresses/" + users);

    onValue(reviewCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newProducts = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setAddresses(newProducts);
      }
    });
  };

  useEffect(() => {
    fetchFaqs();

    navigator.geolocation.getCurrentPosition(function (position) {
      const { latitude, longitude } = position.coords;
      setAddress({ ...address, latitude: latitude, longitude: longitude });
      handleLocationData(position.coords.latitude, position.coords.longitude);
    });

    issuesList();
    fetchRepairPacks();
    if (users && isAuthenticated) {
      fetchAddresses();
    }
    sessionStorage.setItem("activePage", "Zepair");
  }, [isLogin, isAuthenticated]);

  return (
    <div className=" font-[Inter] ">
      <Navbar isLogin={isLogin} />
     

      <div className="lg:mt-[80px] mt-[100px] md:mt-[120px]">
        {" "}
        <RepairBannerOne />
        <SelectDevice
          handleRepairInfo={handleRepairInfo}
          device={device}
          setDevice={setDevice}
          stage={stage}
          setStage={setStage}
        />
        <StepsToRepair />
        <div className="my-16">
          <Faqs faqs={faqs} />
        </div>
        <DownloadApp />
        <BelowCTA />
        {/* <RepairProducts repairProductsList={repairProductsList && repairProductsList} />
        <StepsToRepair />
        <Faqs /> */}
      </div>
      <Footer bg="#0D1F37" />
    </div>
  );
};

export default ZRepair;
