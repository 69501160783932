import React, { useEffect, useState } from 'react'
import Feature1 from "../../asset/feature1.png"
import Feature2 from "../../asset/feature2.png"
import Feature3 from "../../asset/feature3.png"
import Feature4 from "../../asset/feature4.png"
import Downloads from "../../asset/downloads.png"
import Engineer from "../../asset/engineer.png"
import Client from "../../asset/client.png"
import RepairFeature from "../../asset/repairfeature.png"
import CountUp from "react-countup"
import ScrollTrigger from "react-scroll-trigger"



const Features = ({scrollPosition}) => {
    const [downloads, setDownloads] = useState(0)
    const [counterOn, setCounterOn] = useState(false)
    console.log(scrollPosition)
  return (
    <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)} >
         <div className='lg:grid lg:grid-cols-2 font-[Inter] xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px] py-8 xl:py-[60px] grid-flow-col' >
        <div className='lg:w-[400px]' >
            <div className='flex items-center justify-start' >
                <img className='lg:w-16 w-10 lg:mr-4 mr-2 ' src={Feature1} alt="" />
                <img className='lg:w-16 w-10 lg:mr-4 mr-2 ' src={Feature2} alt="" />
                <img className='lg:w-16 w-10 lg:mr-4 mr-2 ' src={Feature3} alt="" />
                <img className='lg:w-16 w-10 lg:mr-4 mr-2 ' src={Feature4} alt="" />
            </div>
            <div className='lg:mb-0 mb-4 md:w-[560px] '  >
                <h4 className='md:text-[2.6rem] text-[25px] mt-4   text-[#2967B0] font-[InterBlack]' >Our 1 year of achievements</h4>
                <p className='text-[#18191F] font-[InterMedium] ' >With our super powers we have reached this</p>
            </div>
        </div>
         <div className='flex items-center gap-3 md:gap-4 lg:gap-0 justify-between flex-wrap lg:w-[500px]' >
            <div className='flex lg:w-[250px] w-[100px] items-center justify-start' >
                <div className='' >
                    <img className='w-8' src={Downloads} alt="" />
                </div>
                <div className='md:ml-4 ml-2'>
                    <h4 className='text-[#DE562C] lg:text-[1.4rem] font-[InterBold] md:text-[1rem] text-[.8rem] font-[600]' >
                {counterOn &&   <CountUp start={0} end={1000} duration={2} delay={0} />} +
                    </h4>
                    <p className='text-gray-600 font-[InterMedium] lg:md:text-[1rem]  md:text-[.8rem] text-[.5rem] ' >Downloads per day</p>
                </div>
            </div>
           <div className='flex lg:w-[250px] w-[100px] items-center justify-start' >
                <div className='' >
                    <img className='w-8' src={RepairFeature} alt="" />
                </div>
                <div className='md:ml-4 ml-2'>
                    <h4 className='text-[#DE562C] lg:text-[1.4rem] font-[InterBold] md:text-[1rem] text-[.8rem] font-[600]' >
                        {counterOn &&   <CountUp start={10000} end={20000} duration={2} delay={0} />} +
                    </h4>
                    <p className='text-gray-600 font-[InterMedium] lg:md:text-[1rem]  md:text-[.8rem] text-[.5rem]' >Devices Repair</p>
                </div>
            </div>
             <div className='flex lg:w-[250px] w-[100px] items-center justify-start' >
                <div className='' >
                    <img className='w-8' src={Client} alt="" />
                </div>
                <div className='md:ml-4 ml-2'>
                    <h4 className='text-[#DE562C] lg:text-[1.4rem] font-[InterBold] md:text-[1rem] text-[.8rem] font-[600]' >
                        {counterOn &&   <CountUp start={0} end={500} duration={2} delay={0} />} +
                    </h4>
                    <p className='text-gray-600 font-[InterMedium] lg:md:text-[1rem]  md:text-[.8rem] text-[.5rem]' >Clients</p>
                </div>
            </div>
             <div className='flex lg:w-[250px] w-[100px] items-center justify-start' >
                <div className='' >
                    <img className='w-8' src={Engineer} alt="" />
                </div>
                <div className='md:ml-4 ml-2'>
                    <h4 className='text-[#DE562C] lg:text-[1.4rem] font-[InterBold] md:text-[1rem] text-[.8rem] font-[600]' >
                        {counterOn &&   <CountUp start={0} end={140} duration={4} delay={0} />} +
                    </h4>
                    <p className='text-gray-600 font-[InterMedium] lg:md:text-[1rem]  md:text-[.8rem] text-[.5rem]' >Engineers</p>
                </div>
            </div>
        </div>
    </div>     
    </ScrollTrigger>
   
  )
}

export default Features