import { MapPinIcon } from '@heroicons/react/24/solid'
import React from 'react'

const LocationMarker = ({lat,long,text}) => {
  return (
    <div className="pin">
   <MapPinIcon  className='w-[60px] text-black' />
    <p className="pin-text">{text}</p>
  </div>
  )
}

export default LocationMarker