import React from 'react'
import Repair from "../../asset/repair.png"
import warningIcon from "../../asset/warningIcon.png"
import Card from "../../asset/card.png"
import { BanknotesIcon, HomeIcon } from '@heroicons/react/24/outline'
const PlaceCartLeft = ({handleOpenModal,handleOrder,setPaymentMethod,deviceInfo,totalServiceCost,handlePayment}) => {
  return (
    <div  className='col-span-2 px-6 ' >
        <div className='border-b-[5px] py-4 border-gray-200' >
            <h4 className='text-[35px] font-[InterBold]  text-black' > Your Cart</h4>
            <div onClick={handleOpenModal} className='mt-6 cursor-pointer border-[1px]  border-gray-200 rounded-lg px-6 py-4' >
                <img className='w-[80px]' src={Repair} alt="" />
                <div className='flex pt-4 items-start justify-between' >
                    <div>
                    <h4 className='text-[16px] font-[InterMedium] text-black' >Booked Repair Service:</h4>
                      <div>
                        {deviceInfo?.ServiceData?.map((service,index)=>(
                    <div>
                      <h4 className='font-[InterRegular]' >{index+1 }. {service.serviceItem}</h4>
                    </div>
                  ))}
                    </div>
                    </div>
                    <div>
                        <p className='text-[#0D1F37] text-[18px] font-[InterBold]'>₹{totalServiceCost}</p>
                    </div>
                  
                </div>
               
            </div>
            <div className='my-4 flex items-center justify-start px-4 rounded-lg bg-[#ECF5FF] py-3' >
                <div>
                    <img className='w-[20px] mr-3' src={warningIcon} alt="" />
                </div>
                <div>
                    <h4 className='text-[#2967B0] text-[13px] font-[InterMedium] ' >Place order with our minimum service fee and pay full cost later when your service is completed</h4>
                </div>
            </div>
           
        </div>
         <div className='py-6' >
                <h4 className='font-[InterBold] text-[20px] text-[#0D1F37]' >Payment Methods</h4>
                <div className='my-3' >
                    <div onClick={handlePayment} className='border-[1px] cursor-pointer px-6 my-4 rounded-lg py-4 flex items-center justify-between border-gray-200 ' >
                        <h4 className='font-[InterMedium]' >Debit / Credit Card, Net Banking, UPI</h4>
                        <img className='w-[40px]' src={Card} alt="" />
                    </div>
                     <div onClick={handleOrder} className='border-[1px] cursor-pointer px-6 my-4  rounded-lg py-4 flex items-center justify-between border-gray-200 ' >
                        <h4 className='font-[InterMedium]' >Cash on Delivery</h4>
                       <BanknotesIcon className='w-[30px]' />
                    </div>
                </div>
            </div>
    </div>
  )
}

export default PlaceCartLeft