import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
  
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import toast, { Toaster } from "react-hot-toast";
const DeviceDetailsModal = ({deviceModal,setDetailsModal,handleDeviceModal,purchaseDate,setPurchaseDate,imeiOne,setImeiOne,imeiTwo,setImeiTwo,devicePrice,setDevicePrice,pinCode,setPinCode,stage,setStage,handleServicePacks,handleFilterPacks,imei}) => {
    const options = {
	title: "Demo Title",
	autoHide: true,
	todayBtn: false,
	clearBtn: true,
	clearBtnText: "Clear",
	maxDate: new Date(),
	minDate: new Date("1950-01-01"),
	theme: {
		background: "bg-gray-700 dark:bg-gray-800",
		todayBtn: "",
		clearBtn: "",
		icons: "",
		text: "",
		disabledText: "bg-red-500",
		input: "",
		inputIcon: "",
		selected: "",
	},
	icons: {
		// () => ReactElement | JSX.Element
		prev: () => <span>Previous</span>,
		next: () => <span>Next</span>,
	},
	datepickerClassNames: "top-12",
	defaultDate: new Date(),
	language: "en",
	disabledDates: [],
	weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
	inputNameProp: "date",
	inputIdProp: "date",
	inputPlaceholderProp: "Select Date",
	inputDateFormatProp: {
		day: "numeric",
		month: "long",
		year: "numeric"
	}
}
    const [show, setShow] = useState(true)
    const [imeiError, setImeiError] = useState(false)
    const [purchaseDateChange, setPurchaseDateChange] = useState(false)
    const [devicePriceError, setDevicePriceError] = useState(false)
    const [pinCodeError, setPinCodeError] = useState(false)
    const [imeiChange, setImeiChange] = useState(false)
    const [devicePriceChange, setDevicePriceChange] = useState(false)
    const [pinCodeChange, setPinCodeChange] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);
  return date;
}
    const [endDate, setEndDate] = useState(addMonths(new Date(),-6))
	const handleChange = (selectedDate) => {
		console.log(selectedDate)
	}
	const handleClose = (state) => {
		setShow(state)
	}
  console.log(imeiOne.length)
    const handleDetailSave = () =>{
      if(!purchaseDateChange){
         alert("Select a Date")
      }
     else if(imeiOne === "" || imeiOne.length < 15 ||  imeiOne.length > 15 ){
        setImeiError(true)
    }
    
    else if(devicePrice === null ){
      setDevicePriceError(true)
    }
    else if(pinCode === "" || pinCode.length < 6 || pinCode.length > 6){
      setPinCodeError(true)
    }
    else{
      handleFilterPacks()
       handleDeviceModal()
       setStage("select pack")
    }
  }
  console.log(endDate)
  return (
     <div>
     
      <Dialog
        size="sm"
        open={deviceModal}
        handler={handleDeviceModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto   w-full ">
          <CardBody className="">
         
            <div className="md:mx-6 mx-0 mt-2" >
              <h4 className="text-[1.5rem] text-black font-[600]  " >Enter Device Details</h4>
             <div>
             <div className="my-6 w-full " >
              <DatePicker className="border-[1px] border-gray-400 rounded-lg outline-none   " showIcon icon={<CalendarDaysIcon className="mt-1 mr-3" />} startDate={startDate} endDate={endDate} minDate={endDate}
      maxDate={startDate} selected={purchaseDate} onChange={(date) => {
        setPurchaseDateChange((true))
        setPurchaseDate(date)}} />
            
                </div>
                <div className="my-6">
                <Input type="number" value={imeiOne} error={imeiError ? true : false} onChange={(e)=>{
                  setImeiOne(e.target.value)
                  setImeiChange(true)
                }} className="" label="IMEI Number 1" />
                
                  <p className="mt-2 text-red-600" >{!imeiChange && "Please Enter IMEI Number "} {  <p className="mt-2 text-red-600" >{imeiError && imeiChange && "Please Enter Correct IMEI Number "}</p>}</p>
                 
                
                </div>
                <div className="my-6">
                <Input type="number" value={devicePrice} error={devicePriceError ? true : false} onChange={(e)=>{
                  setDevicePrice(e.target.value)
                  setDevicePriceChange(true)
                }} className="" label="Device Price" /> 
                <p className="mt-2 text-red-600" >{!devicePriceChange && "Please Enter Device Price "} {  <p className="mt-2 text-red-600" >{devicePriceChange && devicePriceError && "Please Enter Correct Device Price "}</p>}</p>
                </div>
                 <div className="my-6">
                <Input type="number" value={pinCode} error={pinCodeError ? true : false} onChange={(e)=>{
                  setPinCode(e.target.value)
                  setPinCodeChange(true)
                }} className="" label="Pin Code" /> 
                  <p className="mt-2 text-red-600" >{!pinCodeChange && "Please Enter IMEI Number "} {  <p className="mt-2 text-red-600" >{pinCodeChange && pinCodeError && "Please Enter Correct IMEI Number "}</p>}</p>
                </div>
             </div>
             <div className="flex items-center mt-12 mb-8 justify-center" >
                <button onClick={handleDeviceModal} className="py-3 px-6 mx-4 w-[150px]  rounded-lg font-[600] bg-gray-300" >Cancel</button>
                <button onClick={handleDetailSave} className="py-3 px-6 mx-4 w-[150px] text-white rounded-lg font-[600] bg-blue-600 " >Continue</button>
             </div>
             </div>
            
              
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default DeviceDetailsModal