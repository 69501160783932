import  React, {useEffect,useState} from "react"
import './App.css';
import {
  BrowserRouter,
Route,
HashRouter,
  Routes,
  useLocation
} from "react-router-dom";
import Home from './Pages/Home';
import ZRepair from './Pages/ZRepair';
import ZShield from './Pages/ZShield';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Profile from './Pages/Profile';
import Login from './Pages/Login';
import UserInfo from './Pages/UserInfo';
import Register from './Pages/Register';
import { useSelector, useDispatch } from 'react-redux'
import { loadUser } from "./actions/userAction";
import RepairProduct from "./Pages/RepairProduct";




import Success from "./Pages/Success";
import SingleRepairOrder from "./Pages/SingleRepairOrder";

import MyShield from "./Pages/MyShield";
import SingleShieldOrder from "./Pages/SingleShieldOrder";
import Privacypolicy from "./Pages/PrivacyPolicy";
import TermsandCondition from "./Pages/TermsandCondition";
import Cart from "./Pages/Cart";
import Shield from "./Pages/Shield";
import ShieldCart from "./Pages/ShieldCart";
import Terms from "./Pages/Terms";
import SuccessStories from "./Pages/SuccessStories";
import OrderDetails from "./Pages/OrderDetails";
import ShieldSuccess from "./Pages/ShieldSuccess";
import UnAuthorizedAccess from "./Pages/UnAuthorizedAccess";
import RepairManufacturer from "./Pages/RepairManufacturer";
import RepairModels from "./Components/Repair/RepairModels";
import RepairPlan from "./Pages/RepairPlan";
import CancellationPolicy from "./Pages/CancellationPolicy";
import OrderCart from "./Components/OrderDetail/OrderPlaceCart";
import ChatbotButton from "./Components/Layout/ChatbotButton";
import { Spinner } from "@material-tailwind/react";
function App() {
  const dispatch = useDispatch()
  const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
  );
  
  console.log(users)
   useEffect(() => {
    
    
       if(isAuthenticated && !userProfile) {
    dispatch(loadUser(users));
  }
 
   
    

    
  }, [dispatch,isAuthenticated,userProfile]);
  return (
    <div className="App">
    <ChatbotButton />
    {loading && 
    <div  className="flex items-center justify-center h-[100vh]" >
      <Spinner />
    </div>
    }
     <Routes>
     
        <Route exact path="/" element={<Home/>}   />
        <Route exact path="/privacy-policy" element={<Privacypolicy />}   />
          <Route exact path="/terms-conditions" element={<TermsandCondition />}   />
        <Route exact path="/repair" element={<ZRepair /> }   />
         <Route exact path="/repairManufacturer" element={<RepairManufacturer /> }   />
          <Route exact path="/repairModels" element={<RepairModels /> }   />
          <Route exact path="/repairPlan" element={<RepairPlan /> }   />
    
         {isAuthenticated &&  <Route exact path="/cart" element={<Cart /> }   />}
          {isAuthenticated &&  <Route exact path="/shieldCart" element={<ShieldCart /> }   />}
         {isAuthenticated &&  <Route exact path="/viewRepairOrder/:id" element={<SingleRepairOrder />}   />}
          {isAuthenticated &&  <Route exact path="/myShields" element={<MyShield /> }   />}
          {isAuthenticated &&  <Route exact path="/viewShieldOrder/:id" element={<SingleShieldOrder />}   />}
        <Route exact path="/shield" element={<ZShield /> }   />
          <Route exact path="/shieldFlow" element={<Shield /> }   />
        <Route exact path="/about" element={<About /> }   />
        <Route exact path="/stories" element={<SuccessStories /> }   />
         <Route exact path="/termsandcondition" element={<Terms /> }   />
         <Route exact path="/cancellationPolicy" element={<CancellationPolicy /> }   />
      <Route exact path="/profile" element={<Profile /> }   /> 
       {isAuthenticated && <Route exact path="/orderDetails/:type/:id" element={<OrderDetails /> }   /> }
      {isAuthenticated && <Route exact path="/orderDetails/:type/:id/placeCart" element={<OrderCart /> }   /> } 
        <Route exact path="/success" element={<Success/> }   />
        <Route exact path="*" element={<UnAuthorizedAccess /> }   />
          <Route exact path="/shieldSuccess" element={<ShieldSuccess /> }   />
     {isAuthenticated &&    <Route exact path="/user-info" element={<UserInfo/> }   />}
        </Routes>
    </div>
  );
}

export default App;
