import React from 'react'
import RepairIcon from "../../asset/repair.png"
import ShieldIcon from "../../asset/shieldorange.png"
const OrderTab = ({activeTab,setActiveTab}) => {
  const activeTabClass = "border-b-[2px] cursor-pointer py-3 flex items-center justify-center border-blue-400 w-[150px]"
  const inActiveTabClass = 'w-[150px] py-3 cursor-pointer flex items-center justify-center'
  return (
    <div className='bg-white w-full py-4' >
        <div className='flex gap-6 px-6 items-center justify-center lg:justify-start ' >
            <div onClick={()=>{
              setActiveTab("repair")
            }}  className={activeTab === "repair" ? activeTabClass : inActiveTabClass } >
                <img className='w-[80px] ' src={RepairIcon} alt="" />
            </div>
           
        </div>
    </div>
  )
}

export default OrderTab