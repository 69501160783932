import React, { useState } from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import { updateEmail } from 'firebase/auth'
import { useSelector, useDispatch } from 'react-redux'
import { loadUser, updateProfile } from '../actions/userAction'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { collection, query, where, getDocs, doc,  onSnapshot, orderBy } from "firebase/firestore";
import { db, auth, database, storage } from '../firebase.config'
import ProfileLeft from '../Components/Profile/ProfileLeft'
import ProfileRight from '../Components/Profile/ProfileRight'
import toast, { Toaster } from 'react-hot-toast';
import { ref, set, push, onValue,remove, update } from "firebase/database";

import axios from 'axios'
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytesResumable,
  listAll,
} from "firebase/storage";
import AccessModal from '../Components/Layout/AccessModal'
const Profile = () => {
   const { error, loading, isAuthenticated,users,userProfile } = useSelector(
    (state) => state.user
   );
  
    const dispatch = useDispatch();
 

   const [name, setName] = useState(userProfile?.FirstName ? userProfile.FirstName : "")
  const [email, setEmail] = useState(userProfile?.email ? userProfile?.email : "")
  const [emailChange, setEmailChange] = useState(false)
    const [profileUrl, setProfileUrl] = useState(userProfile?.profile_image ? userProfile?.profile_image : "")
    const [phoneLoading, setPhoneLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState("")
  const [phone, setPhone] = useState(userProfile?.ContactNo ? userProfile.ContactNo?.slice(4,15) : "")
  const [phoneError, setPhoneError] = useState(false)
  const [phoneChange, setPhoneChange] = useState(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
  const [role, setRole] = useState(userProfile?.role && "")
  const [addressName, setAddressName] = useState("")
  const [briefAddress, setBriefAddress] = useState("")
  const [landmark, setLandmark] = useState("")
    const [isDefault, setIsDefault] = useState(false)
  const [activeEdit, setActiveEdit] = useState(false)
  const [addressRemoved, setAddressRemoved] = useState(false)
  const [activeSection, setActiveSection] = useState()
    const [addresses, setAddresses] = useState([])
    const [locationData, setLocationData] = useState({})
    const [addressId, setAddressId] = useState("")
    const [repairOrderList, setRepairOrderList] = useState([])
    const [shieldOrderList, setShieldOrderList] = useState([])
    const [updatePassword, setUpdatePassword] = useState(false)
    const [filteredOrderList, setFilteredOrderList] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)
    const handleConfirmModal = () =>{
      setConfirmModal(!confirmModal)
    }
 const [geometry, setGeometry] = useState({
    lat:0,
    lng:0
  })
    const navigate = useNavigate()
    const [singleAddress, setSingleAddress] = useState({
      name:"",
      location:"",
      landmark:"",
      latitude:"",
      longitude:"",
      longAddress:"",
      shortAddress:"",
      alternameNumber:"",
      addressTwo:"",
      id:""
    })
    const [address, setAddress] = useState({
    name:userProfile?.FirstName,
    location:"",
    addressTwo:'',
    landmark:"",
    latitude:0,
    longitude:0,
    shortAddress:'',
    longAddress:'',
    alternameNumber:userProfile?.ContactNo ? userProfile?.ContactNo : ''
  })
    const updateProfileInfo = async  () => {
    const userRef = collection(db, "user");
    const emailQuery = query(userRef, where("email", "==", email));
    const getEmailInfo = await getDocs(emailQuery);
    const userMobileRef = collection(db, "user");
    const mobileQuery = query(userRef, where("ContactNo", "==", phone));
    const getMobileInfo = await getDocs(mobileQuery);
     var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    if(email!=="" && emailChange && getEmailInfo.docs.length!==0 ){
      alert("Email Already Present")
      setEmailError(true)
      setEmailErrorMessage("Email Already Present")
    }
    if(email=== ""  || emailRegex.test(email)===false){
       alert("Enter Valid Email")
      setEmailError(true)
      setEmailErrorMessage("Enter a Valid Email")
    }
    if(phone!=="" && phoneChange && getMobileInfo.docs.length!==0 ){
       alert("Phone Number Already Present")
      setPhoneError(true)
      setPhoneErrorMessage("Phone Number is Already Present")
    }
      if(phone!==""  && phoneRegex.test(phone)!==true){
      alert("Enter Valid Phone Number")
      setPhoneError(true)
      setPhoneErrorMessage("Enter a Valid Phone Number")
    }
    else{
      console.log(auth)
       updateEmail(auth.currentUser, email).then(() => {
      dispatch(updateProfile(users,name,email,phone,role,profileUrl))
      toast.success('Profile Saved Successfully',{
        position:"bottom-center"
      })
    dispatch(loadUser(users))
    setActiveEdit(!activeEdit)
    }).catch((error) => {
      toast.error("Please Login Again to Edit",{
        position:"bottom-center"
      })
     setEmail(userProfile?.email ? userProfile?.email : "")
    });
    
    }
   
   
  }
  const addProfileImage = (e) =>{
      e.preventDefault()
    const file = e.target.files[0]
    console.log(file)
    if (!file) return;
    const storeRef = storageRef(storage, `profileImages/${file.name}`);
    const uploadTask = uploadBytesResumable(storeRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
        setPhoneLoading(true)
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setProfileUrl(downloadURL)
          console.log(downloadURL)
          setPhoneLoading(false)
        });
      }
    );
  }
  const updateAddressInfo = async ()=>{
   
      if(userProfile){
        const productListRef = ref(database, "addresses/" + users);
    const newProductRef = push(productListRef);
    set(newProductRef, {
     userId:users,
      name:userProfile?.FirstName,
    location:address.location,
    addressTwo:address.addressTwo,
    landmark:address.landmark,
    latitude:address.latitude,
    longitude:address.longitude,
    shortAddress:address.shortAddress,
    longAddress:address.longAddress,
    alternameNumber:address.alternameNumber
    }).then(()=>{
   
      setAddress({
         name:userProfile?.FirstName,
    location:"",
    addressTwo:'',
    landmark:"",
    latitude:0,
    longitude:0,
    shortAddress:'',
    longAddress:'',
    alternameNumber:userProfile?.ContactNo ? userProfile?.ContactNo : ''
      })
    })
      }
    }
    const [map, setMap] = useState(null);
     const fetchAddresses = () =>{
      const reviewCountRef = ref(database, 'addresses/' + users);

    onValue(reviewCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));

      setAddresses(newProducts)
      }
   


    },);
    }
    const resetPassword = () =>{
      setUpdatePassword(true)
    }

    const fetchSingleAddress =(addressId) =>{
      const reviewCountRef = ref(database, 'addresses/' + users + "/" + addressId);
      onValue(reviewCountRef,(snapshot)=>{
        const data = snapshot.val()
        console.log(data,snapshot.key)
        setSingleAddress({
          name:data.name,
          location:data.location,
          landmark:data.landmark,
          id:snapshot.key,
          latitude:data?.latitude,
          longitude:data?.longitude,
          longAddress:data?.longAddress,
          shortAddress:data?.shortAddress,
          alternameNumber:data?.alternameNumber
        })
        setGeometry(
          {
            lat:data?.latitude,
          lng:data?.longitude
          }
        )
      })
    }
    const updateAddress = (addressId) =>{
      const addressListRef = ref(database, 'addresses/' + users + "/" + addressId);
       update(addressListRef, {
      name: singleAddress.name,
      location:singleAddress.location,
      landmark:singleAddress.landmark,
      latitude:singleAddress.latitude,
      longitude:singleAddress.longitude,
      longAddress:singleAddress.longAddress,
      shortAddress:singleAddress.shortAddress,
      alternameNumber:singleAddress.alternameNumber,
      addressTwo:singleAddress.addressTwo
    }).then(()=>{
      
      setActiveSection("address")
    })

    }
    const removeAddresss = (id) =>{
    remove(ref(database,"addresses/"+users+"/"+id)).
    then(()=>{
      toast("Address Removed Succesfully")
      setAddressRemoved(true)
    }).catch((err)=>{
      console.log(err.message)
    })
    }
    const fetchRepairOrder =  async () =>{
    console.log("newOrder")
    const q = query(collection(db, "repairOrders"), where("userUid", "==", users));
   const querySnapshot = await getDocs(q);
   console.log(querySnapshot)
    querySnapshot.forEach((doc) => {
     setRepairOrderList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
     
    
     
     
  
    })
    
  
    }
     const fetchShieldOrder =  async () =>{
    console.log("newOrder")
   const q = query(collection(db, "shieldOrders"), where("userUid", "==", users));
   const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
     setShieldOrderList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
     
    
     
     
  
    });
    }
    const onLoad = (map) => {
    setMap(map);
    console.log(map)
  };
     const handleLocationData = (latitude, longitude) => {
    if (address)
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        )
        .then((response) => {
          // setLocationData(response?.data?.results);
          // console.log(response?.data);
          console.log(latitude,longitude)
          onLoad(map)
          setGeometry({
            lat:latitude,
            lng:longitude
          })
          setAddress({
            ...address,
            addressTwo: response?.data?.results[4]?.formatted_address,
            shortAddress: response?.data?.results[8]?.formatted_address,
            longAddress: response?.data?.results[3]?.formatted_address,
            latitude: latitude,
            longitude: longitude,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
  };
  const handleEditLocationData = (latitude, longitude) => {
    if (address)
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        )
        .then((response) => {
          // setLocationData(response?.data?.results);
          // console.log(response?.data);
          console.log(latitude,longitude,response)
          onLoad(map)
          setGeometry({
            lat:latitude,
            lng:longitude
          })
          setSingleAddress({
            ...singleAddress,
            addressTwo: response?.data?.results[4]?.formatted_address,
            shortAddress: response?.data?.results[8]?.formatted_address,
            longAddress: response?.data?.results[3]?.formatted_address,
            latitude: latitude,
            longitude: longitude,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
  };
    useEffect(() => {
      fetchAddresses()
       sessionStorage.setItem("activePage", "Profile");
      navigator.geolocation.getCurrentPosition(function(position) {
    const {latitude, longitude} = position.coords;
    setAddress({...address,
      latitude:latitude,
      longitude:longitude})
      handleLocationData(position.coords.latitude,position.coords.longitude)
    });
    
      
      fetchRepairOrder()
      fetchShieldOrder()
     
      
    }, [users,addressRemoved,isAuthenticated,navigate])
 console.log(updatePassword)
  return (
    <div   >
    <Toaster />
        <Navbar  />
      <AccessModal confirmModal={confirmModal} handleConfirmModal={handleConfirmModal} />
        <div className='mt-[80px] font-[Inter]' >
        <div className='lg:px-48 lg:py-16' >
        <div className='lg:grid grid-cols-5 grid-flow-col' >
          <ProfileLeft  handleConfirmModal={handleConfirmModal} isAuthenticated={isAuthenticated} activeSection={activeSection} setActiveSection={setActiveSection} />
          <ProfileRight handleEditLocationData={handleEditLocationData} updatePassword={updatePassword} setUpdatePassword={setUpdatePassword} resetPassword={resetPassword} phoneLoading={phoneLoading} profileUrl={profileUrl} addProfileImage={addProfileImage} geometry={geometry} onLoad={onLoad} map={map} setMap={setMap} handleLocationData={handleLocationData} setGeometry={setGeometry} address={address} locationData={locationData} setLocationData={setLocationData} setAddress={setAddress} repairOrderList={repairOrderList} shieldOrderList={shieldOrderList} setSingleAddress={setSingleAddress} updateAddress={updateAddress} singleAddress={singleAddress} fetchSingleAddress={fetchSingleAddress}  addressId={addressId} setAddressId={setAddressId} removeAddresss={removeAddresss} fetchAddresses={fetchAddresses} addresses={addresses} addressName={addressName} setAddressName={setAddressName} briefAddress={briefAddress} setBriefAddress={setBriefAddress} landmark={landmark} setLandmark={setLandmark} isDefault={isDefault} setIsDefault={setIsDefault} updateAddressInfo={updateAddressInfo} phoneChange={phoneChange} setPhoneChange={setPhoneChange} emailChange={emailChange} setEmailChange={setEmailChange} phoneError={phoneError} phoneErrorMessage={phoneErrorMessage} emailError={emailError} emailErrorMessage={emailErrorMessage} updateProfileInfo={updateProfileInfo} name={name} activeEdit={activeEdit} setActiveEdit={setActiveEdit} email={email} phone={phone} setName={setName} setEmail={setEmail} setPhone={setPhone} userProfile={userProfile} activeSection={activeSection} setActiveSection={setActiveSection} />
        </div>
        </div>
            <Footer bg="#0D1F37" />
        </div>
         
    </div>
  )
}

export default Profile