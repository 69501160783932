import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import QuickFix from "../../asset/quick_fix_logo.png"
import OTS from "../../asset/ots_logo.png"
import CrossIcon from "../../asset/crossIcon.svg"
const PackDetailsModal = ({detailsModal,handleDetailsModal,repairPacks}) => {
  return (
     <div>
      <Dialog
        size="sm"
        open={detailsModal}
        handler={handleDetailsModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto lg:grid px-2 lg:px-4 py-3 lg:py-2   w-full ">
          <CardBody className="flex flex-col gap-4">
            <div onClick={handleDetailsModal} className="absolute cursor-pointer top-2 right-[10px]  w-[40px] " >
          <img src={CrossIcon} alt="" />
        </div>
         
           <div className="mt-3" >
            <div className="border-b-[1px] flex items-start justify-start lg:gap-6 border-gray-200 py-4" >
            <div>
                <img  className="hidden w-[180px] lg:block" src={OTS} alt="" />
            </div>
            <div>
                <h4 className="font-[InterBold] text-black text-[1.2rem]" >{repairPacks[0]?.packName}</h4>
                <p className="lg:text-[.9rem] font-[InterRegular] text-[.7rem]" >{repairPacks[0]?.longDesc}</p>
                </div>
            </div>
            <div className=" py-4 flex items-start justify-start lg:gap-6"  >
            <div>
                <img  className="hidden w-[180px] lg:block" src={QuickFix} alt="" />
            </div>
            <div>
                <h4 className="font-[InterBold] text-black text-[1.2rem]" >{repairPacks[1]?.packName}</h4>
                <p className="lg:text-[.9rem] font-[InterRegular] text-[.7rem]">{repairPacks[1]?.longDesc}</p>
                  </div>
            </div>
          
           </div>
           
           
            
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default PackDetailsModal