import React, { useState } from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
import AboutBanner from '../Components/About/AboutBanner'
import Mission from '../Components/About/Mission'
import Vision from '../Components/About/Vision'
import Numbers from '../Components/About/Numbers'
import ContactSection from '../Components/About/ContactSection'
import { push, ref, set } from 'firebase/database'
import { database, db } from '../firebase.config'
import toast, { Toaster } from 'react-hot-toast';
import BrandList from '../Components/About/BrandList'
import SuccessVideo from "../asset/sucessVideo.mp4"
import { addDoc, collection } from 'firebase/firestore'
const About = () => {
  const [contactInfo, setContactInfo] = useState({
    name:"",
    email:"",
    contact:"",
    message:""
  })
  var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
  const handleContactForm = () =>{
    if(contactInfo.name.length===0 ){
      alert("Name is required")
    }
    else if(contactInfo.email.length===0){
      alert("Email is Required")
    }
    else if(emailRegex.test(contactInfo.email)===false){
      alert("Please enter valid email")
    }
    else if(contactInfo.contact.length===0){
      alert("Contact is required")
    }
     else if(phoneRegex.test(contactInfo.contact)===false){
      alert("Please enter valid email")
    }
    else if(contactInfo.message.length===0){
      alert("Message is required")
    }
    else{
       const productListRef = ref(database, "contactInfo/" );
    const newProductRef = push(productListRef);
    set(newProductRef, {
       name:contactInfo.name,
    email:contactInfo.email,
    contact:contactInfo.contact,
    message:contactInfo.message
    }).then(async ()=>{
   
   toast.success('Respose Submitted. We will get Back to you')
      setContactInfo({
         name:"",
    email:"",
    contact:"",
    message:""
      })
    })
    }
  }
  return (
    <div className='bg-[#F2F8FF] font-[Inter] ' >
        <Navbar />
        <Toaster />
        <div className='lg:mt-[80px] mt-[80px] md:mt-[80px]' >
        <BrandList />
        <AboutBanner  />
        
        <Vision />
        <video className='w-full'  loop="true" autoplay="autoplay" muted>
    <source src={SuccessVideo}  type="video/mp4" />
      </video>
        <ContactSection handleContactForm={handleContactForm} contactInfo={contactInfo} setContactInfo={setContactInfo} />
        </div>
        {/* <Numbers /> */}
        <Footer bg="#0D1F37" />
    </div>
  )
}

export default About