// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCkHMDc2mmcrl_O3-53Xqe0mdo20UBw9yc",
  authDomain: "myzippy-f9edf.firebaseapp.com",
  projectId: "myzippy-f9edf",
  storageBucket: "myzippy-f9edf.appspot.com",
  messagingSenderId: "176345022913",
  appId: "1:176345022913:web:19a62c7dc08414fcaaef40",
  measurementId: "G-GMJREYN1L0"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth()
export const database = getDatabase(app)
export const  googleProvider = new GoogleAuthProvider()
