import React, { useState,useEffect } from 'react'
import { ref, onValue, get } from "firebase/database";
import { db, database } from '../../firebase.config';
import { ArrowLeftCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Loader from '../Layout/Loader';
import { useNavigate } from 'react-router-dom';
const SelectManufacturer = ({manufacturer,setManufacturer,device,manufacturers,handleRepairInfo}) => {
    const [active, setActive] = useState(manufacturer)
   const navigate =  useNavigate()
    
    const activeClass = ""
    const inActiveClass = ""

    
      

    useEffect(() => {
     
     

    }, [])
   
  return (
    <div className='xl:px-[165px] lg:px-[60px] px-[40px] md:px-[30px]   py-8 xl:py-[30px]' >
    <div className='flex md:flex-row flex-col-reverse gap-4 items-start justify-between' >
     
    <h4 className='font-[InterBold] text-[20px] lg:text-[24px]' >Select a Manufacturer</h4>
    
    </div>
        
       {manufacturers.length !== 0 &&  <div className="flex items-center justify-start flex-wrap sm:gap-8 gap-3 md:gap-6 mt-6 md:mt-12 " >
           
          {
            manufacturers &&  manufacturers.map((item)=>(
              <div onClick={()=>{
                setManufacturer(item)
                handleRepairInfo(item)
                  navigate("/repairModels")
                }} className='flex rounded-lg cursor-pointer items-center md:w-[172px] w-[110px] h-[90px] md:h-[106px] border-[#D4D4D4] border-[1px] justify-center flex-col px-[24px] py-[32px] lg:px-12 lg:py-4' >
                <img className='lg:w-[86px] w-[72px]' src={item.image} alt="" />
                <p className='md:text-[14px] text-[.7rem] font-[InterRegular] text-[#686868] capitalize ' >{item.brand}</p>
              </div>
            ))
          }
        
        </div>}
      
    </div>
  )
}

export default SelectManufacturer