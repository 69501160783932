import React, { useRef,useState } from 'react'
import ProfileImage from "../../asset/profile.png"
import EditIcon from "../../asset/editIcon.png"
import { Input, Spinner } from '@material-tailwind/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import toast, { Toaster } from 'react-hot-toast';

import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../firebase.config'
const UserInfo = ({userProfile,activeEdit,setActiveEdit,updateProfileInfo,name,email,phone,setName,setEmail,setPhone,emailError,phoneError,emailErrorMessage,phoneErrorMessage,addProfileImage,profileUrl,phoneLoading,resetPassword,updatePassword,setUpdatePassword}) => {
   const inputRef = useRef("")
   const [emailSent, setEmailSent] = useState(false)
   const loginType = localStorage.getItem("loginType")
   console.log(profileUrl)
   const handleEmailSend = () =>{
    sendPasswordResetEmail(auth,email).then((data)=>{
        setUpdatePassword(false)
        toast.success('Email Send Successfully')
    }).catch((err)=>{
        alert(err.message)
    })
   }
  return (
    <div className='bg-white border-[#E4E4E4] border-[1px] px-6 py-8 lg:mx-4 lg:my-4  w-full rounded-lg' >
        <div>
           {!updatePassword ?  <h4 className='font-[InterBold] text-[14px]' >Profile Details</h4> : <h4 className='font-[InterBold] text-[14px]' >Update Password</h4>}
            <div className='my-4 relative ' >
          {!updatePassword && <> {!activeEdit ? <img className='w-[80px] h-[80px] rounded-full ' src={userProfile?.profile_image?.length !==0 ? userProfile?.profile_image : ProfileImage} alt="" /> : <img className='w-[80px] h-[80px] rounded-full ' src={profileUrl?.length !==0 ? profileUrl : ProfileImage} alt="" />}</>}
            {phoneLoading && <Spinner className='absolute top-[2rem] left-[6rem]' />}
       
             {activeEdit && <><input
          ref={inputRef}
          onChange={addProfileImage}
          accept="image/png, image/jpeg"
          type="file"
          style={{ display: "none" }}
          multiple={false}
        />
        <button onClick={() => inputRef.current.click()}><img className='w-[20px] absolute top-[3.8rem] left-[3.7rem]' src={EditIcon} alt="" /></button></>}
                
            </div>
          {!updatePassword ?   <>
         { !activeEdit ?   <div>
            <div className='my-8 md:w-[400px] w-full  ' >
                <div className=' mt-3 grid grid-cols-4 grid-flow-col md:gap-16' >
                    <h4 className='text-[#686868] col-span-2 lg:text-[16px] text-[12px]  font-[InterMedium]' >Full Name</h4>
                    <p className='lg:text-[16px] col-span-2 text-[12px] text-left text-[#0D1F37] font-[InterMedium] ' > {userProfile?.FirstName}</p>
                </div>
                 <div className=' mt-3 grid grid-cols-4 grid-flow-col md:gap-16' >
                    <h4 className='text-[#686868] col-span-2 lg:text-[16px] text-[12px]  font-[InterMedium]' >Mobile Number</h4>
                    <p className='lg:text-[16px] text-[12px] col-span-2 text-[#0D1F37] font-[InterMedium]'>{userProfile?.ContactNo ? userProfile?.ContactNo : "-"}</p>
                </div>
                 <div className='mt-3 grid grid-cols-4 grid-flow-col md:gap-16' >
                    <h4 className='text-[#686868] lg:text-[16px] col-span-2 text-[12px]  font-[InterMedium]' >Email Address</h4>
                    <p className='lg:text-[16px] text-[12px] col-span-2 text-left text-[#0D1F37] font-[InterMedium]'>{userProfile?.email}</p>
                </div>
                  { loginType === "email" &&  <h4 onClick={resetPassword} className='mt-2 text-[#59A4FF] cursor-pointer font-[InterMedium]' >Update Password</h4>}
            </div>
          
           <div>
            <button onClick={()=>setActiveEdit(true)} className='bg-[#2967B0] px-6 py-3 text-white rounded-lg w-[180px] font-[]' >Edit</button>
           </div>
           </div> : (
            <div>
                <div className='flex my-6 items-start  gap-4 justify-start flex-col' >
                    <input value={name} onChange={(e)=>setName(e.target.value)} className='w-80 outline-none font-[InterMedium] border-gray-300 border-[1px] rounded-lg px-3 py-2 ' placeholder='Enter Name' />
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className='w-80 outline-none font-[InterMedium] border-gray-300 border-[1px] rounded-lg px-3 py-2 ' placeholder='Enter Email' />
                    <input value={phone} onChange={(e)=>setPhone(e.target.value)} className='w-80 outline-none font-[InterMedium] border-gray-300 border-[1px] rounded-lg px-3 py-2 ' placeholder='Enter Phone' />
                    
                </div>
                <div>
                     <button onClick={updateProfileInfo} className='bg-[#2967B0] px-6 py-4 text-white rounded-lg w-[180px] font-[600]' >Save</button>
                </div>
            </div>
           )} 
           </> : <>
              <div>
                <div className='flex my-6 items-start  gap-4 justify-start flex-col' >
                    <input value={email} onChange={(e)=>setEmail(e.target.value)}  className='w-80 outline-none font-[InterMedium] border-gray-300 border-[1px] rounded-lg px-3 py-2 ' placeholder='Enter Email' />
               
                    
                </div>
                <div>
                     <button onClick={handleEmailSend} className='bg-[#2967B0] px-6 py-4 text-white rounded-lg w-[180px] font-[600]' >Send Email</button>
                </div>
            </div>
           </>}
        </div>
    </div>
  )
}

export default UserInfo