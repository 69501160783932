import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import GuildelinesIcon from "../../asset/guildelinesIcon.png"
import CrossIcon from "../../asset/crossIcon.svg"
const GuildelinesModel = ({guildelinesModel,setGuildelinesModel,handleGuildlinesOpenModal,handleAddressOpenModal,geometry,address,handleOpenLocation}) => {
  return (
    <div>
      <Dialog
        size="md"
        open={guildelinesModel}
        handler={handleGuildlinesOpenModal}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto relative grid py-2 lg:px-2  lg:py-2   w-full ">
        <div onClick={handleGuildlinesOpenModal} className="absolute cursor-pointer top-4 right-[10px] w-[40px] h-[40px]" >
          <img src={CrossIcon} alt="" />
        </div>
          <CardBody className="flex flex-col gap-4">
          <div className="flex items-start gap-3 justifystart" >
            <div>
                <img className="w-[30px] mt-1 mr-3" src={GuildelinesIcon} alt="" />
            </div>
            <div>
            <h4 className="font-[InterBold]  text-[#0D1F37] text-[1.2rem] md:text-[24px]">
              Zepair Service Guidelines
            </h4>
           
            </div>
          </div>
           <div className="mt-3" >
            <div className="border-b-[1px] border-gray-200 py-4" >
                <h4 className=" text-[14px] text-[#0D1F37] font-[InterBold] md:text-[16px]" >Handover the device to Zipster</h4>
                <p className="md:text-[14px] font-[InterRegular] text-[12px]">Our Zipster will evaluate the issue and if the device requires additional or different service, then the cost may differ.</p>
            </div>
            <div className="  py-4" >
                <h4 className="text-[14px] text-[#0D1F37] font-[InterBold] md:text-[16px]" >Add genuine issue</h4>
                <p className="md:text-[14px] font-[InterRegular] text-[12px]" >Adding genuine issue will help you and us find the real solution, save time and cost.</p>
            </div>
            <div className="mt-4" >
                <button onClick={()=>{
                  if(geometry.lat===0 && geometry.lng===0){
                    handleGuildlinesOpenModal()
                    handleOpenLocation()
                  }
                  else{
                     handleGuildlinesOpenModal()
                    handleAddressOpenModal()
                  }
                   
                }} className="bg-[#2967B0] px-6 py-3 md:text-[1rem] text-[.8rem] text-white rounded-lg font-[InterMedium] " >Yes, I will follow the guidelines</button>
            </div>
           </div>
           
           
            
            
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

export default GuildelinesModel