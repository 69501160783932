import React from 'react'
import Autocomplete from "react-autocomplete"
import { Combobox, Transition } from '@headlessui/react'
import AutocompleteInput from './AutocompleteInput'
import SearchHome from "../../asset/searchHome.svg"
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
const AddRepair = ({device,setDevice,deviceData,brands,brand,setBrand,mobileBrandList,models,model,setModel,handleAddRepair}) => {
    console.log(models)
  return (
    <div className='md:block mt-[-60px] xl:pl-[165px] absolute lg:pl-[60px] px-3 md:px-[30px]  rounded-[12px] bottom-[-260px]  z-[900] md:bottom-[-50px]' >
        <div className='bg-white py-3 px-2 md:py-6 md:px-6 shadow-md  rounded-lg   w-[93vw] md:w-[93vw] lg:w-[900px] xl:w-[900px] 2xl:w-[78vw]' >
            <div className='md:border-[1px] relative md:grid grid-cols-3 px-2 grid-flow-col md:py-3 md:px-4 md:border-[#DCDCDC]  md:rounded-[172px]' >
                <div className='md:border-r-[1px] border-b-[1px] ml-0 md:ml-3 md:border-b-0 py-2 md:py-0  border-[#DCDCDC]' >
                    <p className='font-[InterBold] text-[#0D1F37] text-[14px]' >Select Device</p>
                 <AutocompleteInput selected={device} placeholder="Select Device"  mobileBrandList={mobileBrandList} setSelected={setDevice} data={deviceData} />
                </div>
               <div className='md:border-r-[1px]  ml-0 md:ml-3 py-2 md:py-0 md:border-b-0  border-b-[1px]  border-[#DCDCDC]' >
                    <p className='font-[InterBold] text-[#0D1F37] text-[14px]' >Select Brand</p>
                      <AutocompleteInput placeholder="Select Brand" selected={brand} setSelected={setBrand} data={brands} />
                </div>
                <div className=' ml-0 md:ml-3 py-2 md:py-0' >
                    <p className='font-[InterBold]  text-[#0D1F37] text-[14px]' >Select Model</p>
                      <AutocompleteInput placeholder="Select Model" selected={model} setSelected={setModel} data={models} />
                </div>
                <div className='absolute right-4  hidden md:block top-[7px] ' >
                    <img className='w-[50px] cursor-pointer' onClick={handleAddRepair} src={SearchHome} alt="" />
                </div>
                <div className='  md:hidden block mt-3 ' >
                    <button className='w-full text-white py-2 rounded-[8px] flex items-center font-[InterMedium] text-[16px] justify-center gap-3 px-3 bg-[#2967B0]' onClick={handleAddRepair}><MagnifyingGlassIcon className='w-[21.5px]' /> Get Started</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddRepair