import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const ShippingContent = () => {
  return (
    <div>
      <Disclosure className="">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full my-6 font-[InterMedium] justify-between  bg-white border-b-[1px] border-[#ececec] px-3  xl:px-6 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <span className="font-[InterMedium] font-[600] text-[1.1rem]">
                Shipping & Delivery
              </span>
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-6 w-6 text-gray-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-6 pt-1 font-[InterMedium] pb-0 text-[1rem] ">
              <Disclosure className="mt-[-15px]">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full mt-[-10px] font-[InterMedium] justify-between  bg-white  px-3  xl:px-2 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span className="font-[InterMedium] text-[.9rem] ">
                        Packaging
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 rounded-lg mt-[-10px] bg-gray-100  my-2  py-4 font-[InterMedium]  text-[.8rem] text-gray-600">
                      <p>
                        At Zippy, we are committed to revolutionizing the
                        packaging experience for our valued customers. Our
                        relentless focus on excellence extends to every facet of
                        our doorstep repair service, including the careful
                        consideration of packaging materials and methods. <br />
                        Striving for sustainability, we actively seek ways to
                        minimize waste and enhance the packaging of the gadgets
                        we repair. By staying attuned to customer feedback
                        through various channels such as customer service
                        interactions, online returns, and social media, we
                        continuously refine our packaging strategies to ensure
                        an optimal balance between protection and
                        eco-friendliness. <br /> In the realm of doorstep gadget
                        repair, compact packaging is key to safeguarding your
                        valuable devices during transit. Zippy collaborates with
                        manufacturers globally, encouraging them to reimagine
                        and optimize their packaging solutions. This
                        collaborative effort not only aims to reduce waste
                        throughout the supply chain but also guarantees that
                        your repaired gadgets reach you in pristine condition.{" "}
                        <br />
                        At Zippy, we're not just repairing gadgets; we're
                        transforming the entire repair experience, starting from
                        the moment your device is packaged for repair to its
                        safe return to your doorstep. Join us in our commitment
                        to quality, sustainability, and innovation in packaging
                        for a seamless and eco-conscious repair journey.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure className="mt-[-15px]">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full mt-[-10px]  font-[InterMedium] justify-between  bg-white  px-3  xl:px-2 xl:py-4 py-2 text-left text-[12px] xl:text-[1rem] font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span className="font-[InterMedium] text-[.9rem] ">
                        DELAYED/ NOT DELIVERED
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 rounded-lg mt-[-10px] bg-gray-100  my-2  py-4 font-[InterMedium]  text-[.8rem] text-gray-600">
                      <p>
                        <span className="font-[InterMedium] text-[.9rem]">
                          {" "}
                          Here are some potential reasons for a delayed gadget
                          delivery with Zippy:
                        </span>{" "}
                        <br />• Incorrect address details provided. • Missing
                        apartment, building, floor, or house number in the
                        address. <br />• Severe weather conditions affecting the
                        delivery timeline. <br /> • Product temporarily out of
                        stock. <br />• Product sustained damage during transit.
                        <br />• Local or regional contingencies impacting the
                        delivery process.
                        <br /> <br />
                        <span className="font-[600] text-[.9rem] ">
                          {" "}
                          To ensure a smoother delivery experience, consider the
                          following actions:
                        </span>{" "}
                        <br />
                        • Track your gadget repair order and verify the
                        estimated delivery date in Your Orders. We promptly
                        update this information if there are any changes in the
                        expected delivery timeframe.
                        <br /> • Confirm and update your shipping address
                        details in Your Orders. Keeping your address information
                        accurate and adding any specific delivery instructions
                        to Your Addresses helps prevent potential delivery
                        issues.
                        <br /> • Check the payment processing status of your
                        order in Your Orders.
                        <br /> • In case of any unexpected delays, we recommend
                        waiting for 48 hours. We strive to keep our customers
                        informed and typically provide notifications if there
                        are any unforeseen issues affecting the delivery
                        timeline. At Zippy, we value your satisfaction and aim
                        to address any delivery concerns promptly. Thank you for
                        choosing us for your gadget repair needs.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default ShippingContent;
