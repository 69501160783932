import React from 'react'
import Navbar from '../Components/Layout/Navbar'
import Footer from '../Components/Layout/Footer'
const CancellationPolicy = () => {
  return (
     <div>
        <Navbar />
        <div className='my-12 mt-[110px] xl:mx-[165px]'>
            <p>
       
   <strong>  Cancellation, Return and Refund Policy for online orders</strong><br /><br />
   <strong>Cancellation Policy</strong>
<ul className='list-disc' >
<li> Customer cannot cancel the order that has been invoiced.</li>
<li>All costs associated with returning the item to us including return shipping charges and any taxes if applicable
shall need to be taken care of by you.</li>
<li>Gift cards, personalized products, sale, clearance and promotional items are not eligible for a return or exchange.</li>
<li>For order cancellation please write us at support@zepair.in or call us at 8339833985 3 hours before the
scheduled appointment</li>
<li>Zepair India reserves right to cancel the order without any prior information.</li>
</ul><br /><br />
<strong>Return & Refund Policy</strong><br />
<ul className='list-disc' >
<li>All the products sold are covered under Zepair warranty and the same is claimable at Zepair Authorised service
centre only.(Standard Part – 3 months of Warranty from the date of invoice / Genuine Part – 6 months of
warranty from the date of invoice , Cosmetic Part- There is no warranty applicable on the cosmetic parts)</li>
<li>No exchange and return of any products are applicable except the below.</li>
<li>Please notify us of receipt of a Damaged / Defective product within maximum 24 hours of delivery of the product.</li>
<li>All items to be returned must be in their original packaging in unused and undamaged condition, with all original
tags/labels attached along with proof of purchase.</li>
<li>In case you have received your order with the outer packaging tampered/meddled with, please do not accept the
package and write us support@zepair.in asap</li> </ul><br /><br />
<strong>Dead on Arrival Product</strong>
<ul className='list-disc' >
<li>A product is considered Dead on Arrival (“DOA”) if it shows symptoms of a hardware failure, preventing basic
operability, upon its first use out of the package. If you believe that your product is DOA, please call 8339833985
and write us on support@zepair.in within 24 hours of delivery. Zepair authorized service center will determine
whether the product is DOA and offer you the following options:
Service: You may have the product repaired; however, once serviced, the product is no longer eligible for
replacement for the third time.</li>
<li>Product must be deemed DOA by Zepair authorized service center within 24 hours of the product receipt date in
order to be eligible for a Free Second Repair .</li>
<li>Zepair reserves the right to test returned DOA products. If the condition of the product is misrepresented by the

customer, Zepar may impose a handling fee.</li></ul><br /><br />
<strong>Refunds</strong><br />
No Refunds Whatsoever in any case <br /><br />
<strong>Cancellation, Return and Refund Policy for store orders</strong> 
<ul className='list-disc'></ul>
<li> Orders once invoiced will not be cancelled and refunded.</li>
<li>Goods once sold cannot be replaced for refunded
Incase of DOA(Dead on arrival) or any cosmetic damaged product we will replace the product only if the package
is opened at our store.</li>
<li>No bill edits or addition/change of GST will be done once the bill is generated.</li>


            </p>
        </div>
        <Footer bg="#0D1F37" />
    </div>
  )
}

export default CancellationPolicy