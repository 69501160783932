import { Modals_REQUEST,Modals_SUCCESS,Modal_FAIL, SAVE_SHIELD_DEVICE_INFO, SHIELD_ORDER_REQUEST,SHIELD_ORDER_SUCCESS,SHIELD_ORDER_FAIL } from "../contants/shieldContants";
import { CLEAR_ERRORS } from "../contants/userConstants";
export const shieldModalsReducer = (state = {modals: [],deviceInfo:{} }, action) =>{
    switch (action.type) {
    case Modals_REQUEST:
      return {
        loading: true,
        isModals: false,
      };
    case Modals_SUCCESS:
      return {
        ...state,
       loading: true,
        isModals: false,
        modals: action.payload,
      };
    
    case Modal_FAIL:
      return {
        ...state,
       loading: true,
        isModals: false,
        modals: null,
        error: action.payload,
      };
   
      case SAVE_SHIELD_DEVICE_INFO:
      return {
        ...state,
        deviceInfo:action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}

export const shieldOrderReducer = (state = {shieldOrder:{} }, action) => {
   switch (action.type) {
    case SHIELD_ORDER_REQUEST:
      return {
        loading: true,
        isOrder: false,
      };
    case SHIELD_ORDER_SUCCESS:
      return {
        ...state,
       loading: true,
        isOrder: false,
        shieldOrder: action.payload,
      };
    
    case SHIELD_ORDER_FAIL:
      return {
        ...state,
       loading: true,
        isOrder: false,
        shieldOrder: null,
        error: action.payload,
      };
   
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}