import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import "swiper/css";

import Avatar from "../../asset/avatar.png"
import BackgroundImage from "../../asset/testimonialBanner.png"
import TestimonialTitle from "../../asset/testimonialTitle.svg"
import ArrowLeft from "../../asset/arrowleft.svg"
import ArrowRight from "../../asset/arrowRight.svg"
import Quotes from "../../asset/quotes.svg"
import "./Home.css"
// Import Swiper styles
SwiperCore.use([Navigation,Autoplay]);
const Testimonial = ({handleOpenModal,isAuthenticated,testimonialList}) => {
  return (
    <div className='testimonialBanner' >
   
    <div className=' lg:w-[600px] xl:w-[850px] gap-6 w-[300px] md:w-[450px] flex items-center md:items-end justify-center md:justify-start flex-col md:flex-row  xl:pl-[130px] md:px-[30px] mt-8 md:mt-0 lg:mt-4 ' >
    <div>
   
    <h4 className='font-[InterBlack] block md:hidden text-[1.5rem] text-[#FF7A00]' >Real Stories from <br /> Real Customers</h4>
    <img className='xl:ml-[-50px] lg:ml-[-35px] md:ml-[-25px] hidden md:block md:w-[220px] lg:w-[300px] xl:w-[400px]' src={TestimonialTitle} alt="" />
    <p className='font-[InterMedium] text-[.8rem] lg:text-[1rem]  ' >Get inspired by these stories.</p>
  
   
    
  {testimonialList.length !== 0 && <div className='bg-white relative lg:w-[400px] w-[300px] md:w-[300px]  xl:w-[580px] rounded-[15px] mt-6 lg:mt-3 py-8 md:py-6 lg:py-8' >
  <img className='absolute left-[3%] md:left-[4%]' src={Quotes} alt="" />

    <Swiper spaceBetween={0}
          className=""
          draggable={true}
          slidesPerView={1}
          navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
          autoplay={{
            delay: 6000,
            disableOnInteraction:true,
          }}
          loop={true}
         >
          {
            testimonialList?.map((item)=>(
                <SwiperSlide >
                <div className='px-8 lg:px-12' >
               
      <p className='lg:text-[1rem] xl:text-[18px] text-[#18191F] font-[InterRegular] text-[.7rem]' >{item.testimonial}</p>
      <p className='font-[InterBold] xl:text-[18px] text-[#18191F]  mt-2 lg:mt-3 ' >{item.name}</p>
    { item.position &&  <p className='font-[InterMedium] xl:text-[18px] text-[#969BAB]  mt-2 lg:mt-3 ' >{item.position}, {item.company}</p>}
  </div>
    </SwiperSlide>
            ))
          }
    
   
    </Swiper>
      </div>}
      </div>
          <div className='flex mt-4  items-center  gap-4' >
             <button className="arrow-left arrow">
              <img className='w-[40px]' src={ArrowLeft} alt="" />
             </button>
        <button className="arrow-right arrow">
            <img className='w-[40px]' src={ArrowRight} alt="" />
        </button>
          </div>
    </div>
   
    </div>
  )
}

export default Testimonial