import React from 'react'
import { Link } from 'react-router-dom'
import { PhoneIcon,MapPinIcon } from '@heroicons/react/24/outline'
import LogoWhite from "../../asset/LogoWhite.png" 
import Twitter from "../../asset/Twitter.svg" 
import Instagram from "../../asset/Instagram.svg" 
import Facebook from "../../asset/Facebook.svg" 
import GooglePlayButton from "../../asset/googlePlayButton.svg"
import ApplePlayButton from "../../asset/appleButton.svg"
import { useDispatch, useSelector } from "react-redux";
const Footer = ({bg}) => {
  const { error, loading, isAuthenticated, users, userProfile } = useSelector(
    (state) => state.user
  );
  
  return (
    <div style={{backgroundColor:"black"}} className={`xl:px-[165px] lg:px-[60px] px-[20px] md:px-[30px]  py-6 lg:py-16 `} >
         <div className=''>
            <div className='lg:mx-0 mx-6 '>
                <div>
                    <img src={LogoWhite} className="w-36 ml-[-20px]" alt="" />
                    <p className='font-[InterMedium] text-[14px] my-6 text-[#D3D2D2]' >Zepair India ka Apna Repair. </p>
                   <a href="https://www.google.com/maps/place/Zepair/@19.082502,72.7163719,11z/data=!3m1!4b1!4m6!3m5!1s0x11a5f92ac1c21e1:0xf212fb3d2cf2da38!8m2!3d19.0822507!4d72.8811862!16s%2Fg%2F11vkbfn7n0?entry=ttu"> <p className='font-[InterMedium] w-[300px] text-[14px] my-6 text-[#D3D2D2]'>Unit No 30, VTM 1, Mehra Industrial Compound, Opp Telephone Exchange, Andheri Kurla Road, Saki Naka, Mumbai 400072</p></a>
                </div>
                <div className='grid md:w-[500px] grid-cols-3 gap-8 lg:gap-6 md:mt-[64px] grid-flow-col' >
                    <div className='col-span-1 mt-6 md:mt-0 ' >
                        <h4 className='font-[InterMedium] text-[#fafafa] mb-2 text-[14px]' >Company</h4>
                     <Link to="/about" >   <p className='font-[InterRegular] md:mt-[32px] hover:text-white  hover:border-b-[1px] w-[60px] text-[#C9C9C9] text-[.8rem]' >About us</p>  </Link>
                     <Link to="/stories" >  <p className='font-[InterRegular] mt-[4px] hover:text-white  hover:border-b-[1px] w-[100px] text-[#C9C9C9] text-[.8rem]'>Success Stories</p> </Link>
                     <Link to="/repair" >   <p className='font-[InterRegular] mt-[4px] hover:text-white  hover:border-b-[1px] w-[50px] text-[#C9C9C9] text-[.8rem]'>Z Repair</p> </Link>
                    </div>
                    <div className='col-span-1 mt-6 md:mt-0  ' >
                        <h4 className='font-[InterMedium] text-[#fafafa] mb-2 text-[14px]' >Help and Support</h4>
                     
                      
                       <Link onClick={ sessionStorage.setItem(
                                        "profileStatus",
                                        "help"
                                      )} to="/profile" >  <p className='font-[InterRegular] my-1 hover:text-white  md:mt-[32px]  hover:border-b-[1px] w-[30px] text-[#C9C9C9] text-[.8rem]'>Help</p> </Link>
                                      {isAuthenticated && <Link to="/profile" >  <p className='font-[InterRegular] hover:text-white  hover:border-b-[1px] w-[40px] my-1 text-[#C9C9C9] text-[.8rem]'>Profile</p></Link>}
                    </div>
                    <div className='col-span-1 mt-6 md:mt-0  ' >
                        <h4 className='font-[InterMedium] my-1 text-[#fafafa] mb-2 text-[14px]' >More Info</h4>
                      <Link to="/privacy-policy" >  <p className='font-[InterRegular] md:mt-[32px] my-1 text-[#C9C9C9] hover:text-white  hover:border-b-[1px] w-[90px] text-[.8rem]' >Privacy Policy</p></Link>
                     <Link to="/termsandcondition" >   <p className='font-[InterRegular] my-1 hover:text-white  hover:border-b-[1px] w-[80px] text-[#C9C9C9] text-[.8rem]'>Terms of use</p></Link>
                      <Link to="/cancellationPolicy" >   <p className='font-[InterRegular] my-1 hover:text-white  hover:border-b-[1px] w-[120px] text-[#C9C9C9] text-[.8rem]'>Cancellation Policy</p></Link>
                    </div>
                </div>
               
              
                
            </div>
          
           
           
        </div>
        <div className='flex   flex-col md:flex-row  mt-[64px] items-start gap-4 md:gap-0 md:items-center justify-between'>
            <div className='flex items-center justify-center' >
               <a href='https://www.linkedin.com/company/zippy-india/' > <img className='w-6 mx-1' src={Twitter} alt="" /></a>
               <a href='https://www.linkedin.com/company/zippy-india/' > <img className='w-6 mx-1' src={Facebook} alt="" /></a>
               <a href="https://www.instagram.com/zepairindia?igsh=ODB6MzI4bXA2bHJ3"><img className='w-6 mx-1'  src={Instagram} alt="" /></a>
            </div>
            <div className='flex items-center gap-4  justify-end' >
              <a target='_blank' rel='noreferrer' href="https://play.google.com/store/apps/details?id=com.zippy.consumer">  <img className='w-[100px]   ' src={GooglePlayButton} alt="" /> </a>  
              <a target='_blank' rel='noreferrer' href="https://apps.apple.com/in/app/zippy-india/id6466287109">     <img className='w-[100px]  ' src={ApplePlayButton} alt="" /> </a>  
            </div>
        </div>
        <div className='flex  mt-[32px]  items-center justify-between' >
            <p className='text-[#C7C7C7] mx-2 text-[0.8rem] font-[InterMedium]' >Copyright @ 2023 Fixzip Technology Private Limited. All rights reserved</p>
            {/* <div className='flex items-center justify-center' >
               <a href='https://www.linkedin.com/company/zippy-india/' > <img className='w-8 mx-4' src={Linkedin} alt="" /></a>
                <a href="https://instagram.com/zippy.india?igshid=NGVhN2U2NjQ0Yg=="><img className='w-8 mx-4'  src={Instagram} alt="" /></a>
            </div> */}
        </div>
    </div>
  )
}

export default Footer