import React,{useState,useEffect} from 'react'
import UserInfo from './UserInfo'
import MyOrders from './MyOrders'
import ManageAddress from './ManageAddress'
import AddAddress from './AddAddress'
import Help from './Help'
import EditAddress from './EditAddress'
import AddressModel from './AddressModal'
import EditAddressModel from './EditAddressModal'


const ProfileRight = ({setAddress,activeSection,userProfile,activeEdit,setActiveEdit,updateProfileInfo,name,email,phone,setName,setEmail,setPhone,emailError,phoneError,emailErrorMessage,phoneErrorMessage,addressName,setAddressName,breifAddress,setBriefAddress,landmark,setLandmark,isDefault,setIsDefault,updateAddressInfo,addresses,removeAddresss,setActiveSection,addressId,setAddressId,fetchSingleAddress,singleAddress,setSingleAddress,updateAddress,repairOrderList,shieldOrderList,address,locationData,setLocationData,handleLocationData,geometry,setGeometry,map,setMap,onLoad,addProfileImage,profileUrl,phoneLoading,resetPassword,updatePassword,setUpdatePassword,handleEditLocationData}) => {
  const [addressModal, setAddressModal] = useState(false)
  const [editAddressModal, setEditAddressModal] = useState(false)
  const handleAddressModal = () =>{
    setAddressModal(!addressModal)
  }
  const handleEditAddressModal = () =>{
    setEditAddressModal(!editAddressModal)
  }
  return (
   <div className='col-span-4 border-t-[1px] lg:pr-6  border-gray-300 bg-gray-100 ' >
   <AddressModel onLoad={onLoad} map={map} setMap={setMap} handleLocationData={handleLocationData} geometry={geometry} setGeometry={setGeometry} setAddressModel={setAddressModal} updateAddressInfo={updateAddressInfo} addressModel={addressModal} locationData={locationData} setLocationData={setLocationData} address={address} setAddress={setAddress} handleAddressOpenModal={handleAddressModal} />
   <EditAddressModel handleEditLocationData={handleEditLocationData} locationData={locationData} setLocationData={setLocationData} setEditAddressModal={setEditAddressModal} handleLocationData={handleLocationData} editAddressModal={editAddressModal}  handleEditAddressModal={handleEditAddressModal} geometry={geometry} setGeometry={setGeometry} updateAddress={updateAddress} setSingleAddress={setSingleAddress} singleAddress={singleAddress} addressId={addressId} setAddressId={setAddressId} addresses={addresses} activeSection={activeSection} setActiveSection={setActiveSection} addressName={addressName} setAddressName={setAddressName} briefAddress={breifAddress} setBriefAddress={setBriefAddress} landmark={landmark} setLandmark={setLandmark} isDefault={isDefault} setIsDefault={setIsDefault} updateAddressInfo={updateAddressInfo} />
    {
      activeSection === "profile" && <UserInfo updatePassword={updatePassword} setUpdatePassword={setUpdatePassword} resetPassword={resetPassword} phoneLoading={phoneLoading} profileUrl={profileUrl} addProfileImage={addProfileImage}  activeEdit={activeEdit} updateProfileInfo={updateProfileInfo} name={name} email={email} phone={phone} setName={setName} setEmail={setEmail} setPhone={setPhone} phoneError={phoneError} emailError={emailError} emailErrorMessage={emailErrorMessage} phoneErrorMessage={phoneErrorMessage} setActiveEdit={setActiveEdit} userProfile={userProfile} />
    }
     {
      activeSection === "orders" && <MyOrders repairOrderList={repairOrderList} shieldOrderList={shieldOrderList} />
    }
    {
      activeSection === "address" && <ManageAddress handleEditAddressModal={handleEditAddressModal}   handleAddressModal={handleAddressModal} fetchSingleAddress={fetchSingleAddress} addressId={addressId} setAddressId={setAddressId} activeSection={activeSection} setActiveSection={setActiveSection} removeAddresss={removeAddresss} addresses={addresses} />
    }
    
     {
      activeSection === "editAddress" && <EditAddress updateAddress={updateAddress} setSingleAddress={setSingleAddress} singleAddress={singleAddress} addressId={addressId} setAddressId={setAddressId} addresses={addresses} activeSection={activeSection} setActiveSection={setActiveSection} addressName={addressName} setAddressName={setAddressName} briefAddress={breifAddress} setBriefAddress={setBriefAddress} landmark={landmark} setLandmark={setLandmark} isDefault={isDefault} setIsDefault={setIsDefault} updateAddressInfo={updateAddressInfo} />
    }
    {
      activeSection === "help" && <Help />
    }
   </div>
  )
}

export default ProfileRight